<template>
  <div class="relative w-full">
    <div class="flex flex-col">
      <!-- Header & Actions -->
      <div class="flex flex-col sm:flex-row gap-4 mb-6">
        <!-- Title & Description -->
        <div v-if="showHeader" class="flex-grow">
          <div v-if="showTitle" class="flex items-center gap-3">
            <span class="material-symbols-outlined !text-[24px] text-primary">receipt_long</span>
            <h3 class="text-lg font-semibold text-gray-800">{{ t('shared.paymentInformation.title') }}</h3>
          </div>
          <p v-if="showDescription" class="text-sm text-gray-500 mt-2 sm:pl-9">
            {{ t('shared.paymentInformation.description') }} </p>
        </div>
        <div v-if="!showHeader" class="flex-grow"/>

        <!-- Actions -->
        <div class="flex items-center gap-3">
          <CustomInput v-model="searchQuery" :placeholder="t('shared.paymentInformation.search.placeholder')" type="text" :show-label="false" @keyup.enter="handleSearch" @update:model-value="handleInputChange" containerClass="!mb-0 min-w-[240px]" :show-icon="true" icon="search" iconClass="text-gray-400 !text-[20px]" :show-error="false"/>
          <VButton @click="openModal()" color="primary" :button-text="t('shared.paymentInformation.addNew')" icon="add" :show-icon="true" size="sm"/>
        </div>
      </div>

      <!-- Content -->
      <div class="bg-gray-50 rounded-xl p-4 sm:p-6 relative">
        <!-- Loading Overlay -->
        <div v-if="loading" class="absolute inset-0 bg-gray-50 rounded-xl flex items-center justify-center z-10">
          <div class="flex flex-col items-center justify-center">
            <div class="rounded-full h-12 w-12 border-b-2 border-primary flex justify-center items-center">
             <span class="material-symbols-outlined  animate-spin">
               progress_activity
            </span>
            </div>
            <span class="mt-2 text-sm text-gray-600">{{ t('general.loading') }}</span>
          </div>
        </div>

        <div v-if="paymentInfos.length" class="grid gap-3 sm:gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <div v-for="info in paymentInfos" :key="info.id" class="bg-white rounded-lg p-3 sm:p-4 hover:shadow-lg transition-all duration-200 cursor-pointer select-none" :class="{ 'ring-2 ring-primary shadow-md': selectedId === info.id }" @click="selectPaymentInfo(info)">
            <!-- Card Header -->
            <div class="flex justify-between items-start mb-3">
              <div class="flex items-center gap-2">
                <div class="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
                  <span class="material-symbols-outlined !text-[16px] text-primary">account_circle</span>
                </div>
                <div>
                  <h4 class="font-medium text-gray-800 line-clamp-1">{{ info.name }} {{ info.surname }}</h4>
                  <p class="text-xs text-gray-500">TC/VKN: {{ info.identityNumber }}</p>
                </div>
              </div>

              <div class="flex gap-1">
                <button @click="(event) => handleEditClick(event, info)" class="h-7 w-7 flex items-center justify-center rounded-full hover:bg-gray-100 transition-colors" type="button" aria-label="Edit">
                  <span class="material-symbols-outlined !text-[16px] text-gray-600">edit</span>
                </button>
                <button @click="(event) => handleDeleteClick(event, info.id)" class="h-7 w-7 flex items-center justify-center rounded-full hover:bg-gray-100 transition-colors" type="button" aria-label="Delete">
                  <span class="material-symbols-outlined !text-[16px] text-gray-600">delete</span>
                </button>
              </div>
            </div>

            <!-- Card Content -->
            <div class="space-y-2">
              <div class="flex items-center gap-2 text-sm">
                <span class="material-symbols-outlined !text-[16px] text-gray-400 flex-shrink-0">mail</span> <span class="text-gray-600 truncate">{{ info.email }}</span>
              </div>
              <div class="flex items-center gap-2 text-sm">
                <span class="material-symbols-outlined !text-[16px] text-gray-400 flex-shrink-0">phone</span> <span class="text-gray-600">{{ info.phone }}</span>
              </div>
              <div class="flex items-start gap-2 text-sm">
                <span class="material-symbols-outlined !text-[16px] text-gray-400 mt-0.5 flex-shrink-0">location_on</span>
                <div class="text-gray-600">
                  <p class="line-clamp-2">{{ info.address }}</p>
                  <p>{{ info.city }}, {{ info.country }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Empty State -->
        <div v-else class="flex flex-col items-center justify-center py-12">
          <div class="bg-white rounded-full p-4 mb-4">
            <span class="material-symbols-outlined !text-[32px] text-primary/60">receipt_long</span>
          </div>
          <p class="text-gray-600 mb-4">
            {{ searchQuery ? t('shared.paymentInformation.noSearchResults') : t('shared.paymentInformation.noData') }} </p>
          <VButton v-if="searchQuery" @click="clearSearch" variation="outline" color="primary" size="sm" icon="refresh" :show-icon="true" :button-text="t('shared.paymentInformation.clearSearch')"/>
        </div>

        <!-- Pagination -->
        <div v-if="paymentInfos.length && totalPages > 1" class="flex justify-center mt-8">
          <div class="bg-white rounded-full flex items-center gap-1 p-1 shadow-sm">
            <VButton @click="handlePageChange(currentPage - 1)" :disabled="currentPage === 1" variation="rounded-full" color="neutral" size="sm" icon="chevron_left" :show-icon="true" :is-text-showable="false" class="!h-8 !w-8"/>
            <span class="text-sm text-gray-600 px-3">
              {{ currentPage }} / {{ totalPages }}
            </span>
            <VButton @click="handlePageChange(currentPage + 1)" :disabled="currentPage === totalPages" variation="rounded-full" color="neutral" size="sm" icon="chevron_right" :show-icon="true" :is-text-showable="false" class="!h-8 !w-8"/>
          </div>
        </div>
      </div>
    </div>

    <!-- Payment Information Modal -->
    <PaymentInformationModal v-model="showModal" :edit-mode="editMode" :loading="loading" :initial-data="selectedInfo" :errors="errors" @submit="handleSubmit"/>

    <!-- Delete Confirmation Dialog -->
    <VDialog v-model="showDeleteDialog" :title="t('shared.paymentInformation.deleteTitle')" :message="t('shared.paymentInformation.deleteMessage')" :loading="loading" :confirm-button-text="t('shared.paymentInformation.confirmDelete')" :cancel-button-text="t('shared.paymentInformation.cancel')" :show-cancel-button="true" @confirm="confirmDelete" @decline="showDeleteDialog = false"/>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { payment } from '@/networking/urlManager'
import axios from '@/plugins/axiosInstance'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import PaymentInformationModal from './components/PaymentInformationModal.vue'
import VButton from '@/components/base/buttons/VButton.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'
import VDialog from '@/components/feedback/VDialog.vue'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
  showDescription: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const showModal = ref(false)
const loading = ref(false)
const editMode = ref(false)
const selectedId = ref(props.modelValue)
const selectedInfo = ref(null)
const paymentInfos = ref([])
const errors = reactive({})
const searchPending = ref(false)
const showDeleteDialog = ref(false)
const deleteId = ref(null)

// Search and pagination states
const searchQuery = ref('')
const currentPage = ref(0)
const totalPages = ref(1)

const fetchPaymentInfos = async (page = 0, q = '') => {
  try {
    loading.value = true
    const response = await axios.get(payment.paymentInformation, { params: { page, q } })
    paymentInfos.value = response.data.data.detail || []
    totalPages.value = response.data.data.pageCount || 1
    currentPage.value = page
  } catch (error) {
    console.error('Error fetching payment information:', error)
    responseHandlerService.handleError(t('shared.paymentInformation.errors.fetch'))
    paymentInfos.value = []
  } finally {
    loading.value = false
    searchPending.value = false
  }
}

const handleInputChange = (value) => {
  searchPending.value = true
}

const handleSearch = async () => {
  if (!searchPending.value) return

  currentPage.value = 0
  await fetchPaymentInfos(0, searchQuery.value)
}

const clearSearch = async () => {
  searchQuery.value = ''
  searchPending.value = false
  currentPage.value = 0
  await fetchPaymentInfos(0, '')
}

const handlePageChange = async (page) => {
  if (page < 1 || page > totalPages.value) return
  await fetchPaymentInfos(page, searchQuery.value)
}

const handleEditClick = (event, info) => {
  if (event) {
    event.stopPropagation()
  }
  openModal(info)
}

const handleDeleteClick = (event, id) => {
  if (event) {
    event.stopPropagation()
  }
  deleteId.value = id
  showDeleteDialog.value = true
}

const confirmDelete = async () => {
  try {
    loading.value = true
    await axios.delete(payment.paymentInformation, {
      params: { id: deleteId.value },
    })

    // Silinen item'ı array'den çıkarıyoruz
    paymentInfos.value = paymentInfos.value.filter(item => item.id !== deleteId.value)

    // Seçili item'ı sıfırlıyoruz
    if (selectedId.value === deleteId.value) {
      selectedId.value = null
      emit('update:modelValue', null)
    }

    // Eğer listenin son item'ı silindiyse ve başka sayfa varsa bir önceki sayfaya gidiyoruz
    if (paymentInfos.value.length === 0 && currentPage.value > 1) {
      await fetchPaymentInfos(currentPage.value - 1, searchQuery.value)
    }

    responseHandlerService.handleSuccess(t('shared.paymentInformation.success.delete'))
  } catch (error) {
    console.error('Error deleting payment information:', error)
    responseHandlerService.handleError(t('shared.paymentInformation.errors.delete'))
  } finally {
    loading.value = false
    deleteId.value = null
    showDeleteDialog.value = false
  }
}

const openModal = (info = null) => {
  selectedInfo.value = info
  editMode.value = !!info
  showModal.value = true
}

const handleSubmit = async (formData) => {
  try {
    loading.value = true
    const method = editMode.value ? 'put' : 'post'
    const data = editMode.value ? { ...formData, id: selectedInfo.value.id } : formData

    await axios[method](payment.paymentInformation, data).then(async () => {
      if (editMode.value) {
        const index = paymentInfos.value.findIndex(item => item.id === selectedInfo.value.id)
        if (index !== -1) {
          paymentInfos.value[index] = { ...paymentInfos.value[index], ...formData }
        }
      } else {
        await fetchPaymentInfos(currentPage.value, searchQuery.value)
      }

      showModal.value = false
      responseHandlerService.handleSuccess(
          t(`shared.paymentInformation.success.${editMode.value ? 'update' : 'add'}`),
      )
    }).catch((error) => {
      console.error('Error submitting payment information:', error)
      if (error.response?.data?.errors) {
        Object.assign(errors, error.response.data.errors)
      }
      responseHandlerService.handleError(
          t(`shared.paymentInformation.errors.${editMode.value ? 'update' : 'add'}`),
      )
    })
  } finally {
    loading.value = false
  }
}

const selectPaymentInfo = (info) => {
  selectedId.value = info.id
  emit('update:modelValue', info.id)
}

onMounted(() => {
  fetchPaymentInfos()
})

watch(() => props.modelValue, (newValue) => {
  selectedId.value = newValue
})

defineExpose({
  validate: () => {
    if (!selectedId.value) {
      responseHandlerService.handleError(t('shared.paymentInformation.errors.validation.required'))
      return false
    }
    return true
  },
  reset: () => {
    selectedId.value = null
    selectedInfo.value = null
    searchQuery.value = ''
    currentPage.value = 0
    emit('update:modelValue', null)
  },
})
</script>