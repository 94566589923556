export const setupSteps = {
  'general': {
    id: 'general',
    order: 1,
    title: 'Genel Bilgiler',
    description: 'Varlık hakkında temel bilgileri doldurun',
    component: () => import('./steps/GeneralSettings.vue'),
    nextStep: 'configuration',
  },
  'configuration': {
    id: 'configuration',
    order: 2,
    title: 'Konfigürasyon',
    description: 'Anahtar kelimeler ve lokasyon ayarlarını yapın',
    component: () => import('./steps/Configuration.vue'),
    nextStep: 'integrations',
  },
  'integrations': {
    id: 'integrations',
    order: 3,
    title: 'Entegrasyonlar',
    description: 'Sosyal medya hesaplarınızı bağlayın',
    component: () => import('./steps/Integrations.vue'),
    nextStep: 'schedule',
  },
  'schedule': {
    id: 'schedule',
    order: 4,
    title: 'Zamanlama',
    description: 'Paylaşım takvimini oluşturun',
    component: () => import('./steps/Schedule.vue'),
    nextStep: null, // Son adım
  },
}

// Yardımcı fonksiyonlar
export const getFirstStep = () => {
  return Object.values(setupSteps).sort((a, b) => a.order - b.order)[0].id
}

export const getNextStep = (currentStepId) => {
  const currentStep = setupSteps[currentStepId]
  return currentStep?.nextStep
}

export const getPreviousStep = (currentStepId) => {
  const currentOrder = setupSteps[currentStepId].order
  return Object.values(setupSteps)
      .find(step => step.order === currentOrder - 1)?.id
}

export const isValidStep = (stepId) => {
  return stepId in setupSteps
}

export const getStepTitle = (stepId) => {
  return setupSteps[stepId]?.title || ''
}

export const getStepDescription = (stepId) => {
  return setupSteps[stepId]?.description || ''
}

export const getStepOrder = (stepId) => {
  return setupSteps[stepId]?.order || 0
}

export const getTotalSteps = () => {
  return Object.keys(setupSteps).length
}

export const isLastStep = (stepId) => {
  return setupSteps[stepId]?.nextStep === null
}