<template>
  <VueFinalModal @closed="handleClose" :click-to-close="true" :esc-to-close="true" class="flex justify-center items-center" :content-class="'flex flex-col bg-white w-full shadow-lg h-screen lg:h-auto rounded-none md:rounded-md md:w-[calc(100%-4rem)] lg:w-full max-w-[640px]'">
    <div class="flex flex-col w-full h-full">
      <!-- Header -->
      <div class="sticky top-0 z-10 w-full flex items-center justify-between bg-primary/95 px-6 py-1 text-white shrink-0 rounded-t-md">
        <h2 class="text-base font-medium flex items-center gap-2">
          <span class="material-symbols-outlined !text-[20px]">warning</span> <span>{{ t('dashboard.components.keywordAnalysis.warnings.deletedKeywordsTitle') }}</span>
        </h2>
        <button @click="handleClose" class="p-1.5 rounded-md hover:bg-white/10 active:bg-white/20 transition-colors flex items-center">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>

      <!-- Content -->
      <div class="flex-1 overflow-y-auto px-6 py-4 max-h-[60vh] thin-scrollbar">
        <div class="space-y-4">
          <div v-for="keyword in keywords" :key="keyword.NAME" class="bg-gray-50 p-4 rounded-lg">
            <div class="flex items-center justify-between">
              <h3 class="text-base font-medium text-gray-900">{{ keyword.keywordName }}</h3>
              <div class="flex items-center gap-2">
                <span class="text-sm text-gray-600">{{ t('dashboard.components.keywordAnalysis.warnings.lastMonthVolume') }}:</span>
                <div class="flex gap-2">
                  <span class="text-sm px-2 py-1 rounded bg-gray-100">
                    {{ keyword.volume }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="sticky bottom-0 z-10 px-6 py-4 bg-gray-50 border-t border-gray-100 shrink-0 rounded-b-md">
        <div class="flex justify-end">
          <CustomButton @click="handleClose" :button-text="t('common.close')" size="sm" variation="outline"/>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'
import CustomButton from '@/components/base/buttons/VButton.vue'

const props = defineProps({
  keywords: {
    type: Array,
    required: false,
    default: () => [],
  },
})

const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()

const handleClose = () => {
  emit('update:modelValue', false)
}
</script>