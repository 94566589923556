<template>
  <VueFinalModal :click-to-close="true" :esc-to-close="true" @closed="handleModalClose" @opened="handleOpened" class="flex justify-center items-center" content-class="flex flex-col bg-white w-screen h-screen" contentTransition="vfm-fade">
    <div class="h-full flex flex-col">
      <div class="w-full flex items-center justify-between bg-primary bg-opacity-90 text-white px-4 py-3">
        <h2 class="text-sm font-medium flex items-center">
          <span class="material-symbols-outlined !text-[22px] mr-2">preview</span> {{ t('entity.components.newContent.components.previewModal.title') }} </h2>

        <div class="flex items-center gap-4">
          <div class="hidden sm:flex items-center gap-1.5 text-white/70">
            <kbd class="px-2 py-0.5 text-xs font-semibold bg-white/20 rounded">ESC</kbd> <span class="text-xs">{{ t('entity.components.newContent.components.previewModal.closeHint') }}</span>
          </div>

          <button @click="$emit('update:modelValue', false)" class="flex items-center gap-2 px-3 py-1.5 rounded-lg hover:bg-white/20 active:bg-white/30 transition-all group relative">
            <span class="hidden sm:inline text-sm">{{ t('common.close') }}</span> <span class="material-symbols-outlined !text-[22px]">close</span>
          </button>
        </div>
      </div>

      <div v-if="loading" class="flex-1 flex items-center justify-center">
        <span class="material-symbols-outlined animate-spin">progress_activity</span>
      </div>

      <template v-else>
        <div class="flex-1 overflow-y-auto p-6">
          <div class="max-w-4xl mx-auto">
            <article class="prose prose-sm lg:prose-base max-w-none">
              <h1 class="text-2xl font-bold text-gray-900 mb-4">{{ contentData.title }}</h1>

              <div class="flex items-center text-sm text-gray-500 mb-6 not-prose">
                <span class="material-symbols-outlined !text-[18px] mr-1">category</span> {{ contentData.category }}
              </div>

              <div v-if="formattedImages.length" class="mb-6 not-prose">
                <h3 class="text-sm font-medium text-gray-900 mb-2">
                  {{ t('entity.components.newContent.components.previewModal.coverImages') }} </h3>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div v-for="image in formattedImages" :key="image.id" class="aspect-video bg-gray-100 rounded-lg overflow-hidden">
                    <img :src="image.image" :alt="contentData.title" class="w-full h-full object-cover">
                  </div>
                </div>
              </div>

              <div class="border-b border-gray-200 mb-6"></div>
              <div v-html="contentData.body" class="prose prose-sm lg:prose-base"></div>

              <div class="mt-8 pt-6 border-t border-gray-200 not-prose">
                <h3 class="text-sm font-medium text-gray-900 mb-2">
                  {{ t('entity.components.newContent.components.previewModal.metaInfo') }} </h3>
                <div class="space-y-2 text-sm text-gray-600">
                  <div>
                    <span class="font-medium">{{ t('entity.components.newContent.components.previewModal.metaDescription') }}:</span> {{ contentData.metaDescription }}
                  </div>
                  <div>
                    <span class="font-medium">{{ t('entity.components.newContent.components.previewModal.keywords') }}:</span> {{ contentData.keywords }}
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>

        <div v-if="showFooter && showActionButtons" class="w-full px-6 py-4 bg-gray-50 border-t border-gray-200">
          <div class="max-w-4xl mx-auto flex justify-end items-center gap-4">
            <CustomButton v-if="!assetStore.automaticPublishing && isRefreshable" :button-text="t('scheduleAssistant.components.content.refreshButton')" :full-width="false" color="secondary" :is-disabled="isContentRefreshing" :disable-gray="true" :is-loading="isContentRefreshing" icon="refresh" :loading-text="t('scheduleAssistant.components.content.refreshing')" @click="refreshContent" size="sm"/>
            <CustomButton v-if="!assetStore.automaticPublishing && isRejectable" :button-text="t('scheduleAssistant.components.content.rejectButton')" :full-width="false" color="primary" :is-disabled="buttonLoading || isContentRefreshing" :disable-gray="true" :is-loading="buttonLoading" @click="reject" size="sm"/>
            <CustomButton v-if="!assetStore.automaticPublishing && isConfirmable" :button-text="t('scheduleAssistant.components.content.confirmButton')" :full-width="false" color="secondary" :is-disabled="buttonLoading || isContentRefreshing" :disable-gray="true" :is-loading="buttonLoading" @click="confirm" size="sm"/>
          </div>
        </div>
      </template>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, nextTick, onBeforeUnmount } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'
import { useAssetStore } from '@/stores/asset'
import CustomButton from '@/components/base/buttons/VButton.vue'
import axios from '@/plugins/axiosInstance'
import { scheduleAssistant } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const props = defineProps({
  content: {
    type: Object,
    required: false,
  },
  showFooter: {
    type: Boolean,
    default: false,
  },
  fetchData: {
    type: Boolean,
    default: false,
  },
  rowId: {
    type: [String, Number],
    required: false,
  },
})

const emit = defineEmits(['update:modelValue', 'confirmed', 'rejected', 'refreshed', 'onModalClosed'])
const { t } = useI18n()
const assetStore = useAssetStore()

const loading = ref(true)
const buttonLoading = ref(false)
const isContentRefreshing = ref(false)
const contentData = ref({})

const formattedImages = computed(() => {
  return Array.isArray(contentData.value?.images) ? contentData.value.images : []
})

const handleOpened = async () => {
  if (props.fetchData && props.rowId) {
    loading.value = true
    try {
      const response = await axios.get(scheduleAssistant.getContent, {
        params: {
          assetId: assetStore.assetId,
          rowId: props.rowId,
        },
      })
      const data = response.data.data
      contentData.value = {
        title: data.title,
        category: data.category,
        body: data.content,
        metaDescription: data.metaDescription,
        keywords: data.seoKeywords ? JSON.parse(data.seoKeywords).join(', ') : '',
        images: data.imageUrl ? JSON.parse(data.imageUrl).map(img => ({
          id: img.id,
          image: img.image,
        })) : [],
        id: data.id,
        stateId: data.stateId,
      }
    } catch (error) {
      responseHandlerService.handleError(error, t('scheduleAssistant.components.content.loadContentError'))
    } finally {
      loading.value = false
    }
  } else {
    contentData.value = props.content
    loading.value = false
  }

}

const refreshContent = async () => {
  isContentRefreshing.value = true
  loading.value = true
  try {
    const response = await axios.post(scheduleAssistant.refreshContent, {
      id: contentData.value.id,
      assetId: assetStore.assetId,
    })

    const data = response.data.data
    contentData.value = {
      title: data.title,
      category: data.category,
      body: data.content,
      metaDescription: data.metaDescription,
      keywords: data.seoKeywords ? JSON.parse(data.seoKeywords).join(', ') : '',
      images: data.imageUrl ? JSON.parse(data.imageUrl).map(img => ({
        id: img.id,
        image: img.image,
      })) : [],
      id: data.id,
    }
    emit('refreshed', { content: contentData.value, result: true })
  } catch (error) {
    responseHandlerService.handleError(error, t('scheduleAssistant.components.content.refreshContentError'))
  } finally {
    isContentRefreshing.value = false
    loading.value = false
  }
}


const confirm = async () => {
  buttonLoading.value = true
  try {
    await axios.post(scheduleAssistant.confirmContent, {
      id: contentData.value.id,
      assetId: assetStore.assetId,
    })
    emit('confirmed', { id: contentData.value.id, result: true })
    emit('update:modelValue', false)
  } catch (error) {
    responseHandlerService.handleError(error, t('scheduleAssistant.components.content.confirmContentError'))
    emit('confirmed', { id: contentData.value.id, result: false })
  } finally {
    buttonLoading.value = false
  }
}

const reject = async () => {
  buttonLoading.value = true
  try {
    await axios.delete(scheduleAssistant.rejectContent, {
      params: {
        id: contentData.value.id,
        assetId: assetStore.assetId,
      },
    })
    emit('rejected', { id: contentData.value.id, result: true })
    emit('update:modelValue', false)
  } catch (error) {
    responseHandlerService.handleError(error, t('scheduleAssistant.components.content.rejectContentError'))
    emit('rejected', { id: contentData.value.id, result: false })
  } finally {
    buttonLoading.value = false
  }
}

const showActionButtons = computed(() => {
  return ![2, 3, 4, 5].includes(contentData.value?.stateId)
})

const isRefreshable = computed(() => {
  return contentData.value?.stateId === 1
})

const isConfirmable = computed(() => {
  return contentData.value?.stateId === 1
})

const isRejectable = computed(() => {
  return contentData.value?.stateId === 1
})

const handleModalClose = () => {
  emit('update:modelValue', false)
  emit('onModalClosed')
}
</script>

<style>
:deep(.vfm__content) {
  border-radius: 0 !important;
  margin: 0 !important;
}
</style>