<template>
  <div :class="outerDivClass">
    <div v-if="showProgressBar" class="w-full max-w-3xl mx-auto mb-12">
      <StepVisualization :steps="steps" :current-step="stepsStore.currentStepIndex" :current-nested-step="stepsStore.currentNestedStepIndex" :completed-steps="completedStepsList" @step-click="handleStepClick"/>
    </div>

    <div class="w-full mx-auto">
      <div class="bg-white rounded-md transition-all duration-500 p-4">
        <component :is="getCurrentComponent" v-if="!loading" v-bind="getCurrentProps" :step="currentStepConfig" :current-nested-step="stepsStore.currentNestedStepIndex" @stepValidation="handleStepValidation" @stepData="handleStepData" ref="currentStepRef"/>
      </div>

      <StepNavigation v-if="showNavigation" :can-go-next="canGoNext && !stepsStore.navigation.nextDisabled" :can-go-prev="canGoPrev && !stepsStore.navigation.prevDisabled" :loading="loading" :is-last-step="isLastStep" :next-button-text="getNextButtonText" :prev-button-text="getPrevButtonText" @next="handleNext" @prev="handlePrev"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useStepsStore } from '@/stores/steps'
import { useI18n } from 'vue-i18n'
import StepVisualization from './components/StepVisualization.vue'
import StepNavigation from './components/StepNavigation.vue'

const props = defineProps({
  steps: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showProgressBar: {
    type: Boolean,
    default: true,
  },
  showNavigation: {
    type: Boolean,
    default: true,
  },
  persistKey: {
    type: String,
    required: true,
  },
  outerDivClass: {
    type: String,
    default: 'w-full flex flex-col items-center justify-center py-8 px-4',
  },
})

const emit = defineEmits(['step-change', 'complete'])
const stepsStore = useStepsStore()
const { t } = useI18n()
const currentStepRef = ref(null)
const isValidating = ref(false)

const completedStepsList = computed(() => {
  const lastCompleted = stepsStore.lastCompletedStepIndex
  return Array.from({ length: lastCompleted + 1 }, (_, i) => i)
})

const currentStepConfig = computed(() => props.steps[stepsStore.currentStepIndex])
const isLastStep = computed(() => stepsStore.currentStepIndex === props.steps.length - 1)

const getCurrentComponent = computed(() => currentStepConfig.value?.component)
const getCurrentProps = computed(() => ({
  ...currentStepConfig.value?.props || {},
  currentNestedStep: stepsStore.currentNestedStepIndex,
  stepData: stepsStore.getStepData(getStepKey.value),
}))

const getStepKey = computed(() => {
  const step = currentStepConfig.value
  if (step?.nestedSteps) {
    return `${props.persistKey}_${step.ref}_${step.nestedSteps[stepsStore.currentNestedStepIndex].ref}`
  }
  return `${props.persistKey}_${step?.ref}`
})

const canGoNext = computed(() => {
  if (props.loading || isValidating.value) return false
  const currentValidation = stepsStore.getStepValidation(getStepKey.value)
  return currentValidation.isValid && Object.keys(currentValidation.errors).length === 0
})

const canGoPrev = computed(() => {
  if (props.loading) return false
  return stepsStore.currentStepIndex > 0 || stepsStore.currentNestedStepIndex > 0
})

const getNextButtonText = computed(() => {
  const step = currentStepConfig.value
  if (step?.buttonText) {
    return step.buttonLoadingText && props.loading ? t(step.buttonLoadingText) : t(step.buttonText)
  }
  return t('common.next')
})

const getPrevButtonText = computed(() => {
  const step = currentStepConfig.value
  return step?.prevButtonText ? t(step.prevButtonText) : t('common.previous')
})

const handleStepValidation = async ({ isValid, errors = {} }) => {
  await stepsStore.setStepValidation({ stepKey: getStepKey.value, isValid, errors })

  if (isValid && Object.keys(errors).length === 0 && !completedStepsList.value.includes(stepsStore.currentStepIndex)) {
    await stepsStore.updateStepCompletion({
      stepIndex: stepsStore.currentStepIndex,
      nestedStepIndex: stepsStore.currentNestedStepIndex,
    })
  }
}

const handleStepData = async (data) => {
  await stepsStore.setStepData({ stepKey: getStepKey.value, data })
}

const validateCurrentStep = async () => {
  isValidating.value = true
  try {
    const currentRef = currentStepRef.value
    if (!currentRef?.validate) return true

    const result = await currentRef.validate()
    if (typeof result === 'object') {
      const { isValid, errors = {} } = result
      await handleStepValidation({ isValid, errors })
      return isValid
    }

    await handleStepValidation({ isValid: result, errors: {} })
    return result
  } catch (error) {
    console.error('Step validation error:', error)
    await handleStepValidation({ isValid: false, errors: { general: error.message } })
    return false
  } finally {
    isValidating.value = false
  }
}

const handleStepClick = async (index) => {
  if (!completedStepsList.value.includes(index) && index !== stepsStore.currentStepIndex) return

  if (index > stepsStore.currentStepIndex) {
    const isValid = await validateCurrentStep()
    if (!isValid) return
  }

  await stepsStore.setCurrentStep({ stepIndex: index, nestedStepIndex: 0 })
  emit('step-change', index)
}

const handleNext = async () => {
  if (!canGoNext.value) return

  const isValid = await validateCurrentStep()
  if (!isValid) return

  const step = currentStepConfig.value
  if (step?.nestedSteps) {
    if (stepsStore.currentNestedStepIndex < step.nestedSteps.length - 1) {
      await stepsStore.setCurrentStep({
        stepIndex: stepsStore.currentStepIndex,
        nestedStepIndex: stepsStore.currentNestedStepIndex + 1,
      })
      await handleStepValidation({ isValid: false, errors: {} })
    } else {
      const currentRef = currentStepRef.value
      if (currentRef?.finalize) {
        const finalizeResult = await currentRef.finalize()
        if (!finalizeResult) return
      }
      await moveToNextStep()
    }
  } else {
    await moveToNextStep()
  }
}

const moveToNextStep = async () => {
  const nextStep = stepsStore.currentStepIndex + 1
  if (nextStep >= props.steps.length) {
    emit('complete')
    return
  }

  await stepsStore.setCurrentStep({ stepIndex: nextStep, nestedStepIndex: 0 })
  emit('step-change', nextStep)
}

const handlePrev = async () => {
  if (!canGoPrev.value) return

  if (currentStepConfig.value?.nestedSteps && stepsStore.currentNestedStepIndex > 0) {
    await stepsStore.setCurrentStep({
      stepIndex: stepsStore.currentStepIndex,
      nestedStepIndex: stepsStore.currentNestedStepIndex - 1,
    })
  } else {
    const prevStep = stepsStore.currentStepIndex - 1
    if (prevStep >= 0) {
      await stepsStore.setCurrentStep({ stepIndex: prevStep, nestedStepIndex: 0 })
      emit('step-change', prevStep)
    }
  }
}

watch(() => props.steps, validateCurrentStep, { deep: true })

onMounted(async () => {
  await stepsStore.setCurrentStep({
    stepIndex: stepsStore.currentStepIndex,
    nestedStepIndex: stepsStore.currentNestedStepIndex,
  })
  await validateCurrentStep()
})
</script>