<template>
  <VueFinalModal v-model="show" class="flex justify-center items-center" content-class="relative bg-white rounded-lg max-w-xl w-full mx-4" @closed="handleClose">
    <div class="p-6">
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-lg font-semibold text-gray-800">
          {{ editMode ? t('shared.paymentInformation.edit') : t('shared.paymentInformation.add') }} </h3>
        <VButton @click="$emit('update:modelValue', false)" variation="rounded-full" color="neutral" size="sm" :icon="'close'" :show-icon="true" :is-text-showable="false"/>
      </div>

      <form @submit.prevent="handleSubmit" class="space-y-4">
        <div class="grid grid-cols-2 gap-4">
          <CustomInput v-model="formData.name" :label="t('shared.paymentInformation.fields.name')" required :error="validationErrors.name" ref="nameInput"/>
          <CustomInput v-model="formData.surname" :label="t('shared.paymentInformation.fields.surname')" required :error="validationErrors.surname" ref="surnameInput"/>
        </div>

        <CustomInput v-model="formData.email" :label="t('shared.paymentInformation.fields.email')" type="email" required :error="validationErrors.email" ref="emailInput"/>

        <CustomInput v-model="formData.phone" :label="t('shared.paymentInformation.fields.phone')" type="tel" required :error="validationErrors.phone" ref="phoneInput"/>

        <CustomInput v-model="formData.identityNumber" :label="t('shared.paymentInformation.fields.identityNumber')" required :error="validationErrors.identityNumber" ref="identityNumberInput"/>

        <CustomInput v-model="formData.address" :label="t('shared.paymentInformation.fields.address')" type="textarea" required :error="validationErrors.address" ref="addressInput"/>

        <div class="grid grid-cols-2 gap-4">
          <CustomInput v-model="formData.city" :label="t('shared.paymentInformation.fields.city')" required :error="validationErrors.city" ref="cityInput"/>
          <CustomInput v-model="formData.country" :label="t('shared.paymentInformation.fields.country')" required :error="validationErrors.country" ref="countryInput"/>
        </div>

        <div class="flex justify-end gap-3 mt-6">
          <VButton type="button" @click="$emit('update:modelValue', false)" variation="outline" color="neutral" :button-text="t('common.cancel')"/>
          <VButton type="submit" color="primary" :is-loading="loading" :button-text="editMode ? t('common.save') : t('common.add')"/>
        </div>
      </form>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, reactive, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'
import { createBillingSchema } from '@/validations/schemas/asset.create.billing.schema'
import CustomInput from '@/components/base/inputs/VInput.vue'
import VButton from '@/components/base/buttons/VButton.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  editMode: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  initialData: {
    type: Object,
    default: () => ({}),
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['update:modelValue', 'submit'])
const { t } = useI18n()
const show = ref(props.modelValue)
const validationErrors = reactive({})

// Input refs for handling touched state
const inputRefs = {
  nameInput: ref(null),
  surnameInput: ref(null),
  emailInput: ref(null),
  phoneInput: ref(null),
  identityNumberInput: ref(null),
  addressInput: ref(null),
  cityInput: ref(null),
  countryInput: ref(null),
}

const formData = reactive({
  name: '',
  surname: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  country: '',
  identityNumber: '',
})

const validateForm = async () => {
  try {
    const schema = createBillingSchema(t)
    await schema.validate(formData, { abortEarly: false })
    return true
  } catch (err) {
    err.inner.forEach(error => {
      validationErrors[error.path] = error.message
      // Set touched state for fields with errors
      const inputRef = inputRefs[`${error.path}Input`].value
      if (inputRef) {
        inputRef.setTouched(true)
      }
    })
    return false
  }
}

const handleSubmit = async () => {
  // Set all fields as touched
  Object.values(inputRefs).forEach(ref => {
    if (ref.value) {
      ref.value.setTouched(true)
    }
  })

  // Clear previous validation errors
  Object.keys(validationErrors).forEach(key => delete validationErrors[key])

  const isValid = await validateForm()
  if (isValid) {
    emit('submit', { ...formData })
  }
}

const handleClose = () => {
  Object.keys(formData).forEach(key => formData[key] = '')
  Object.keys(validationErrors).forEach(key => delete validationErrors[key])
  // Reset touched state for all inputs
  Object.values(inputRefs).forEach(ref => {
    if (ref.value) {
      ref.value.setTouched(false)
    }
  })
  emit('update:modelValue', false)
}

watch(() => props.modelValue, (newValue) => {
  show.value = newValue
})

watch(() => props.initialData, (newData) => {
  if (newData) {
    Object.keys(formData).forEach(key => {
      formData[key] = newData[key] || ''
    })
  }
}, { deep: true })

// Watch for backend errors
watch(() => props.errors, (newErrors) => {
  Object.assign(validationErrors, newErrors)
}, { deep: true })
</script>