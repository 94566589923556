<template>
  <VueFinalModal @opened="handleModalOpened" v-model="showModal" class="flex justify-center items-center" content-class="relative flex flex-col mx-auto bg-white p-4 rounded-lg w-full max-w-6xl">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-semibold">{{ t('globalComponents.engineLocationModal.title') }}</h2>
      <button @click="closeModal" class="text-gray-500 hover:text-gray-700">
        <span class="material-symbols-outlined">close</span>
      </button>
    </div>

    <div class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
      <div v-for="engine in engines" :key="engine.id" class="flex-1 border rounded-lg p-4">
        <div class="flex items-center space-x-2 mb-4">
          <img :src="engine.icon" :alt="engine.name" class="w-6 h-6">
          <h3 class="text-lg font-semibold">{{ engine.name }}</h3>
          <span class="text-sm text-gray-500">
            ({{ getCurrentFilteredLocationCount(engine.name) }} {{ t('globalComponents.engineLocationModal.locationsSelected') }})
          </span>
        </div>

        <CustomInput v-model="searchQueries[engine.name.toLowerCase()]" :placeholder="t('globalComponents.engineLocationModal.searchLocations')" @search="() => handleSearch(engine)" @detail-clicked="() => handleDetailClick(engine)" @keyup.enter="() => handleSearch(engine)" :show-detail-button="true" :detail-icon="hasSearched[engine.name.toLowerCase()] ? 'close' : 'search'" class="mb-4 text-sm"/>

        <div class="h-48 overflow-y-auto mb-4 text-sm thin-scrollbar">
          <div v-if="isLoading[engine.name.toLowerCase()]" class="p-2 text-gray-500 flex items-center justify-center h-full">
            <span class="material-symbols-outlined animate-spin mr-2">progress_activity</span> {{ t('globalComponents.engineLocationModal.loading') }}
          </div>
          <div v-else-if="searchQueries[engine.name.toLowerCase()] && getFilteredOptions(engine).length === 0" class="p-2 text-gray-500 h-full flex items-center justify-center">
            {{ t('globalComponents.engineLocationModal.noResults') }}
          </div>
          <div v-else class="space-y-2">
            <button v-for="option in getFilteredOptions(engine)" :key="option.id" @click="toggleSelection(engine, option)" class="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center justify-between" :class="{'bg-green-50': isSelected(engine, option)}">
              <span class="truncate mr-2">{{ getDisplayName(engine, option) }}</span> <span v-if="isSelected(engine, option)" class="text-green-600 flex-shrink-0">✓</span>
            </button>
          </div>
        </div>

        <div v-if="getCurrentFilteredLocationCount(engine.name) > 0" class="text-xs">
          <p class="font-medium mb-2">{{ t('globalComponents.engineLocationModal.selectedLocations') }}:</p>
          <div class="flex flex-wrap gap-1 max-h-20 overflow-y-auto">
            <button v-for="location in getCurrentSelectedLocations(engine)" :key="location.id" @click="toggleSelection(engine, location)" class="bg-gray-100 px-1.5 py-0.5 rounded-full flex items-center hover:bg-gray-200 text-xs">
              <span class="truncate max-w-[100px]">{{ getDisplayName(engine, location) }}</span> <span class="material-symbols-outlined !text-[18px] ml-1">close</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showSaveButton" class="mt-6 flex justify-end">
      <CustomButton :button-text="t('globalComponents.engineLocationModal.saveButton')" @click="saveLocations" size="sm"/>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import CustomInput from '@/components/base/inputs/VInput.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import { useAssetStore } from '@/stores/asset'

const props = defineProps({
  modelValue: Boolean,
  locations: Object,
  showSaveButton: {
    type: Boolean,
    default: false,
  },
  stateMode: { type: Boolean, default: true },
})

const emit = defineEmits(['update:modelValue', 'update:locations'])

const { t } = useI18n()
const assetStore = useAssetStore()

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const engines = ref([
  { id: 1, name: 'Yandex', icon: require('@/assets/icons/yandex.svg') },
  { id: 2, name: 'Google', icon: require('@/assets/icons/google.svg') },
  { id: 3, name: 'Bing', icon: require('@/assets/icons/bing.svg') },
])

const searchQueries = ref({
  yandex: '',
  google: '',
  bing: '',
})

const isLoading = ref({
  yandex: false,
  google: false,
  bing: false,
})

const totalCount = ref({
  yandex: 0,
  google: 0,
  bing: 0,
})

const hasSearched = ref({
  yandex: false,
  google: false,
  bing: false,
})

const options = ref({
  yandex: [],
  google: [],
  bing: [],
})

const selectedLocations = ref({
  yandex: [],
  google: [],
  bing: [],
})

const closeModal = () => {
  showModal.value = false
}

const fetchLocations = async (engine) => {
  const engineName = engine.name.toLowerCase()
  isLoading.value[engineName] = true
  try {
    const res = await axios.get(assets.searchEngineLocation, {
      params: {
        searchEngineId: engine.id,
        search: searchQueries.value[engineName],
        assetId: assetStore.assetId,
      },
    })

    if (res.data.data) {
      options.value[engineName] = res.data.data.detail.map(location => ({
        id: location.id,
        locationId: location.locationId,
        name: location.name,
        engineId: engine.id,
        canonicalName: location.canonicalName,
      }))
      totalCount.value[engineName] = res.data.data.totalCount
    }
  } catch (error) {
    responseHandlerService.handleError(error)
  } finally {
    isLoading.value[engineName] = false
  }
}

const toggleSelection = (engine, option) => {
  const engineName = engine.name.toLowerCase()
  const index = selectedLocations.value[engineName].findIndex(loc => {
    if (engine.name === 'Yandex') return loc.name === option.name
    else return loc.name === option.name || loc.canonicalName === option.canonicalName
  })

  if (props.stateMode) {
    if (index !== -1) {
      if (selectedLocations.value[engineName][index].state === 1) {
        selectedLocations.value[engineName][index].state = 3
        responseHandlerService.handleSuccess(t('globalComponents.engineLocationModal.deselectionSuccess'))
      } else if (selectedLocations.value[engineName][index].state === 2) {
        selectedLocations.value[engineName].splice(index, 1)
        responseHandlerService.handleSuccess(t('globalComponents.engineLocationModal.deselectionSuccess'))
      } else if (selectedLocations.value[engineName][index].state === 3) {
        selectedLocations.value[engineName][index].state = 1
        responseHandlerService.handleSuccess(t('globalComponents.engineLocationModal.reselectionSuccess'))
      }
    } else {
      const activeSelections = selectedLocations.value[engineName].filter(loc => loc.state === 1 || loc.state === 2)
      if (activeSelections.length >= 3) {
        responseHandlerService.handleWarning(t('globalComponents.engineLocationModal.limitWarning'))
        return
      }
      selectedLocations.value[engineName].push({ ...option, state: 2 })
    }
  } else {
    if (index !== -1) {
      selectedLocations.value[engineName].splice(index, 1)
      responseHandlerService.handleSuccess(t('globalComponents.engineLocationModal.deselectionSuccess'))
    } else {
      if (selectedLocations.value[engineName].length >= 3) {
        responseHandlerService.handleWarning(t('globalComponents.engineLocationModal.limitWarning'))
        return
      }
      selectedLocations.value[engineName].push(option)
    }
  }

  selectedLocations.value[engineName] = [...selectedLocations.value[engineName]]
  searchQueries.value[engineName] = ''
  fetchLocations(engine)

  if (!props.showSaveButton) {
    updateLocations()
  }
}

// Rest of the functions remain the same
const isSelected = (engine, option) => {
  const engineName = engine.name.toLowerCase()
  return selectedLocations.value[engineName].some(loc => {
    if (engine.name === 'Yandex') return loc.name === option.name && (loc.state === 1 || loc.state === 2)
    return loc.canonicalName === option.canonicalName && (loc.state === 1 || loc.state === 2)
  })
}

const truncate = (str, n) => {
  return (str.length > n) ? str.substr(0, n - 1) + '...' : str
}

const getDisplayName = (engine, option) => {
  const name = engine.name === 'Yandex' ? option.name : option.canonicalName || option.name
  return truncate(name, 30)
}

const getCurrentFilteredLocationCount = (engineName) => {
  return selectedLocations.value[engineName.toLowerCase()].filter(loc => loc.state !== 3).length
}

const getFilteredOptions = (engine) => {
  const engineName = engine.name.toLowerCase()
  return options.value[engineName].filter(option =>
      !selectedLocations.value[engineName].some(loc => loc.id === option.id && loc.state !== 3),
  )
}

const getCurrentSelectedLocations = (engine) => {
  const engineName = engine.name.toLowerCase()
  return selectedLocations.value[engineName].filter(loc => loc.state !== 3)
}

const updateLocations = () => {
  emit('update:locations', JSON.parse(JSON.stringify(selectedLocations.value)))
}

const saveLocations = () => {
  updateLocations()
  responseHandlerService.handleSuccess(t('globalComponents.engineLocationModal.locationsUpdated'))
  closeModal()
}

const handleModalOpened = () => {
  engines.value.forEach(engine => {
    fetchLocations(engine)
  })
}

const handleSearch = (engine) => {
  const engineName = engine.name.toLowerCase()
  if (searchQueries.value[engineName]) {
    hasSearched.value[engineName] = true
    fetchLocations(engine)
  }
}

const handleDetailClick = (engine) => {
  const engineName = engine.name.toLowerCase()
  if (hasSearched.value[engineName]) {
    hasSearched.value[engineName] = false
    searchQueries.value[engineName] = ''
    fetchLocations(engine)
  } else {
    if (searchQueries.value[engineName]) {
      hasSearched.value[engineName] = true
      fetchLocations(engine)
    }
  }
}

watch(() => props.locations, (newLocations) => {
  selectedLocations.value = JSON.parse(JSON.stringify(newLocations))
}, { immediate: true, deep: true })
</script>