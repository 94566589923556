<template>
  <div class="bg-white rounded-lg shadow-md p-4 max-w-5xl mx-auto">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-lg font-semibold text-gray-900 flex items-center gap-2">
        <span class="material-symbols-outlined">check_circle</span> {{ t('entity.components.videoCreation.success.title') }} </h2>
      <CustomButton :button-text="t('entity.components.videoCreation.success.newVideo')" icon="add" size="sm" color="primary" variation="outline" :show-icon="true" @click="$emit('reset')"/>
    </div>

    <div class="rounded-lg overflow-hidden bg-gray-50 p-6">
      <div class="flex flex-col items-center justify-center text-center space-y-4">
        <div class="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center">
          <span class="material-symbols-outlined text-primary text-3xl">mail</span>
        </div>
        <h3 class="text-xl font-medium text-gray-900">
          {{ t('entity.components.videoCreation.success.messageTitle') }} </h3>
        <p class="text-gray-600 max-w-md">
          {{ t('entity.components.videoCreation.success.messageDescription') }} </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import CustomButton from '@/components/base/buttons/VButton.vue'

const { t } = useI18n()

defineEmits(['reset'])
</script>