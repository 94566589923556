// Ondalıklı sayıları formatla
export const formatDecimal = (value, decimals = 0) => {
  if (value == null) return '0'
  try {
    const num = Number(value)
    if (isNaN(num)) return '0'
    return num.toLocaleString('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
  } catch {
    return '0'
  }
}

// Büyük sayıları formatla (1K, 1M, 1B gibi)
export const formatLargeNumber = (value) => {
  if (value == null) return '0'
  try {
    const num = Number(value)
    if (isNaN(num)) return '0'

    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1) + 'B'
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M'
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K'
    }

    return num.toString()
  } catch {
    return '0'
  }
}

export const formatFileSize = (bytes) => {
  if (bytes >= 1024 * 1024 * 1024) {
    return { size: (bytes / (1024 * 1024 * 1024)).toFixed(1), unit: 'GB' }
  } else if (bytes >= 1024 * 1024) {
    return { size: (bytes / (1024 * 1024)).toFixed(1), unit: 'MB' }
  } else if (bytes >= 1024) {
    return { size: (bytes / 1024).toFixed(1), unit: 'KB' }
  }
  return { size: bytes, unit: 'B' }
}

// Özel metrik formatı (7.55M%, 106.44M gibi)
export const formatMetric = (value) => {
  if (!value) return '0'
  // String olarak gelen değeri olduğu gibi döndür
  return value
}

/**
 * Safely parse JSON string and validate data type
 * @param {any} data - The data to parse/validate
 * @param {any} fallback - Fallback value if parsing fails (default: null)
 * @param {'object' | 'array' | 'any'} expectedType - Expected type of parsed data (default: 'any')
 * @param {boolean} logError - Whether to log parsing errors (default: true)
 * @returns {any} Parsed and validated data or fallback value
 */
export const safeParse = (data, fallback = null, expectedType = 'any', logError = true) => {
  // Handle null/undefined
  if (data == null) return fallback

  // If data is already of expected type, return it
  if (expectedType === 'array' && Array.isArray(data)) return data
  if (expectedType === 'object' && typeof data === 'object' && !Array.isArray(data)) return data
  if (expectedType === 'any' && typeof data === 'object') return data

  // If data is string, try to parse it
  if (typeof data === 'string') {
    try {
      const parsed = JSON.parse(data)

      // Validate parsed data matches expected type
      if (expectedType === 'array' && !Array.isArray(parsed)) {
        if (logError) console.warn('Parsed data is not an array')
        return fallback
      }
      if (expectedType === 'object' && (typeof parsed !== 'object' || Array.isArray(parsed))) {
        if (logError) console.warn('Parsed data is not an object')
        return fallback
      }

      return parsed
    } catch (error) {
      if (logError) {
        console.warn('Failed to parse JSON:', error.message)
      }
      return fallback
    }
  }

  // If we get here, data is neither string nor expected type
  if (logError) {
    console.warn(`Data is not a string or ${expectedType}`)
  }
  return fallback
}

/**
 * Check if parsed data has content in any of given keys
 * @param {object} data - The object to check
 * @param {string[]} keys - Array of keys to check for content
 * @returns {boolean} Whether any key has content
 */
export const hasContent = (data, keys) => {
  if (!data || typeof data !== 'object') return false

  return keys.some(key => {
    const value = data[key]
    if (Array.isArray(value)) return value.length > 0
    if (typeof value === 'object') return Object.keys(value).length > 0
    return !!value
  })
}