<template>
  <VueFinalModal :click-to-close="!isLoading" :esc-to-close="!isLoading" @opened="handleModalOpened" @closed="handleModalClosed" class="flex justify-center items-center" content-class="flex flex-col bg-white rounded-lg shadow-xl w-full md:w-[90vw] md:max-w-5xl h-[90vh] md:h-[70vh]">
    <div class="h-full flex flex-col">
      <!-- Header -->
      <header class="bg-white border-b px-4 py-2 rounded-t-lg flex items-center justify-between">
        <h2 class="text-lg font-bold text-gray-800">
          {{ isEditing ? t('settings.components.configuration.components.backlinksModal.editTitle') : t('settings.components.configuration.components.backlinksModal.title') }} </h2>
        <button type="button" @click="closeModal" class="text-gray-600 hover:text-gray-800 flex items-center">
          <span class="material-symbols-outlined">close</span>
        </button>
      </header>

      <div class="flex-grow flex flex-col overflow-hidden p-4">
        <!-- Edit Form -->
        <div v-if="isEditing" class="flex flex-col space-y-4">
          <VInput v-model="editingBacklink.link" type="text" :label="t('settings.components.configuration.components.backlinksModal.link')" :placeholder="t('settings.components.configuration.components.backlinksModal.linkPlaceholder')" :show-icon="true" icon="link" :error="errors.link" :required="true"/>
          <VInput v-model="editingBacklink.description" type="textarea" :label="t('settings.components.configuration.components.backlinksModal.description')" :placeholder="t('settings.components.configuration.components.backlinksModal.descriptionPlaceholder')" text-area-height="h-20" :error="errors.description" :required="true" :max-length="500"/>
          <VInput v-model="editingBacklink.source" type="text" :label="t('settings.components.configuration.components.backlinksModal.source')" :placeholder="t('settings.components.configuration.components.backlinksModal.sourcePlaceholder')" :show-icon="true" icon="source" :error="errors.source" :required="true"/>
        </div>

        <!-- Backlinks Table -->
        <div v-else class="flex flex-col h-full">
          <CustomTable :data="backlinks" :columns="tableColumns" :is-loading="isLoading" :show-pagination="true" min-height="300px" max-height="400px">
            <template #table-actions>
              <VButton @click="showAddForm" :button-text="t('settings.components.configuration.components.backlinksModal.addNew')" icon="add" :show-icon="true" size="sm" :is-disabled="isLoading"/>
            </template>

            <template #link="{ item }">
              <div class="relative max-w-[300px]">
                <Tooltip :title="item.link" :display-condition="item.link.length > maxChars" z-index="999999">
                  <template #content>
                    <a :href="item.link" target="_blank" class="text-primary hover:text-primary-dark truncate block"> {{ truncatedText(item.link) }} </a>
                  </template>
                </Tooltip>
              </div>
            </template>

            <template #description="{ item }">
              <div class="relative max-w-[300px]">
                <Tooltip :title="item.description" :display-condition="item.description.length > maxChars" z-index="999999">
                  <template #content>
                    <div class="truncate">
                      {{ truncatedText(item.description) }}
                    </div>
                  </template>
                </Tooltip>
              </div>
            </template>

            <template #source="{ item }">
              <div class="relative max-w-[150px]">
                <Tooltip :title="item.source" :display-condition="item.source.length > maxChars" z-index="999999">
                  <template #content>
                    <div class="truncate">
                      {{ truncatedText(item.source) }}
                    </div>
                  </template>
                </Tooltip>
              </div>
            </template>

            <template #actions="{ item }">
              <div class="flex items-center space-x-2 justify-center">
                <Tooltip :title="t('settings.components.configuration.components.backlinksModal.edit')" :display-condition="true" z-index="999999">
                  <template #content>
                    <button @click="editBacklink(item)" class="text-blue-500 hover:text-blue-700 flex items-center" :disabled="isLoading || isDeletingId === item.id || isDeleting">
                      <span class="material-symbols-outlined !text-[20px]">edit</span>
                    </button>
                  </template>
                </Tooltip>
                <Tooltip :title="isDeletingId === item.id
                    ? t('settings.components.configuration.components.backlinksModal.confirmDelete')
                    : t('settings.components.configuration.components.backlinksModal.delete')" :display-condition="true" z-index="999999">
                  <template #content>
                    <button @click="handleDelete(item.id)" class="flex items-center relative transition-colors duration-300" :class="isDeletingId === item.id ? 'text-green-500 hover:text-green-700' : 'text-red-500 hover:text-red-700'" :disabled="isLoading || isDeleting">
                      <!-- Loading Icon -->
                      <span class="material-symbols-outlined !text-[20px] absolute inset-0 transition-transform duration-300 animate-spin" :class="isDeleting && isDeletingId === item.id ? 'rotate-0 opacity-100' : 'rotate-90 opacity-0'">
                        progress_activity
                      </span>

                      <!-- Confirm Icon -->
                      <span class="material-symbols-outlined !text-[20px] absolute inset-0 transition-transform duration-300" :class="isDeletingId === item.id && !isDeleting ? 'rotate-0 opacity-100' : 'rotate-90 opacity-0'">
                        check_circle
                      </span>

                      <!-- Delete Icon -->
                      <span class="material-symbols-outlined !text-[20px] transition-transform duration-300 animate-spin" :class="isDeletingId === item.id || isDeleting ? '-rotate-90 opacity-0' : 'rotate-0 opacity-100'">
                        delete
                      </span>
                    </button>
                  </template>
                </Tooltip>
              </div>
            </template>
          </CustomTable>
        </div>
      </div>

      <!-- Footer -->
      <footer v-if="isEditing" class="border-t px-4 py-3 bg-gray-50 rounded-b-lg">
        <div class="flex justify-end space-x-2">
          <VButton @click="cancelEdit" :button-text="t('settings.components.configuration.components.backlinksModal.cancel')" color="secondary" size="sm" :is-disabled="isSubmitting"/>
          <VButton @click="saveEdit" :button-text="t('settings.components.configuration.components.backlinksModal.save')" :is-loading="isSubmitting" size="sm" :is-disabled="!isFormValid"/>
        </div>
      </footer>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import VButton from '@/components/base/buttons/VButton.vue'
import VInput from '@/components/base/inputs/VInput.vue'
import CustomTable from '@/components/table/VTable.vue'
import Tooltip from '@/components/common/VTooltip.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useAssetStore } from '@/stores/asset'

const { t } = useI18n()
const assetStore = useAssetStore()

const emit = defineEmits(['update:show'])

const isLoading = ref(false)
const isSubmitting = ref(false)
const backlinks = ref([])
const isEditing = ref(false)
const isDeletingId = ref(null)
const isDeleting = ref(false)
const deleteTimeout = ref(null)
const maxChars = ref(40)

const editingBacklink = ref({
  id: null,
  link: '',
  description: '',
  source: '',
})

const errors = ref({
  link: '',
  description: '',
  source: '',
})

const truncatedText = (text) => {
  if (text.length <= maxChars.value) return text
  return `${text.slice(0, maxChars.value)}...`
}

const validateForm = () => {
  errors.value = {
    link: '',
    description: '',
    source: '',
  }

  if (!editingBacklink.value.link) {
    errors.value.link = t('settings.components.configuration.components.backlinksModal.linkRequired')
  } else if (!isValidUrl(editingBacklink.value.link)) {
    errors.value.link = t('settings.components.configuration.components.backlinksModal.invalidUrl')
  }

  if (!editingBacklink.value.description) {
    errors.value.description = t('settings.components.configuration.components.backlinksModal.descriptionRequired')
  }

  if (!editingBacklink.value.source) {
    errors.value.source = t('settings.components.configuration.components.backlinksModal.sourceRequired')
  }

  return !errors.value.link && !errors.value.description && !errors.value.source
}

const isValidUrl = (url) => {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

const isFormValid = computed(() => {
  return editingBacklink.value.link &&
      editingBacklink.value.description &&
      editingBacklink.value.source &&
      isValidUrl(editingBacklink.value.link)
})

const tableColumns = [
  { key: 'link', label: t('settings.components.configuration.components.backlinksModal.link') },
  { key: 'description', label: t('settings.components.configuration.components.backlinksModal.description') },
  { key: 'source', label: t('settings.components.configuration.components.backlinksModal.source') },
  { key: 'actions', label: t('settings.components.configuration.components.backlinksModal.actions'), align: 'center' },
]

const fetchBacklinks = async () => {
  isLoading.value = true
  try {
    const { data } = await axios.get(settings.getBacklinks, {
      params: { assetId: assetStore.assetId },
    })
    backlinks.value = data.data
  } catch (error) {
    responseHandlerService.handleError(error)
  } finally {
    isLoading.value = false
  }
}

const showAddForm = () => {
  isEditing.value = true
  editingBacklink.value = {
    id: null,
    link: '',
    description: '',
    source: '',
  }
  errors.value = {
    link: '',
    description: '',
    source: '',
  }
}

const editBacklink = (backlink) => {
  isEditing.value = true
  editingBacklink.value = { ...backlink }
  errors.value = {
    link: '',
    description: '',
    source: '',
  }
}

const handleDelete = (id) => {
  if (isDeletingId.value === id) {
    deleteBacklink(id)
  } else {
    // Cancel any existing delete timeout
    if (deleteTimeout.value) {
      clearTimeout(deleteTimeout.value)
    }

    isDeletingId.value = id

    // Auto reset after 3 seconds
    deleteTimeout.value = setTimeout(() => {
      isDeletingId.value = null
    }, 3000)
  }
}

const deleteBacklink = async (id) => {
  isDeleting.value = true
  try {
    await axios.put(settings.deleteBacklink, { id })
    backlinks.value = backlinks.value.filter(backlink => backlink.id !== id)
    responseHandlerService.handleSuccess(t('settings.components.configuration.components.backlinksModal.deleteSuccess'))
  } catch (error) {
    responseHandlerService.handleError(error)
    await fetchBacklinks()
  } finally {
    isDeleting.value = false
    isDeletingId.value = null
    if (deleteTimeout.value) {
      clearTimeout(deleteTimeout.value)
    }
  }
}

const saveEdit = async () => {
  if (!validateForm()) return

  isSubmitting.value = true
  try {
    const endpoint = editingBacklink.value.id ? settings.editBacklink : settings.addBacklink
    const payload = {
      ...editingBacklink.value,
      assetId: assetStore.assetId,
    }

    const requestMethod = editingBacklink.value.id ? 'put' : 'post'

    await axios[requestMethod](endpoint, payload)
    responseHandlerService.handleSuccess(
        editingBacklink.value.id
            ? t('settings.components.configuration.components.backlinksModal.updateSuccess')
            : t('settings.components.configuration.components.backlinksModal.addSuccess'),
    )
    isEditing.value = false
    await fetchBacklinks()
  } catch (error) {
    responseHandlerService.handleError(error)
  } finally {
    isSubmitting.value = false
  }
}

const cancelEdit = () => {
  isEditing.value = false
  editingBacklink.value = {
    id: null,
    link: '',
    description: '',
    source: '',
  }
  errors.value = {
    link: '',
    description: '',
    source: '',
  }
}

const handleModalOpened = () => {
  fetchBacklinks()
}

const handleModalClosed = () => {
  isEditing.value = false
  editingBacklink.value = {
    id: null,
    link: '',
    description: '',
    source: '',
  }
  errors.value = {
    link: '',
    description: '',
    source: '',
  }
  isDeletingId.value = null
  if (deleteTimeout.value) {
    clearTimeout(deleteTimeout.value)
  }
}

const closeModal = () => {
  emit('update:show', false)
}

// Watch for changes to validate in real-time
watch(() => editingBacklink.value, () => {
  if (isEditing.value) {
    validateForm()
  }
}, { deep: true })
</script>