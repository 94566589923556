<template>
  <div class="p-6 w-full">
    <template v-if="isLoading">
      <div class="fixed inset-0 bg-white backdrop-blur-sm z-50">
        <div class="flex items-center justify-center min-h-[90vh]">
          <VLoading loadingText="Varlık kontrol ediliyor" :showLoadingText="true" containerClass="flex flex-col justify-center items-center" loadingTextClass="mt-4 font-medium text-gray-700"/>
        </div>
      </div>
    </template>

    <stepController v-else :steps="localizedSteps" persist-key="asset-creation" :loading="assetStore.assetData.loading" @step-change="handleStepChange" @complete="handleComplete"/>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useStepsStore } from '@/stores/steps'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { markRaw } from 'vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import stepController from '@/components/features/step-controller/index.vue'
import VLoading from '@/components/loading/VLoading.vue'

import informationStep from './components/information/index.vue'
import analyseStep from './components/analyse/index.vue'
import introductionStep from './components/introduction/index.vue'
import sourcesStep from './components/source/index.vue'
import subscriptionStep from './components/subscriptions/index.vue'
import paymentStep from './components/payment/index.vue'
import BillingForm from './components/payment/components/BillingForm.vue'
import PaymentMethods from './components/payment/components/PaymentMethods.vue'
import PackageSummary from './components/payment/components/PackageSummary.vue'

const assetStore = useAssetStore()
const stepsStore = useStepsStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const servicesTypes = {
  service: 1,
  crawler: 2,
}

const SETUP_STEPS = {
  INITIAL: 'initial',
  PAYMENT_CONFIRMED: 'payment-confirmed',
}

const isLoading = ref(false)

const currentServiceType = computed(() => assetStore.assetData.serviceType)

const getStepsByServiceType = (serviceType) => {
  let steps = [
    {
      component: markRaw(introductionStep),
      ref: 'introduction',
      label: 'assets.components.create.steps.introduction',
    },
    {
      component: markRaw(subscriptionStep),
      ref: 'subscription',
      label: 'assets.components.create.steps.subscription',
    },
  ]

  if (serviceType === servicesTypes.service) {
    steps.push(
        {
          component: markRaw(sourcesStep),
          ref: 'sources',
          label: 'assets.components.create.steps.sources',
          buttonText: 'assets.components.create.steps.addSources',
          buttonLoadingText: 'assets.components.create.steps.addingSources',
        },
        {
          component: markRaw(informationStep),
          ref: 'information',
          label: 'assets.components.create.steps.information',
        },
    )
  }

  steps.push(
      {
        component: markRaw(paymentStep),
        ref: 'payment',
        label: 'assets.components.create.steps.payment',
        nestedSteps: [
          {
            component: markRaw(BillingForm),
            ref: 'billing',
            label: 'assets.components.create.steps.nestedSteps.billing',
          },
          {
            component: markRaw(PaymentMethods),
            ref: 'method',
            label: 'assets.components.create.steps.nestedSteps.method',
          },
          {
            component: markRaw(PackageSummary),
            ref: 'confirmation',
            label: 'assets.components.create.steps.nestedSteps.confirmation',
          },
        ],
      },
      {
        component: markRaw(analyseStep),
        ref: 'analyse',
        label: 'assets.components.create.steps.analyse',
      },
  )

  return steps
}

const steps = computed(() => getStepsByServiceType(currentServiceType.value))

const localizedSteps = computed(() => {
  return steps.value.map((step) => {
    const localizedStep = {
      ...step,
      label: t(step.label),
      buttonText: step.buttonText ? t(step.buttonText) : undefined,
      buttonLoadingText: step.buttonLoadingText ? t(step.buttonLoadingText) : undefined,
    }

    if (step.nestedSteps) {
      localizedStep.nestedSteps = step.nestedSteps.map((nestedStep) => ({
        ...nestedStep,
        label: t(nestedStep.label),
      }))
    }

    return localizedStep
  })
})

const handleStepChange = async (stepIndex) => {
  await stepsStore.setCurrentStep({ stepIndex })
}

const handleComplete = async () => {
  await router.push('/assets')
}

// Setup verilerini yükleme fonksiyonu
async function loadAssetSetupData(id) {
  if (isLoading.value) return

  isLoading.value = true
  try {
    const res = await axios.get(assets.get, { params: { assetId: id } })

    // Setup verilerini işle
    await handleSetupData(res.data.data)
  } catch (error) {
    console.error('Failed to load asset setup data:', error)
    await assetStore.exitSetupFlow()
    await router.push({ name: 'assets' })
  } finally {
    isLoading.value = false
  }
}

const getStepIndexByRef = (steps, ref) => {
  return steps.findIndex(step => step.ref === ref)
}

// Setup verilerini işleme fonksiyonu
async function handleSetupData(data) {
  const { id, name, website, languageId, serviceType, setupStep } = data

  // Asset tipini set et
  await assetStore.setAssetServiceType(serviceType)

  // Introduction bilgilerini set et
  await assetStore.setIntroductionList({
    name: name || '',
    website: website || '',
    languageId: languageId || 1,
    searchEngineLocations: {
      google: [],
      bing: [],
      yandex: [],
    },
    communicationId: '',
  })

  // Setup step'e göre yönlendirme yap
  switch (setupStep) {
    case SETUP_STEPS.INITIAL:
      // Önce validasyonu ayarla
      await stepsStore.setStepValidation({
        stepKey: 'asset-creation_introduction',
        isValid: true,
        errors: {},
      })

      // Önceki adımı tamamlandı olarak işaretle
      await stepsStore.updateStepCompletion({
        stepIndex: 0, // introduction tamamlandı
      })

      // Subscription adımına yönlendir
      await stepsStore.setCurrentStep({
        stepIndex: 1,
        nestedStepIndex: 0,
      })
      break

    case SETUP_STEPS.PAYMENT_CONFIRMED:
      const paymentStepIndex = getStepIndexByRef(steps.value, 'payment')
      const paymentStep = steps.value[paymentStepIndex]
      const confirmationStepIndex = paymentStep.nestedSteps.findIndex(
          step => step.ref === 'confirmation',
      )

      // Payment öncesi adımları tamamlandı göster
      for (let i = 0; i <= paymentStepIndex; i++) {
        await stepsStore.setStepValidation({
          stepKey: `asset-creation_${steps.value[i].ref}`,
          isValid: true,
          errors: {},
        })
      }

      // Önceki adımları tamamlandı olarak işaretle
      await stepsStore.updateStepCompletion({
        stepIndex: paymentStepIndex - 1,
      })

      // Query'e success ekle
      await router.replace({
        query: { ...route.query, success: 'true' },
      })

      // Payment confirmation step'ine yönlendir
      await stepsStore.setCurrentStep({
        stepIndex: paymentStepIndex,
        nestedStepIndex: confirmationStepIndex,
      })
      break
  }

  await nextTick()
}

onMounted(async () => {
  if (route.query.success) return

  if (assetStore.isSetupFlow && assetStore.setupAssetId) {
    await stepsStore.resetStepManagement()
    await loadAssetSetupData(assetStore.setupAssetId)
  }
})

// Service type değişimini ayrı bir watch'da tutabiliriz
watch(
    () => currentServiceType.value,
    async (serviceType) => {
      if (serviceType) await stepsStore.resetStepManagement()
    },
)

</script>
