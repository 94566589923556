import { defineStore } from 'pinia'
import { encryptedStorage } from '@/utils/encryption'

const defaultAssetData = {
  assetId: null,
  serviceType: '',
  selectedPackage: {
    id: null,
    name: null,
    price: null,
    originalPrice: null,
    plan: null,
    planId: null,
    features: [],
    maxWebsites: null,
    maxLobs: null,
    discountRate: null,
    isTrialEligible: false,
    trialDurationDays: null,
  },
  billingInformation: {
    paymentInformationId: null,
    name: '',
    surname: '',
    email: '',
    phone: '',
    identityNumber: '',
    address: '',
    city: '',
    country: '',
  },
  paymentDetails: {
    status: null,
    method: null,
    token: null,
    paymentId: null,
    errorMessage: null,
    completedAt: null,
    formContent: null,
  },
  subscriptionDetails: {
    startDate: null,
    endDate: null,
    trialEndsAt: null,
    currentPeriodStart: null,
    currentPeriodEnd: null,
    nextBillingDate: null,
    isActive: false,
    autoRenew: true,
    status: null,
  },
  introductionList: {
    name: '',
    website: '',
    languageId: 1,
    countryId: null,
    timezoneId: null,
    searchEngineLocations: {
      google: [],
      bing: [],
      yandex: [],
    },
    communicationId: '',
  },
  informationList: {
    aboutUs: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    keywords: [],
    logo: '',
    linkedinUrl: '',
    phone: '',
    wphone: '',
  },
  authList: {
    username: '',
    password: '',
    authUrl: '',
    isAuth: false,
  },
  sources: [{ sourceCategoryId: '', getUrl: '', addUrl: '', editUrl: '' }],
  loading: false,
  inputLoading: false,
  isDataReceived: false,
}

export const useAssetStore = defineStore('asset', {
  state: () => ({
    assetId: null,
    isSetupFlow: false,
    isCheckPhase: false,
    setupAssetId: null,
    automaticPublishing: false,
    assetName: '',
    assetServiceType: '',
    assetLanguage: '',
    customerLanguageId: null,
    assets: [],
    assetData: defaultAssetData,
  }),

  actions: {
    // State setters
    setAssetId(id) {
      this.$patch((state) => {
        state.assetId = id
        state.assetData.assetId = id
        // Setup flow'da ise setupAssetId'yi de güncelle
        if (state.isSetupFlow) {
          state.setupAssetId = id
        }
      })
    },

    async startCheckPhase(assetId) {
      this.isCheckPhase = true
      this.setupAssetId = assetId
      this.assetId = assetId
    },

    exitCheckPhase() {
      this.isCheckPhase = false
      this.setupAssetId = null
      this.resetAssetData()
    },

    async startSetupFlow(assetId) {
      // Tüm asset ID referanslarını güncelle
      this.isSetupFlow = true
      this.setupAssetId = assetId
      this.assetId = assetId  // Ana state'deki assetId
      this.assetData.assetId = assetId // assetData içindeki assetId
    },

    exitSetupFlow() {
      this.isSetupFlow = false
      this.setupAssetId = null
      // resetAssetData zaten tüm assetId'leri null yapıyor
      this.resetAssetData()
    },

    setAutomaticPublishing(value) {
      this.automaticPublishing = value
    },

    setAssetName(name) {
      this.assetName = name
    },

    setAssetServiceType(type) {
      this.$patch((state) => {
        state.assetServiceType = type
        state.assetData.serviceType = type
      })
    },

    setAssetCustomerLanguageId(val) {
      this.$patch((state) => {
        state.customerLanguageId = val
        state.assetData.customerLanguageId = val
      })
    },

    setAssetLanguage(language) {
      this.assetLanguage = language
    },

    setAssets(assets) {
      this.assets = assets
    },

    // Billing information setters
    setBillingInformation(billingInfo) {
      this.$patch((state) => {
        Object.assign(state.assetData.billingInformation, billingInfo)
      })
    },

    setPaymentInformationId(id) {
      this.$patch((state) => {
        state.assetData.billingInformation.paymentInformationId = id
      })
    },

    // Asset data setters
    setIsDataReceived(isDataReceived) {
      this.assetData.isDataReceived = isDataReceived
    },

    setIntroductionList(introductionList) {
      if (introductionList) {
        this.assetData.introductionList = introductionList
      } else {
        this.assetData.introductionList = {
          name: '',
          website: '',
          languageId: 1,
          searchEngineLocations: {
            google: [],
            bing: [],
            yandex: [],
          },
          communicationId: '',
        }
      }
    },

    setInformationList(informationList) {
      this.assetData.informationList = informationList
    },

    setAuthList(authList) {
      this.assetData.authList = authList
    },

    setSources(sources) {
      this.assetData.sources = sources
    },

    setAssetLoading(loading) {
      this.assetData.loading = loading
    },

    setAssetInputLoading(inputLoading) {
      this.assetData.inputLoading = inputLoading
    },

    setSelectedPackage(packageData) {
      this.$patch((state) => {
        Object.assign(state.assetData.selectedPackage, packageData)
      })
    },

    setSubscriptionDetails(details) {
      this.$patch((state) => {
        Object.assign(state.assetData.subscriptionDetails, details)
      })
    },

    setPaymentDetails(details) {
      this.$patch((state) => {
        Object.assign(state.assetData.paymentDetails, details)
      })
    },

    // Reset functions
    clearBillingInformation() {
      this.$patch((state) => {
        state.assetData.billingInformation = {
          paymentInformationId: null,
          name: '',
          surname: '',
          email: '',
          phone: '',
          identityNumber: '',
          address: '',
          city: '',
          country: '',
        }
      })
    },

    clearPackageSelection() {
      this.$patch((state) => {
        state.assetData.selectedPackage = {
          id: null,
          name: null,
          price: null,
          originalPrice: null,
          plan: null,
          planId: null,
          features: [],
          maxWebsites: null,
          maxLobs: null,
          discountRate: null,
          isTrialEligible: false,
          trialDurationDays: null,
        }
        state.assetData.subscriptionDetails = {
          startDate: null,
          endDate: null,
          trialEndsAt: null,
          currentPeriodStart: null,
          currentPeriodEnd: null,
          nextBillingDate: null,
          isActive: false,
          autoRenew: true,
          status: null,
        }
      })
    },

    resetPayment() {
      this.$patch((state) => {
        state.assetData.paymentDetails = {
          status: null,
          method: null,
          token: null,
          paymentId: null,
          errorMessage: null,
          completedAt: null,
          formContent: null,
        }
      })
    },

    resetAssetData() {
      this.$patch((state) => {
        state.assetId = null
        state.setupAssetId = null
        state.isSetupFlow = false
        state.isCheckPhase = false
        state.automaticPublishing = false
        state.assetName = ''
        state.assetServiceType = ''
        state.assetLanguage = ''
        state.customerLanguageId = null
        state.assets = []
        state.assetData = {
          assetId: null,
          serviceType: '',
          selectedPackage: {
            id: null,
            name: null,
            price: null,
            originalPrice: null,
            plan: null,
            planId: null,
            features: [],
            maxWebsites: null,
            maxLobs: null,
            discountRate: null,
            isTrialEligible: false,
            trialDurationDays: null,
          },
          billingInformation: {
            paymentInformationId: null,
            name: '',
            surname: '',
            email: '',
            phone: '',
            identityNumber: '',
            address: '',
            city: '',
            country: '',
          },
          paymentDetails: {
            status: null,
            method: null,
            token: null,
            paymentId: null,
            errorMessage: null,
            completedAt: null,
            formContent: null,
          },
          subscriptionDetails: {
            startDate: null,
            endDate: null,
            trialEndsAt: null,
            currentPeriodStart: null,
            currentPeriodEnd: null,
            nextBillingDate: null,
            isActive: false,
            autoRenew: true,
            status: null,
          },
          introductionList: {
            name: '',
            website: '',
            languageId: 1,
            countryId: null,
            timezoneId: null,
            searchEngineLocations: {
              google: [],
              bing: [],
              yandex: [],
            },
            communicationId: '',
          },
          informationList: {
            aboutUs: '',
            facebookUrl: '',
            instagramUrl: '',
            twitterUrl: '',
            keywords: [],
            logo: '',
            linkedinUrl: '',
            phone: '',
            wphone: '',
          },
          authList: {
            username: '',
            password: '',
            authUrl: '',
            isAuth: false,
          },
          sources: [{
            sourceCategoryId: '',
            getUrl: '',
            addUrl: '',
            editUrl: '',
          }],
          loading: false,
          inputLoading: false,
          isDataReceived: false,
        }
      })
    },

    clearEverything() {
      encryptedStorage.removeItem('asset-store')
      this.resetAssetData()
    },
  },

  getters: {
    // Asset getters
    getServiceType: (state) => state.assetServiceType,
    isAssetIdsConsistent: (state) => {
      if (state.isSetupFlow) return state.assetId === state.setupAssetId && state.assetId === state.assetData.assetId
      return true
    },

    // Package getters
    getSelectedPackage: (state) => state.assetData.selectedPackage,
    getSubscriptionDetails: (state) => state.assetData.subscriptionDetails,
    hasActivePackage: (state) => !!state.assetData.selectedPackage.id,
    getPackageFeatures: (state) => state.assetData.selectedPackage.features || [],
    getMaxWebsites: (state) => state.assetData.selectedPackage.maxWebsites,
    getMaxLobs: (state) => state.assetData.selectedPackage.maxLobs,

    // Payment getters
    getPaymentDetails: (state) => state.assetData.paymentDetails,
    isPaymentCompleted: (state) => state.assetData.paymentDetails.status === 'success',
    isPaymentPending: (state) => state.assetData.paymentDetails.status === 'pending',
    getPaymentMethod: (state) => state.assetData.paymentDetails.method,
    getPaymentFormContent: (state) => state.assetData.paymentDetails.formContent,

    // Billing getters
    getBillingInformation: (state) => state.assetData.billingInformation,
    getPaymentInformationId: (state) => state.assetData.billingInformation.paymentInformationId,
  },

  persist: {
    key: 'asset-store',
    storage: encryptedStorage,
    paths: ['assetData', 'assetId', 'assetServiceType'],
  },
})