<template>
  <div class="relative w-full" ref="componentRef">
    <label v-if="label" :class="['block font-semibold text-sm mb-1', labelClass]"> {{ label }} <span v-if="required" class="text-red-500">*</span> </label>
    <p v-if="description" :class="['mb-2 text-xs text-gray-500', descriptionClass]">
      {{ description }} </p>

    <CustomDropdown :options="timezoneOptions" v-model="selectedTimezoneId" :label="placeholder" :show-search="true" :loading="loading" :disabled="disabled" :error="error" size="sm" @update:modelValue="handleTimezoneChange" @search="handleSearch"/>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import CustomDropdown from '@/components/base/inputs/VDropdown.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const props = defineProps({
  modelValue: [String, Number],
  countryId: [String, Number],
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  required: { type: Boolean, default: false },
  labelClass: { type: String, default: 'text-secondary' },
  descriptionClass: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  showSearch: { type: Boolean, default: true },
  error: { type: String, default: '' },
})

const emit = defineEmits(['update:modelValue', 'timezoneSelected'])

const componentRef = ref(null)
const timezones = ref([])
const loading = ref(false)
const selectedTimezoneId = ref(props.modelValue)
const searchQuery = ref('')

const timezoneOptions = computed(() => {
  if (!timezones.value?.length) return []

  return timezones.value
      .filter(timezone => {
        if (!searchQuery.value) return true
        return timezone.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            timezone.utc_offset_iso.toLowerCase().includes(searchQuery.value.toLowerCase())
      })
      .map(timezone => ({
        value: timezone.id,
        label: `${timezone.name} (${timezone.utc_offset_iso})`,
      }))
})

const handleTimezoneChange = (timezoneId) => {
  selectedTimezoneId.value = timezoneId
  emit('timezoneSelected', timezoneId)
  emit('update:modelValue', timezoneId)
}

const handleSearch = (query) => {
  searchQuery.value = query || ''
}

const fetchTimezones = async (countryId) => {
  try {
    loading.value = true
    const response = await axios.get(assets.timezones, {
      params: countryId ? { countryId } : {},
    })
    timezones.value = response.data.data || []

    if (timezones.value.length > 0 && countryId) {
      // Country seçiliyse ve timezone listesi varsa ilk değeri seç
      handleTimezoneChange(timezones.value[0].id)
    }
  } catch (error) {
    console.error('Error fetching timezones:', error)
    timezones.value = []
  } finally {
    loading.value = false
  }
}

// CountryId değişimini izle
watch(() => props.countryId, (newCountryId) => {
  if (newCountryId) {
    // Country değiştiğinde seçili timezone'ı temizle
    selectedTimezoneId.value = null
    fetchTimezones(newCountryId)
  }
}, { immediate: true })

// ModelValue değişimini izle
watch(() => props.modelValue, (newValue) => {
  if (newValue !== selectedTimezoneId.value) selectedTimezoneId.value = newValue
}, { immediate: true })

onMounted(() => {
  if (!props.countryId) fetchTimezones(props.countryId)
})
</script>