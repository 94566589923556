<template>
  <div class="min-h-[80vh] w-full relative component-layout">
    <!-- Loading State -->
    <div v-if="loading" class="loading-overlay h-[80vh]">
      <Loading/>
    </div>

    <!-- Payment Status Overlay -->
    <div v-if="isCheckingPayment || showPaymentStatus" class="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div class="bg-white rounded-lg shadow-xl p-6 max-w-md w-full mx-4 relative">
        <button @click="showPaymentStatus = false" class="absolute top-4 right-4 text-gray-400 hover:text-gray-600">
          <span class="material-symbols-outlined">close</span>
        </button>

        <div v-if="isCheckingPayment" class="flex flex-col items-center">
          <Loading class="w-12 h-12 mb-4"/>
          <p class="text-gray-600">{{ t('settings.components.payment.status.checking') }}</p>
        </div>

        <div v-else-if="paymentStatus" class="text-center">
          <!-- Success State -->
          <div v-if="paymentStatus.success" class="flex flex-col items-center">
            <div class="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center mb-4">
              <span class="material-symbols-outlined text-green-500">check_circle</span>
            </div>
            <h3 class="text-lg font-medium text-gray-900 mb-2">{{ t('settings.components.payment.status.success') }}</h3>
            <p class="text-gray-600 mb-2">{{ paymentStatus.message }}</p>
            <div class="text-sm text-gray-500 space-y-1">
              <p>{{ t('settings.components.payment.status.details') }}: {{ formatPrice(paymentStatus.amount) }}</p>
              <p>{{ formatDate(paymentStatus.date) }}</p>
            </div>
            <button @click="showPaymentStatus = false" class="mt-6 px-4 py-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200">
              {{ t('settings.components.payment.status.close') }}
            </button>
          </div>

          <!-- Error State -->
          <div v-else class="flex flex-col items-center">
            <div class="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center mb-4">
              <span class="material-symbols-outlined text-red-500">error</span>
            </div>
            <h3 class="text-lg font-medium text-gray-900 mb-2">{{ t('settings.components.payment.status.failed') }}</h3>
            <p class="text-gray-600 mb-4">{{ paymentStatus.message }}</p>
            <button @click="showPaymentStatus = false" class="mt-4 px-4 py-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200">
              {{ t('settings.components.payment.status.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-7xl mx-auto py-8 px-4">
      <!-- Package Selection Active -->
      <template v-if="isPackageSelectionActive">
        <div class="bg-white rounded-lg shadow-sm border p-6">
          <!-- Steps Progress -->
          <div class="flex flex-col items-center mb-8">
            <div class="flex items-center w-full max-w-md mx-auto mb-4">
              <div class="flex items-center gap-3 flex-1">
                <div class="w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center text-sm">1</div>
                <span class="text-sm font-medium">{{ t('settings.components.payment.packageSelection') }}</span>
              </div>
              <div class="w-24 h-px bg-gray-200 mx-4"></div>
              <div class="flex items-center gap-3 flex-1">
                <div class="w-8 h-8 rounded-full flex items-center justify-center text-sm" :class="currentStep === 2 ? 'bg-primary text-white' : 'bg-gray-200 text-gray-600'">2</div>
                <span class="text-sm font-medium">{{ t('settings.components.payment.billingInfo') }}</span>
              </div>
            </div>

            <!-- Cancel Button -->
            <div class="flex justify-end w-full">
              <custom-button @click="cancelSelection" :button-text="t('settings.components.payment.cancel')" color="secondary" variation="outline" :show-icon="true" size="sm" icon="cancel"/>
            </div>
          </div>

          <!-- Package Selection Step -->
          <div v-if="currentStep === 1">
            <!-- Trial Banner -->
            <div v-if="isTrialAvailable && !isMaxPackage" class="mb-8 bg-gradient-to-r from-primary/10 to-secondary/10 rounded-xl p-6 border-2" :class="{'border-primary': selectedPackage?.id === 1}">
              <div class="flex items-start space-x-4">
                <span class="material-symbols-outlined text-primary text-3xl mt-1">star</span>
                <div>
                  <h3 class="text-lg font-bold text-gray-900 mb-2">{{ t('settings.components.payment.freeTrial') }}</h3>
                  <p class="text-sm text-gray-600">{{ t('settings.components.payment.freeTrialDesc') }}</p>
                  <CustomButton class="mt-4" :button-text="selectedPackage?.id !== 1 ? t('settings.components.payment.startTrial') : t('settings.components.payment.selected')" color="primary" size="sm" :is-disabled="selectedPackage?.id === 1 || !canSelectPackage({id: 1})" :additional-button-class="selectedPackage?.id === 1 ? 'ring-2 ring-primary/20' : ''" @click="handlePackageSelect({id: 1})"/>
                </div>
              </div>
            </div>

            <!-- Package Grid -->
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div v-for="pkg in filteredPackages" :key="pkg.id" class="relative border-2 rounded-xl transition-all duration-300" :class="getPackageClasses(pkg)" @click="handlePackageSelect(pkg)">
                <div class="p-6" :class="{'opacity-50': !canSelectPackage(pkg)}">
                  <div class="flex justify-between items-start mb-4">
                    <div>
                      <h3 class="text-lg font-bold text-gray-900">{{ pkg.name }}</h3>
                      <p class="text-sm text-gray-500 mt-1">{{ pkg.description }}</p>
                    </div>
                    <div v-if="pkg.isPopular" class="bg-primary text-white px-3 py-1 rounded-full text-sm font-medium">
                      {{ t('settings.components.payment.popular') }}
                    </div>
                    <div v-else-if="pkg.isCurrent" class="px-3 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
                      {{ t('settings.components.payment.currentPackage') }}
                    </div>
                  </div>

                  <!-- Pricing Plans -->
                  <div class="space-y-4">
                    <!-- Monthly Plan -->
                    <div class="border rounded-lg p-4 transition-colors relative" :class="getPlanClasses('monthly', pkg)" @click.stop="handlePlanSelect('monthly', pkg)">
                      <div class="flex justify-between items-center mb-2">
                        <span class="font-medium text-sm">{{ t('settings.components.payment.planTypes.monthly') }}</span>
                        <div class="text-right">
                          <span class="text-xl font-bold">{{ formatPrice(pkg.monthlyPrice) }}</span> <span class="text-sm text-gray-500">/{{ t('settings.components.payment.period.month') }}</span>
                        </div>
                      </div>
                      <div class="space-y-2">
                        <div class="text-sm text-gray-500">
                          <span class="block font-medium">{{ pkg.monthlyFeatures }}</span>
                          <div class="mt-2 flex items-center gap-2">
                            <span class="material-symbols-outlined text-sm text-primary">info</span> <span class="text-xs text-gray-400">{{
                              t('settings.components.payment.additionalDiscount')
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div v-if="!canSelectPlan(pkg, 'monthly')" class="absolute inset-0 bg-gray-100/50 backdrop-blur-sm cursor-not-allowed rounded-lg flex items-center justify-center">
                        <span class="text-sm text-gray-500 font-semibold">{{ t('settings.components.payment.planNotAvailable') }}</span>
                      </div>
                    </div>

                    <!-- Annual Plan -->
                    <div class="border rounded-lg p-4 transition-colors relative" :class="getPlanClasses('annual', pkg)" @click.stop="handlePlanSelect('annual', pkg)">
                      <div class="flex justify-between items-center mb-2">
                        <div>
                          <span class="font-medium text-sm">{{ t('settings.components.payment.planTypes.annual') }}</span> <span class="ml-2 bg-green-100 text-green-800 px-2 py-0.5 rounded text-xs">33% {{
                            t('settings.components.payment.discount')
                          }}</span>
                        </div>
                        <div class="text-right">
                          <span class="text-xl font-bold">{{ formatPrice(pkg.annualPrice) }}</span> <span class="text-sm text-gray-500">/{{ t('settings.components.payment.period.year') }}</span>
                        </div>
                      </div>
                      <div class="space-y-2">
                        <div class="text-sm text-gray-500">
                          <span class="block font-medium">{{ pkg.annualFeatures }}</span>
                          <div class="mt-2 flex items-center gap-2">
                            <span class="material-symbols-outlined text-sm text-green-500">savings</span> <span class="text-xs text-gray-400">{{
                              t('settings.components.payment.annualSavings')
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div v-if="!canSelectPlan(pkg, 'annual')" class="absolute inset-0 bg-gray-100/50 backdrop-blur-sm cursor-not-allowed rounded-lg flex items-center justify-center">
                        <span class="text-sm text-gray-500 font-semibold">{{ t('settings.components.payment.planNotAvailable') }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Features List -->
                  <div class="mt-6 space-y-3">
                    <div v-for="(feature, index) in pkg.features" :key="index" class="flex items-center">
                      <span class="material-symbols-outlined text-primary mr-2">check_circle</span> <span class="text-sm text-gray-600">{{ feature }}</span>
                    </div>
                  </div>
                </div>

                <div v-if="!canSelectPackage(pkg)" class="absolute inset-0 bg-gray-100/30 backdrop-blur-[1px] rounded-xl flex items-center justify-center">
                  <span class="text-sm text-gray-500 bg-white px-4 py-2 rounded-lg shadow-sm">
                    {{ t('settings.components.payment.packageNotAvailable') }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Continue Button -->
            <div class="flex justify-end mt-8">
              <VButton :button-text="t('settings.components.payment.continue')" :is-disabled="!canProceedToNextStep" @click="proceedToNextStep" color="primary"/>
            </div>
          </div>

          <!-- Payment Info Step -->
          <div v-else>
            <PaymentInformation v-model="selectedPaymentInfoId" class="mb-6"/>
            <div class="flex items-center justify-between mt-8 pt-6 border-t">
              <custom-button @click="currentStep = 1" :button-text="t('settings.components.payment.back')" color="secondary" variation="outline" :show-icon="true" size="sm" icon="arrow_back"/>
              <VButton :button-text="upgradeButtonText" :is-loading="upgrading" :is-disabled="!canConfirmUpgrade" @click="handleUpgrade" color="primary"/>
            </div>
          </div>
        </div>
      </template>

      <!-- Unlimited Account Banner -->
      <template v-else-if="isUnlimitedAccount">
        <div class="w-full bg-gradient-to-br from-blue-50 via-indigo-50 to-violet-50 rounded-2xl p-8 border border-blue-200/50 shadow-sm hover:shadow-md transition-all duration-300">
          <div class="flex flex-col lg:flex-row items-start gap-8">
            <!-- Left Section: Icon & Title -->
            <div class="flex-shrink-0 text-center lg:text-left">
              <div class="w-16 h-16 lg:w-20 lg:h-20 rounded-2xl bg-gradient-to-r from-blue-500 to-indigo-600 flex items-center justify-center mb-4 mx-auto lg:mx-0 shadow-lg">
                <span class="material-symbols-outlined text-white text-3xl lg:text-4xl">workspace_premium</span>
              </div>
              <span class="px-4 py-1.5 rounded-full bg-blue-100 text-blue-700 text-sm font-medium inline-block">
                Enterprise
              </span>
            </div>

            <!-- Right Section: Content -->
            <div class="flex-1 space-y-6">
              <!-- Title & Description -->
              <div>
                <h3 class="text-2xl font-bold text-gray-900 mb-3">
                  {{ t('settings.components.payment.enterpriseAccount') }} </h3>
                <p class="text-gray-700 text-base leading-relaxed">
                  {{ t('settings.components.payment.customizedSolution') }} </p>
              </div>

              <!-- Divider -->
              <div class="border-t border-blue-100"></div>

              <!-- Enterprise Features Grid -->
              <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
                <!-- Priority Support -->
                <div class="bg-white/60 rounded-xl p-4 backdrop-blur-sm border border-blue-100">
                  <div class="flex items-start gap-3">
                    <div class="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center">
                      <span class="material-symbols-outlined text-blue-600">support_agent</span>
                    </div>
                    <div>
                      <h4 class="font-semibold text-gray-900 mb-1">
                        {{ t('settings.components.payment.enterpriseFeatures.support') }} </h4>
                      <p class="text-sm text-gray-600">{{ t('settings.components.payment.enterpriseFeatures.supportDesc') }}</p>
                    </div>
                  </div>
                </div>

                <!-- Custom Solutions -->
                <div class="bg-white/60 rounded-xl p-4 backdrop-blur-sm border border-blue-100">
                  <div class="flex items-start gap-3">
                    <div class="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center">
                      <span class="material-symbols-outlined text-blue-600">verified</span>
                    </div>
                    <div>
                      <h4 class="font-semibold text-gray-900 mb-1">
                        {{ t('settings.components.payment.enterpriseFeatures.solutions') }} </h4>
                      <p class="text-sm text-gray-600">{{ t('settings.components.payment.enterpriseFeatures.solutionsDesc') }}</p>
                    </div>
                  </div>
                </div>

                <!-- Unlimited Capacity -->
                <div class="bg-white/60 rounded-xl p-4 backdrop-blur-sm border border-blue-100">
                  <div class="flex items-start gap-3">
                    <div class="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center">
                      <span class="material-symbols-outlined text-blue-600">trending_up</span>
                    </div>
                    <div>
                      <h4 class="font-semibold text-gray-900 mb-1">
                        {{ t('settings.components.payment.enterpriseFeatures.unlimited') }} </h4>
                      <p class="text-sm text-gray-600">{{ t('settings.components.payment.enterpriseFeatures.unlimitedDesc') }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Current Package View -->
      <template v-else>
        <div class="bg-white rounded-lg shadow-sm border p-6">
          <div class="mb-10 w-full">
            <div class="flex items-center justify-between">
              <div class="flex flex-col">
                <!-- Package Header -->
                <div class="flex items-center gap-2 mb-2">
                  <h2 class="text-xl font-medium text-gray-900">
                    {{ currentPackage.name }} <span v-if="featureStore.getPackageInfo(currentPackage).showPlanType && currentPackage.planType" class="text-gray-500 ml-2">
                    ({{ currentPackage.planType === 'ANNUAL' ? t('settings.components.payment.planTypes.annual') : t('settings.components.payment.planTypes.monthly') }})
                  </span>
                  </h2>
                  <span v-if="currentPackage.status === 'ACTIVE'" class="px-2 py-0.5 bg-green-100 text-green-800 text-xs font-medium rounded">
                      {{ t('settings.components.payment.status.active') }}
                  </span>
                </div>

                <!-- Package Info -->
                <div class="flex items-center gap-4 text-sm text-gray-500">
                  <div v-if="featureStore.getPackageInfo(currentPackage).showRenewalDate">
                    {{ t('settings.components.payment.renewalDate') }}: {{ formatDate(currentPackage.renewalDate) || formatDate(currentPackage.endDate) }}
                  </div>
                  <div v-if="featureStore.getPackageInfo(currentPackage).showPlanType" class="text-primary font-medium">
                    {{ formatPrice(currentPackage.price) }}/{{
                      currentPackage.planType === 'ANNUAL' ?
                          t('settings.components.payment.period.year') :
                          t('settings.components.payment.period.month')
                    }}
                  </div>
                </div>
              </div>

                            <button v-if="showUpgradeButton" @click="startPackageSelection" class="inline-flex items-center gap-2 bg-primary text-white px-4 py-2 rounded-lg text-sm hover:bg-primary/90 transition-colors">
                            <span class="material-symbols-outlined !text-[18px]">upgrade</span> {{ upgradeButtonText }}
                           </button>
            </div>
          </div>

          <!-- Usage Statistics - Hide for Unlimited accounts -->
          <template v-if="!isUnlimitedAccount">
            <div class="mb-6 pb-2 border-b">
              <div class="flex items-center justify-between">
                <h1 class="text-lg font-medium text-gray-900">{{ t('settings.components.payment.usage.title') }}</h1>
                <div class="flex items-center gap-2">
                  <span class="text-sm text-gray-500">{{ t('settings.components.payment.usage.total') }}:</span> <span class="px-2 py-0.5 bg-primary/10 text-primary text-sm font-medium rounded-full">
                    {{ totalUsedLobs }}/{{ totalLobs }} {{ t('settings.components.payment.usage.lobs') }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Usage List -->
            <div class="space-y-6">
              <div v-for="module in usageData" :key="module.id" class="group hover:bg-gray-50 p-3 -mx-3 rounded-lg transition-colors duration-200">
                <div class="flex justify-between items-center mb-2">
                  <div class="flex items-start gap-3">
                    <span class="material-symbols-outlined shrink-0 mt-0.5" :class="featureStore.getFeatureIconClass(module)">
                      {{ featureStore.getFeatureIcon(module) }}
                    </span>
                    <div>
                      <h3 class="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                        {{ t(`${featureStore.getFeatureTranslationKey(module)}.name`) }} </h3>
                      <p class="text-xs text-gray-500 mt-0.5">
                        {{ formatUsage(module.usedAmount, module.totalAmount) }} {{ t('settings.components.payment.usage.lobs') }} </p>
                    </div>
                  </div>
                  <span :class="getPercentageColor(module.usedAmount, module.totalAmount)" class="text-xs font-medium">
                    {{ formatPercentage(module.usedAmount, module.totalAmount) }}
                  </span>
                </div>

                <!-- Progress Bar -->
                <div class="h-2 bg-gray-100 rounded-full overflow-hidden">
                  <div class="h-full transition-all duration-500 ease-in-out transform origin-left" :class="getProgressBarColor(module.usedAmount, module.totalAmount)" :style="`width: ${calculatePercentage(module.usedAmount, module.totalAmount)}%`"></div>
                </div>
              </div>
            </div>

            <!-- Empty State for Usage Data -->
            <div v-if="usageData.length === 0" class="text-center py-8">
              <span class="material-symbols-outlined text-4xl text-gray-400 mb-2">analytics</span>
              <p class="text-gray-500">{{ t('settings.components.payment.usage.noData') }}</p>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { packages, payment } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import Loading from '@/components/loading/VLoading.vue'
import VButton from '@/components/base/buttons/VButton.vue'
import PaymentInformation from '@/views/shared/PaymentInformationManagment/index.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import { useFeatureStore } from '@/stores/featureStore'
import axios from '@/plugins/axiosInstance'
import moment from 'moment'

// Store, Route & i18n setup
const assetStore = useAssetStore()
const featureStore = useFeatureStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

// State
const loading = ref(false)
const upgrading = ref(false)
const isPackageSelectionActive = ref(false)
const currentStep = ref(1)
const isTrialAvailable = ref(false)
const selectedPaymentInfoId = ref(null)
const selectedPackage = ref(null)
const availablePackages = ref([])
const existingSubscription = ref(null)

// Payment Status State
const isCheckingPayment = ref(false)
const paymentStatus = ref(null)
const showPaymentStatus = ref(false)


// Computed Properties
const currentPackage = computed(() => featureStore.currentPackage)
const usageData = computed(() => featureStore.usageData)

const isUnlimitedAccount = computed(() => {
  return existingSubscription.value?.isUnlimited
})


const isMaxPackage = computed(() => {
  const maxPackageId = Math.max(...availablePackages.value.map(p => p.id))
  const subscription = existingSubscription.value
  return currentPackage.value?.id === maxPackageId && subscription?.planId === 10
})

const filteredPackages = computed(() => {
  return availablePackages.value.filter(pkg => {
    if (isMaxPackage.value) return false
    if (currentPackage.value?.id && pkg.id < currentPackage.value.id) return false
    return !(pkg.id === 1 && !pkg.canUseTrial)
  })
})

const totalUsedLobs = computed(() => {
  return usageData.value.reduce((acc, module) => acc + module.usedAmount, 0)
})

const totalLobs = computed(() => {
  return usageData.value.reduce((acc, module) => acc + module.totalAmount, 0)
})

const canConfirmUpgrade = computed(() => {
  return selectedPackage.value && selectedPaymentInfoId.value
})

const canProceedToNextStep = computed(() => {
  return selectedPackage.value && selectedPackage.value.selectedPlan
})


const showUpgradeButton = computed(() => {
  if (existingSubscription.value?.isUnlimited) return false
  if (!existingSubscription.value?.hasActiveSubscription) return false

  if (!currentPackage.value) return true
  if (currentPackage.value.id === 1) return true
  return !isMaxPackage.value
})


const upgradeButtonText = computed(() => {
  if (currentStep.value === 2) return t('settings.components.payment.buttons.confirmUpgrade')
  if (!currentPackage.value) return t('settings.components.payment.buttons.selectPackage')
  if (isMaxPackage.value) return ''
  if (currentPackage.value.id === 1) return t('settings.components.payment.buttons.upgrade')

  const isUpgrade = selectedPackage.value?.id > currentPackage.value.id
  if (currentPackage.value.planType === 'MONTHLY') {
    return isUpgrade
        ? t('settings.components.payment.buttons.upgrade')
        : t('settings.components.payment.buttons.switchToAnnual')
  }
  return isUpgrade
      ? t('settings.components.payment.buttons.upgrade')
      : t('settings.components.payment.buttons.changePlan')
})

// Helper Methods
const formatDate = (date) => {
  if (!date) return '-'
  return moment(date).format('DD.MM.YYYY HH:mm')
}

const formatPrice = (price) => {
  if (!price) return '0 ₺'

  const [integerPart, decimalPart] = price.toString().split('.')

  const formattedInteger = new Intl.NumberFormat('tr-TR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(integerPart))

  return decimalPart ? `${formattedInteger},${decimalPart} ₺` : `${formattedInteger} ₺`
}


const formatUsage = (used, total) => `${used || 0}/${total || 0}`

const formatPercentage = (used, total) => {
  if (!used || !total) return '%0'
  const percentage = calculatePercentage(used, total)
  return `%${percentage}`
}

const calculatePercentage = (used, total) => featureStore.calculatePercentage(used, total)

const getProgressBarColor = (used, total) => {
  const percentage = featureStore.calculatePercentage(used, total)
  if (percentage >= 90) return 'bg-[#FF453A]'
  if (percentage >= 70) return 'bg-[#FF9F0A]'
  return 'bg-primary'
}

const getPercentageColor = (used, total) => {
  const percentage = calculatePercentage(used, total)
  if (percentage >= 90) return 'text-[#FF453A]'
  if (percentage >= 70) return 'text-[#FF9F0A]'
  return 'text-primary'
}

// UI Classes
const getPackageClasses = (pkg) => ({
  'border-primary shadow-lg': selectedPackage.value?.id === pkg.id,
  'border-gray-200': selectedPackage.value?.id !== pkg.id,
  'cursor-not-allowed': !canSelectPackage(pkg),
  'hover:border-gray-300 cursor-pointer': canSelectPackage(pkg),
})

const getPlanClasses = (planType, pkg) => ({
  'border-primary bg-primary/5': selectedPackage.value?.id === pkg.id && selectedPackage.value?.selectedPlan === planType,
  'border-gray-200': selectedPackage.value?.id !== pkg.id || selectedPackage.value?.selectedPlan !== planType,
  'hover:border-gray-300 cursor-pointer': canSelectPlan(pkg, planType),
  'cursor-not-allowed': !canSelectPlan(pkg, planType),
})


// Package & Plan Selection Logic
const canSelectPackage = (pkg) => {
  if (isMaxPackage.value) return false
  if (!currentPackage.value) return true
  if (pkg.id === 1 && !pkg.canUseTrial) return false

  // Prevent downgrading to lower packages
  return pkg.id >= currentPackage.value.id
}

const canSelectPlan = (pkg, planType) => {
  if (!canSelectPackage(pkg)) return false

  const subscription = existingSubscription.value
  if (!subscription) return true

  // If current package is on annual plan
  if (currentPackage.value?.planType === 'ANNUAL') {
    // Can only upgrade to annual plans of higher packages
    if (pkg.id > currentPackage.value.id) {
      return planType === 'annual'
    }
    // Cannot change plans within same package if on annual
    if (pkg.id === currentPackage.value.id) {
      return false
    }
  }

  // If current package is on monthly plan
  if (currentPackage.value?.planType === 'MONTHLY') {
    if (pkg.id === currentPackage.value.id) {
      // Can only upgrade to annual within same package
      return planType === 'annual'
    }
    // Can select either plan for higher packages
    return true
  }

  return true
}

// UI Actions
const startPackageSelection = () => {
  isPackageSelectionActive.value = true
  currentStep.value = 1
  selectedPackage.value = null
  selectedPaymentInfoId.value = null
}

const cancelSelection = () => {
  isPackageSelectionActive.value = false
  currentStep.value = 1
  selectedPackage.value = null
  selectedPaymentInfoId.value = null
}

const handlePackageSelect = (pkg) => {
  if (!canSelectPackage(pkg)) return
  selectedPackage.value = {
    ...pkg,
    selectedPlan: null,
  }
}

const handlePlanSelect = (planType, pkg) => {
  if (!canSelectPlan(pkg, planType)) return
  selectedPackage.value = {
    ...pkg,
    selectedPlan: planType,
    price: planType === 'monthly' ? pkg.monthlyPrice : pkg.annualPrice,
    planId: pkg.planId[planType],
  }
}

const proceedToNextStep = () => {
  if (!canProceedToNextStep.value) {
    responseHandlerService.handleError(t('settings.components.payment.messages.selectPackagePlan'))
    return
  }
  currentStep.value = 2
}
// Payment Status Check
const checkPaymentStatus = async () => {
  isCheckingPayment.value = true

  try {
    const response = await axios.get(payment.status, {
      params: { assetId: assetStore.assetId },
    })

    const { success, paymentStatus: status, message, details } = response.data.data
    const paymentDetails = typeof details === 'string' ? JSON.parse(details) : details

    paymentStatus.value = {
      success: true,
      status,
      message: t('settings.components.payment.messages.paymentSuccess'),
      amount: paymentDetails?.paidAmount,
      date: paymentDetails?.paymentDate,
    }

    showPaymentStatus.value = true

    if (success) {
      // Başarılı ödemeden sonra feature store'u resetleyip yeni verileri çekelim
      featureStore.resetFeaturesByAsset()
      await featureStore.fetchFeatures()

      setTimeout(() => {
        showPaymentStatus.value = false
        isPackageSelectionActive.value = false
      }, 5000)
    }
  } catch (error) {
    paymentStatus.value = {
      success: false,
      status: 'ERROR',
      message: t('settings.components.payment.messages.paymentCheckError'),
      details: t('settings.components.payment.messages.refreshAndRetry'),
    }
    showPaymentStatus.value = true
  } finally {
    isCheckingPayment.value = false
  }
}

// API Calls
const getExistingSubscription = async () => {
  try {
    const response = await axios.get(payment.getExistingSubscription, {
      params: { assetId: assetStore.assetId },
    })
    existingSubscription.value = response.data.data
    return response
  } catch (error) {
    console.error('Error fetching subscription:', error)
    return null
  }
}

const fetchAvailablePackages = async () => {
  try {
    const packagesResponse = await axios.get(packages.get)
    const { packages: allPackages, canUseTrial } = packagesResponse.data.data
    const subscription = existingSubscription.value
    const currentPackageId = subscription?.packageId

    availablePackages.value = allPackages
        .filter(pkg => {
          if (pkg.packageId === 1) {
            return canUseTrial || currentPackageId === 1
          }
          return true
        })
        .map(pkg => {
          const monthlyLobsLimit = pkg.features.find(f => f.code === 'SEO_ANALYSIS')?.monthlyLobsLimit || 0
          const monthlyPlanPrice = pkg.plans.find(p => p.type === 'MONTHLY')?.price || 0
          const annualPlanPrice = pkg.plans.find(p => p.type === 'ANNUAL')?.price || 0

          return {
            id: pkg.packageId,
            name: pkg.packageName,
            description: pkg.description,
            monthlyPrice: monthlyPlanPrice,
            annualPrice: annualPlanPrice,
            monthlyFeatures: `${monthlyLobsLimit} ${t('settings.components.payment.usage.lobs')}/${t('settings.components.payment.period.month')} + ${pkg.features.find(f => f.code === 'SEO_ANALYSIS')?.discountRate || 5}% ${t('settings.components.payment.discount')}`,
            annualFeatures: `${monthlyLobsLimit} ${t('settings.components.payment.usage.lobs')}/${t('settings.components.payment.period.month')} + ${pkg.features.find(f => f.code === 'SEO_ANALYSIS')?.discountRate || 20}% ${t('settings.components.payment.discount')}`,
            features: pkg.features
                .filter(f => f.isAvailable)
                .map(f => {
                  if (f.monthlyLobsLimit > 0) {
                    return `${t(`${featureStore.getFeatureTranslationKey(f)}.name`)} (${f.monthlyLobsLimit} ${t('settings.components.payment.usage.lobs')}/${t('settings.components.payment.period.month')})`
                  }
                  return t(`${featureStore.getFeatureTranslationKey(f)}.name`)
                }),
            planId: {
              monthly: pkg.plans.find(p => p.type === 'MONTHLY')?.planId,
              annual: pkg.plans.find(p => p.type === 'ANNUAL')?.planId,
            },
            isCurrent: pkg.packageId === currentPackageId,
            isPopular: pkg.packageId === 3,
            canUseTrial: pkg.packageId === 1 ? canUseTrial : true,
            selectedPlan: (pkg.packageId === currentPackageId && subscription?.planId === 10) ? 'annual' : null,
          }
        })
        .sort((a, b) => a.id - b.id)

    isTrialAvailable.value = canUseTrial
  } catch (error) {
    responseHandlerService.handleError(error)
    availablePackages.value = []
  }
}

const handleUpgrade = async () => {
  if (!canConfirmUpgrade.value || upgrading.value) return

  upgrading.value = true
  try {
    const response = await axios.post(packages.upgrade, {
      assetId: assetStore.assetId,
      newPackageId: selectedPackage.value.id,
      paymentInfoId: selectedPaymentInfoId.value,
      planId: selectedPackage.value.planId[selectedPackage.value.selectedPlan],
      upgradeType: determineUpgradeType(),
    })

    if (response.data?.data?.paymentPageUrl) {
      window.location.replace(response.data.data.paymentPageUrl)
    } else {
      responseHandlerService.handleError(t('settings.components.payment.messages.paymentPageError'))
      upgrading.value = false
    }
  } catch (error) {
    responseHandlerService.handleError(error)
    upgrading.value = false
  }
}

const determineUpgradeType = () => {
  if (!currentPackage.value || !selectedPackage.value) return 'NEW_SUBSCRIPTION'

  // Same package plan changes
  if (currentPackage.value.id === selectedPackage.value.id) {
    if (currentPackage.value.planType === 'MONTHLY' && selectedPackage.value.selectedPlan === 'annual') {
      return 'UPGRADE_TO_ANNUAL'
    }
    return 'PLAN_CHANGE'
  }

  // Package upgrades
  if (selectedPackage.value.id > currentPackage.value.id) {
    if (selectedPackage.value.selectedPlan === 'annual') {
      return 'UPGRADE_TO_YEARLY_PACKAGE'
    }
    return 'UPGRADE_PACKAGE'
  }

  return 'PACKAGE_CHANGE'
}

// Handle URL query parameters
const handlePaymentRedirect = async () => {
  const successValue = route.query.success || route.query['?success']

  if (successValue !== undefined) {
    try {
      await checkPaymentStatus()
    } catch (error) {
      console.error('Payment status check failed:', error)
    } finally {
      const newQuery = { tab: route.query.tab }
      await router.replace({ query: newQuery })
      await initializeComponent()
    }
  }
}

// Component Initialization
const initializeComponent = async () => {
  loading.value = true
  try {
    const subscriptionResponse = await getExistingSubscription()
    if (subscriptionResponse) {
      await featureStore.fetchFeatures()
      await fetchAvailablePackages()
    }
  } catch (error) {
    responseHandlerService.handleError(t('settings.components.payment.messages.loadingError'))
  } finally {
    loading.value = false
  }
}

// Lifecycle Hooks & Watchers
onMounted(async () => {
  if (route.query.success !== undefined) {
    await handlePaymentRedirect()
  } else {
    await initializeComponent()
  }
})

watch(
    () => route.query,
    async (newQuery) => {
      if (newQuery.success !== undefined) {
        await handlePaymentRedirect()
      }
    },
)
</script>

<style scoped>
.loading-overlay {
  @apply absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10;
}

.feature-icon {
  @apply flex-shrink-0 transition-transform duration-200;
}

.progress-bar {
  @apply transition-all duration-500 ease-in-out transform origin-left;
}

.validation-loading-overlay {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  z-index: 50;
}

.package-card {
  transition: all 0.3s ease;
}

.package-card:hover {
  transform: translateY(-2px);
}

.disabled-overlay {
  @apply absolute inset-0 bg-gray-100/50 backdrop-blur-[1px] flex items-center justify-center cursor-not-allowed rounded-lg;
}

.package-transition {
  @apply transition-all duration-300 ease-in-out;
}

.plan-option {
  @apply transition-colors duration-200;
}

.package-grid {
  @apply grid gap-6;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.backdrop-blur-sm {
  backdrop-filter: blur(4px);
}

@media (min-width: 1024px) {
  .package-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>