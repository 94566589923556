<template>
  <div class="bg-slate-50 min-h-screen py-6 relative">
    <div class="mx-auto px-4">
      <!-- Header -->
      <div class="bg-white rounded-xl shadow-sm mb-6">
        <div class="p-6 border-b border-slate-100">
          <div class="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
            <HeaderInfo :title="entityData.title" :category="entityData.category"/>
            <ActionButtons :social-media-data="socialMediaData" :content="contentDataForActions" :can-optimize="canOptimize" :entity-url="entityUrl" :is-analyzing="isAnalyzing" :is-revised-entity-received="isRevisedEntityReceived" :is-history-content="isHistoryContent" :save-edited-content-loading="isSaving" @analyze="fetchAnalyzedContent" @toggle-history="toggleHistoryModal" @navigate="goToContent" @save-content="saveHistoryContent" @save-edited-content="saveRevision" @update:social-media-data="updateSocialMediaData"/>
          </div>
        </div>
      </div>

      <!-- Main Content Grid -->
      <div class="grid grid-cols-1 lg:grid-cols-12 gap-6">
        <!-- Left Column -->
        <div class="lg:col-span-8 space-y-6">
          <ContentPreview :content="truncatedContent" @preview="showPreviewModal = true"/>
          <Suspense>
            <MediaGallery :image-urls="entityData.imageUrls" :is-loading="isLoading"/>
          </Suspense>
        </div>

        <!-- Right Column -->
        <div class="lg:col-span-4 space-y-6">
          <ScoreSection :score="entityData.score" :parsed-score-message="parsedScoreMessage" :carousel-items="carouselItems"/>
          <KeywordsSection :keywords="entityData.keywords"/>
          <Suspense>
            <RecommendationsSection v-if="hasRecommendations" :entity-data="recommendationData" :parsed-seo-shortcomings="parsedSeoShortcomings"/>
          </Suspense>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <PreviewContentModal v-if="showPreviewModal" v-model="showPreviewModal" :content="previewContent"/>

    <ReviseContentModal v-if="showReviseModal" v-model="showReviseModal" :selected-message="selectedMessage" :selected-type="selectedType" :selected-data="selectedData" :module-type-id="1" :entity-id="entityData.optimizeId || entityData.id" @confirm="handleReviseConfirm"/>

    <!-- Sidebar -->
    <ContentHistories ref="contentHistory" :entity-id="entityData.id" :top-id="entityData.topId" @withdrawHistory="fetchEntityDetail"/>

    <!-- Loading -->
    <div v-if="isLoading || isAnalyzing" class="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex items-center justify-center z-40">
      <Loading/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watchEffect, defineAsyncComponent, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAssetStore } from '@/stores/asset'
import { entity } from '@/networking/urlManager'
import axios from '@/plugins/axiosInstance'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { safeParse } from '@/utils/common'

// Core Components
import HeaderInfo from './components/header/HeaderInfo.vue'
import ActionButtons from './components/header/ActionButtons.vue'
import ContentPreview from './components/content/ContentPreview.vue'
import ScoreSection from './components/score/ScoreSection.vue'
import KeywordsSection from './components/keyword/KeywordsSection.vue'
import Loading from '@/components/loading/VLoading.vue'

// Lazy Loaded Components
const MediaGallery = defineAsyncComponent(() =>
    import('./components/content/MediaGallery.vue'),
)
const RecommendationsSection = defineAsyncComponent(() =>
    import('./components/recommendations/RecommendationsSection.vue'),
)
const PreviewContentModal = defineAsyncComponent(() =>
    import('@/components/modals/PreviewContentModal.vue'),
)
const ReviseContentModal = defineAsyncComponent(() =>
    import('../reviseContentModal/reviseContentModal.vue'),
)
const ContentHistories = defineAsyncComponent(() =>
    import('./components/contentHistory/contentHistories.vue'),
)

const props = defineProps({
  selectedContent: { type: Number, required: true },
})

// Core state management
const { t } = useI18n()
const assetStore = useAssetStore()
const entityData = ref({})
const entityUrl = ref('')
const optimizeId = ref('')

// UI state management
const contentHistory = ref(null)
const showPreviewModal = ref(false)
const showReviseModal = ref(false)
const isLoading = ref(false)
const isAnalyzing = ref(false)
const isSaving = ref(false)
const isRevisedEntityReceived = ref(false)
const selectedMessage = ref('')
const selectedType = ref('')
const selectedData = ref('')
const isHistoryContent = ref(false)

// Utility Functions
const memoizedParseJson = (jsonString, defaultValue) => {
  if (!jsonString) return defaultValue
  try {
    return JSON.parse(jsonString)
  } catch {
    return defaultValue
  }
}

const truncateText = (text, limit = 500) => {
  if (!text) return ''
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = text
  const plainText = tempDiv.textContent || tempDiv.innerText
  return plainText.length > limit ? text.substring(0, limit) + '...' : text
}

// Optimized Computed Properties
const firstImage = computed(() => {
  const images = entityData.value?.imageUrls || []
  return images[0]?.url || images[0]?.image || null
})

const contentDataForActions = computed(() => ({
  title: entityData.value.title || '',
  content: entityData.value.content || '',
  category: entityData.value.category || '',
  keywords: entityData.value.keywords || '',
  metaDescription: entityData.value.metaDescription || '',
  imageUrls: entityData.value.imageUrls || [],
  socialMediaContent: entityData.value.socialMediaContent || '',
  socialMediaHashtags: entityData.value.socialMediaHashtags || [],
}))

const socialMediaData = computed(() => ({
  entityId: entityData.value.id,
  assetId: assetStore.assetId,
  title: entityData.value.title,
  content: entityData.value?.socialMediaContent || '',
  hashtags: safeParse(entityData.value?.socialMediaHashtags, null, 'array') || [],
  url: entityUrl.value,
  image: firstImage.value,
  isSocialMediaContentReady: !!entityData.value?.socialMediaContent,
}))

const parsedScoreMessage = computed(() => {
  const message = entityData.value.scoreMessage
  const parsed = memoizedParseJson(message, null)
  if (!parsed) return { type: 'simple', message }
  return parsed.sections
      ? { type: 'detailed', data: parsed }
      : { type: 'simple', message: parsed.message || message }
})

const carouselItems = computed(() => {
  const sections = parsedScoreMessage.value?.data?.sections
  if (!sections) return []

  const items = Object.entries(sections)
      .filter(([key]) => key !== 'improvements')
      .map(([key, data]) => ({ type: 'section', key, data }))

  if (sections.improvements?.length) {
    items.push({
      type: 'improvements',
      data: sections.improvements,
    })
  }

  return items
})

const previewContent = computed(() => ({
  title: entityData.value.title || '',
  body: entityData.value.content || '',
  category: entityData.value.category || '',
  metaDescription: entityData.value.metaDescription || '',
  keywords: entityData.value.keywords?.map(k => k.name).join(', ') || '',
  images: entityData.value.imageUrls || [],
}))

const recommendationData = computed(() => ({
  semanticMessage: entityData.value.semanticMessage || '',
  spellingMessage: entityData.value.spellingMessage || '',
  seoShortcomings: entityData.value.seoShortcomings || [],
}))

const truncatedContent = computed(() =>
    truncateText(entityData.value.content),
)

const hasRecommendations = computed(() =>
    !!(entityData.value.semanticMessage ||
        entityData.value.spellingMessage ||
        entityData.value.seoShortcomings),
)

const parsedSeoShortcomings = computed(() =>
    memoizedParseJson(entityData.value.seoShortcomings, []),
)

const canOptimize = computed(() =>
    [2, 3].includes(entityData.value.entityTypeId),
)

// Helper Methods
const parseImageUrls = (imageUrlsData) => {
  if (!imageUrlsData) return []

  if (Array.isArray(imageUrlsData) &&
      imageUrlsData.every(item => typeof item === 'object' && (item.url || item.image))) {
    return imageUrlsData
  }

  const parsed = safeParse(imageUrlsData, [], 'array')
  return parsed.length && parsed.every(item => typeof item === 'object' && (item.url || item.image))
      ? parsed
      : []
}

const updateEntityData = (data) => {
  if (!data) return

  entityUrl.value = data.entityUrl || entityUrl.value
  entityData.value = {
    ...data,
    keywords: (data.keywords || []).filter(keyword => keyword && keyword.name),
    imageUrls: parseImageUrls(data.imageUrls),
    recommendedKeywords: safeParse(data.recommendedKeywords, []),
    recomendationNotes: {
      scoreMessage: data.scoreMessage || '',
      semanticMessage: data.semanticMessage || '',
      metaDescription: data.metaDescription || '',
      seoShortcomings: data.seoShortcomings || [],
      spellingMessage: data.spellingMessage || '',
    },
  }
}

// API Methods
const fetchEntityDetail = async (entityId = null) => {
  isLoading.value = true
  try {
    const response = await axios.get(entity.getDetail, {
      params: {
        entityId: entityId || props.selectedContent,
        assetId: assetStore.assetId,
      },
    })
    if (entityId) {
      optimizeId.value = entityId
      isHistoryContent.value = true
    }
    updateEntityData(response.data.data)
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.contentReview.errorFetch'))
    optimizeId.value = ''
  } finally {
    isLoading.value = false
  }
}

const fetchAnalyzedContent = async () => {
  isAnalyzing.value = true
  try {
    const response = await axios.put(entity.assetContentEdit, {
      entityId: entityData.value.id,
      assetId: assetStore.assetId,
    })
    const { data } = response.data

    updateEntityData({
      ...entityData.value,
      optimizeId: data.optimizeId || '',
      title: data.result.title || '',
      score: data.result.score || '',
      content: data.result.content || '',
      entityTypeId: 3,
      keywords: data.result.keywords.filter(r => r !== '') || [],
      contentDifference: data.result.contentDifference || [],
      subTitle: data.result.spot || '',
      isActive: data.result.isActive || 1,
      recomendationNotes: {
        ...entityData.value.recomendationNotes,
        scoreMessage: data.result.scoreMessage || '',
      },
    })
    isRevisedEntityReceived.value = true
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.contentReview.errorOptimize'))
  } finally {
    isAnalyzing.value = false
  }
}

const saveRevision = async () => {
  isSaving.value = true
  try {
    await axios.post(entity.saveEditedContent, {
      entityId: entityData.value.id,
      optimizeId: entityData.value.optimizeId,
      assetId: assetStore.assetId,
    })
    await fetchEntityDetail()
    responseHandlerService.handleSuccess(t('entity.components.contentReview.contentSaved'))
    isRevisedEntityReceived.value = false
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.contentReview.errorSave'))
  } finally {
    isSaving.value = false
  }
}

const handleReviseConfirm = (response) => {
  if (!response?.data) return

  const imageUrls = Array.isArray(response.data.imageUrls)
      ? response.data.imageUrls
      : typeof response.data.imageUrls === 'string'
          ? safeParse(response.data.imageUrls, [])
          : entityData.value.imageUrls

  updateEntityData({
    ...entityData.value,
    ...response.data,
    imageUrls,
    optimizeId: response.optimizeId || '',
  })
}

const saveHistoryContent = async () => {
  isLoading.value = true
  try {
    await axios.post(entity.saveEditedContent, {
      entityId: entityData.value.id,
      optimizeId: optimizeId.value,
      assetId: assetStore.assetId,
    })
    await fetchEntityDetail()
    isHistoryContent.value = false
    responseHandlerService.handleSuccess(t('entity.components.contentReview.contentSaved'))
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.contentReview.errorSave'))
  } finally {
    isLoading.value = false
  }
}

const goToContent = (url) => window.open(url, '_blank')
const toggleHistoryModal = () => contentHistory.value?.toggleSidebar()
const updateSocialMediaData = (newData) => {
  entityData.value = {
    ...entityData.value,
    socialMediaContent: newData.content,
    socialMediaHashtags: newData.hashtags,
  }
}

// Lifecycle
onMounted(() => {
  fetchEntityDetail()
})

watchEffect(() => {
  if (props.selectedContent) fetchEntityDetail()
})
</script>