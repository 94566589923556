import axios from 'axios'
import router from '@/router'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { baseUrl } from '@/networking/urlManager'
import { errorMessages } from './errorMessages'

const DEFAULT_TIMEOUT = 500000

let stores = null

export const initializeAxiosStores = (authStore, uiStore) => {
  stores = { authStore, uiStore }
}

const instance = axios.create({
  baseURL: baseUrl,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel

instance.interceptors.request.use(
    (config) => {
      if (!stores) {
        console.warn('Stores not initialized in axios instance')
        return config
      }

      const { authStore, uiStore } = stores
      responseHandlerService.resetConnectionErrorFlag()
      errorMessages.setLanguage(uiStore.getCurrentLanguage)

      const token = authStore.userData?.token
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      config.headers['Accept-Language'] = uiStore.getCurrentLanguage
      config.headers.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Istanbul'

      return config
    },
    (error) => Promise.reject(error),
)

instance.interceptors.response.use(
    (response) => {
      responseHandlerService.resetConnectionErrorFlag()
      return response
    },
    async (error) => {
      if (axios.isCancel(error)) return Promise.reject(error)

      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        responseHandlerService.handleError({
          isConnectionError: true,
          message: errorMessages.get('connectionError'),
        })
        return Promise.reject(error)
      }

      if (!error.response) {
        responseHandlerService.handleError({
          isConnectionError: true,
          message: errorMessages.get('connectionError'),
        })
        return Promise.reject(error)
      }

      if (!stores) {
        console.warn('Stores not initialized in axios instance')
        return Promise.reject(error)
      }

      const { authStore } = stores

      switch (error.response.status) {
        case 401: {
          if (error.config._skipAuthRedirect) {
            await authStore.clearUserData()
            return Promise.reject(error)
          }

          await authStore.clearUserData()
          await router.push('/')

          if (!responseHandlerService.unauthorizedSnackbarDisplayed) {
            const message = error.response.data?.message || errorMessages.get('sessionExpired')
            responseHandlerService.handleError({ message })
            responseHandlerService.unauthorizedSnackbarDisplayed = true
          }
          break
        }

        case 500: {
          const message = error.response.data?.message || errorMessages.get('serverError')
          responseHandlerService.handleError({ message })
          break
        }
      }

      return Promise.reject(error)
    },
)

export default instance