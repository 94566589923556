<template>
  <div class="bg-white rounded-xl shadow-sm overflow-hidden min-h-[320px] relative" :class="[imageClass, 'w-full']">
    <div class="h-full">
      <!-- Loading State -->
      <div v-if="contentLoading || loading" class="absolute inset-0 bg-slate-50 flex items-center justify-center z-50">
        <div class="flex flex-col items-center justify-center gap-2">
          <span class="material-symbols-outlined animate-spin text-primary text-2xl">progress_activity</span> <span class="text-sm text-slate-500 font-medium">{{ t('general.loading') }}</span>
        </div>
      </div>

      <!-- Image Gallery -->
      <div v-else-if="validImages.length > 0" class="relative h-[320px]">
        <!-- Navigation Arrows -->
        <button v-if="validImages.length > 1" @click="prevImage" class="absolute top-1/2 left-4 -translate-y-1/2 z-10 bg-white/90 hover:bg-white rounded-full w-10 h-10 shadow-lg transition-all flex items-center justify-center">
          <span class="material-symbols-outlined text-primary">chevron_left</span>
        </button>

        <!-- Images -->
        <div class="h-full flex items-center justify-center" v-viewer>
          <template v-for="(item, index) in validImages" :key="getImageKey(item, index)">
            <img v-show="index === currentIndex" :src="getImageSrc(item)" :alt="t('globalComponents.imageComponent.contentImageAltText')" class="h-full w-full object-cover transition-opacity duration-300" @error="() => handleImageError(index)"/>
          </template>
        </div>

        <button v-if="validImages.length > 1" @click="nextImage" class="absolute top-1/2 right-4 -translate-y-1/2 z-10 bg-white/90 hover:bg-white rounded-full w-10 h-10 shadow-lg transition-all flex items-center justify-center">
          <span class="material-symbols-outlined text-primary">chevron_right</span>
        </button>

        <!-- Image Counter -->
        <div v-if="validImages.length > 1" class="absolute bottom-4 left-1/2 -translate-x-1/2 bg-white/90 px-3 py-1.5 rounded-full text-xs font-medium">
          {{ currentIndex + 1 }} / {{ validImages.length }}
        </div>
      </div>

      <!-- No Image State -->
      <div v-else class="h-[320px] w-full flex items-center justify-center bg-slate-50 text-slate-500">
        <div class="flex items-center justify-center flex-col gap-2">
          <span class="material-symbols-outlined text-3xl">image_not_supported</span> <span class="text-sm">{{ t('globalComponents.imageComponent.noImageFound') }}</span>
        </div>
      </div>

      <!-- Upload Button -->
      <div v-if="isImageUploadable" class="absolute bottom-4 right-4">
        <label class="cursor-pointer bg-white hover:bg-slate-50 px-3 py-2 rounded-full shadow-lg transition-all flex items-center gap-2"> <span class="material-symbols-outlined text-primary">add_a_photo</span>
          <span class="text-sm font-medium">{{ t('globalComponents.imageComponent.uploadImage') }}</span> <input type="file" accept="image/*" class="hidden" @change="uploadImage"/> </label>
      </div>
    </div>

    <!-- Refresh Button -->
    <div v-if="validImages.length > 0 && !contentLoading && isImageRefreshable" class="p-2 border-t border-slate-100">
      <button @click="refreshImage" class="w-full flex items-center justify-center gap-2 px-4 py-2 bg-primary/5 hover:bg-primary/10 text-primary rounded-lg transition-colors text-sm">
        <span class="material-symbols-outlined !text-[18px]">refresh</span> {{ t('globalComponents.imageComponent.refreshImageButton') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

const LOADING_TIMEOUT = 1000

const props = defineProps({
  imageUrls: {
    type: [Array, String],
    required: false,
    default: () => [],
  },
  imageClass: {
    type: String,
    default: '',
  },
  contentLoading: {
    type: Boolean,
    default: false,
  },
  isImageRefreshable: {
    type: Boolean,
    default: false,
  },
  isImageUploadable: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['refreshImage', 'uploadImage'])

const { t } = useI18n()

const currentIndex = ref(0)
const loading = ref(false)
const invalidImageIndices = ref(new Set())

const validImages = computed(() => {
  const images = handleImageUrls()
  return images.filter((_, index) => !invalidImageIndices.value.has(index))
})

function refreshImage() {
  emit('refreshImage', props.imageUrls[currentIndex.value], currentIndex.value)
}

function nextImage() {
  if (currentIndex.value < validImages.value.length - 1) {
    currentIndex.value++
  } else {
    currentIndex.value = 0
  }
}

function prevImage() {
  if (currentIndex.value > 0) {
    currentIndex.value--
  } else {
    currentIndex.value = validImages.value.length - 1
  }
}

function uploadImage(event) {
  const file = event.target.files[0]
  if (file) {
    emit('uploadImage', file)
  }
}

function preloadImages() {
  const images = handleImageUrls()

  if (!images) return

  images.forEach((imageUrl, index) => {
    const img = new Image()
    img.src = getImageSrc(imageUrl)
    img.onerror = () => handleImageError(index)
  })
}

function handleImageUrls() {
  let imageUrls = props.imageUrls

  if (props.imageUrls === null) {
    imageUrls = []
  } else if (typeof props.imageUrls === 'string') {
    try {
      imageUrls = JSON.parse(props.imageUrls)
    } catch (error) {
      console.warn('Invalid imageUrls format:', props.imageUrls)
      console.error('Failed to parse imageUrls:', error)
      imageUrls = []
    }
  }

  return Array.isArray(imageUrls) ? imageUrls : []
}

function handleImageError(index) {
  invalidImageIndices.value.add(index)

  // If current image is invalid, move to next valid image
  if (index === currentIndex.value) {
    const nextValidIndex = validImages.value.findIndex((_, i) => i > currentIndex.value)
    if (nextValidIndex !== -1) {
      currentIndex.value = nextValidIndex
    } else {
      currentIndex.value = 0
    }
  }
}

function getImageSrc(item) {
  return typeof item === 'object' ? item.image || item.url : item
}

function getImageKey(item, index) {
  return typeof item === 'object' ? item.id : index
}

watch(
    () => props.imageUrls,
    () => {
      loading.value = true
      invalidImageIndices.value.clear()
      currentIndex.value = 0
      preloadImages()
      nextTick(() => {
        setTimeout(() => {
          loading.value = false
        }, LOADING_TIMEOUT)
      })
    },
    { deep: true },
)

// Initial preload
preloadImages()
</script>