<template>
  <div v-if="show" :class="[
      'border-b',
      currentStyle.bg,
      currentStyle.border,
      $attrs.class,
      'w-full',
    ]">
    <div :class="['mx-auto py-2 px-4', layout]">
      <div class="flex items-center justify-between gap-4">
        <!-- Sol Kısım -->
        <div class="flex items-center gap-2 flex-1">
          <span :class="['material-symbols-outlined !text-[18px]', currentStyle.icon]">
            {{ currentStyle.icon_name }}
          </span>
          <div class="flex flex-wrap items-center gap-4">
            <p :class="[
                'text-sm font-medium',
                currentStyle.text,
                'break-words',
              ]">
              {{ message }} </p>
            <button v-if="hasAction" @click="emit('action')" :class="[
                'text-sm px-2 py-0.5 rounded-md border',
                currentStyle.actionText,
                currentStyle.actionBorder,
              ]">
              {{ actionText }}
            </button>
          </div>
        </div>
        <!-- Sağ Kısım (Çarpı Butonu) -->
        <button v-if="dismissible" @click="emit('dismiss')" :class="[
            'flex items-center',
            currentStyle.icon,
            currentStyle.hover,
          ]">
          <span class="material-symbols-outlined !text-[20px]">close</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const emit = defineEmits(['action', 'dismiss', 'update:show'])

const props = defineProps({
  status: {
    type: String,
    default: 'warning',
    validator: (value) =>
        ['success', 'error', 'warning', 'info'].includes(value),
  },
  message: {
    type: String,
    required: true,
  },
  hasAction: {
    type: Boolean,
    default: false,
  },
  actionText: {
    type: String,
    default: '',
  },
  show: {
    type: Boolean,
    default: true,
  },
  dismissible: {
    type: Boolean,
    default: true,
  },
  layout: {
    type: String,
    default: '',
  },
})

const statusStyles = {
  success: {
    bg: 'bg-green-50',
    border: 'border-green-200',
    text: 'text-green-700',
    icon: 'text-green-600',
    hover: 'hover:text-green-800',
    icon_name: 'check_circle',
    actionText: 'text-gray-600',
    actionBorder: 'border-gray-400',
  },
  warning: {
    bg: 'bg-yellow-50',
    border: 'border-yellow-200',
    text: 'text-yellow-700',
    icon: 'text-yellow-600',
    hover: 'hover:text-yellow-800',
    icon_name: 'warning',
    actionText: 'text-gray-600',
    actionBorder: 'border-gray-400',
  },
  info: {
    bg: 'bg-blue-50',
    border: 'border-blue-200',
    text: 'text-blue-700',
    icon: 'text-blue-600',
    hover: 'hover:text-blue-800',
    icon_name: 'info',
    actionText: 'text-gray-600',
    actionBorder: 'border-gray-400',
  },
}

const currentStyle = computed(() => statusStyles[props.status])
</script>