<template>
  <div ref="triggerRef" :class="[containerClass, { 'w-full': fullWidth, 'inline-block': !fullWidth }]" v-bind="$attrs">
    <slot name="content"></slot>
    <teleport to="body">
      <div v-if="shouldShow" ref="tooltipRef" class="fixed flex-col items-center flex pointer-events-none px-3 py-2 rounded-lg shadow-lg transition-opacity duration-300" :class="[tipClass, colorVariations[color]?.[variation] || colorVariations.default[variation]]" :style="tooltipStylesWithZIndex">
        <span class="text-center text-xs break-words whitespace-pre-wrap" :style="{ maxWidth: maxWidth }">
          {{ title }}
        </span>
        <div ref="arrowRef" class="absolute w-3 h-3 rotate-45" :class="[arrowColorClass]" :style="arrowStyles"></div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick, watch } from 'vue'
import { computePosition, autoUpdate, offset, flip, shift, arrow } from '@floating-ui/dom'

const props = defineProps({
  title: String,
  tipClass: String,
  displayCondition: { type: Boolean, default: true },
  externalTrigger: { type: Boolean, default: false },
  isTriggered: { type: Boolean, default: false },
  color: {
    type: String,
    default: 'default',
    validator: (value) => ['primary', 'secondary', 'accent', 'neutral', 'indigo', 'default'].includes(value),
  },
  variation: {
    type: String,
    default: 'solid',
    validator: (value) => ['solid', 'outline'].includes(value),
  },
  maxWidth: {
    type: [Number, String],
    default: 200,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  containerClass: {
    type: String,
    default: '',
  },
  zIndex: { type: String, default: '99' },
})

const isOpen = ref(false)
const triggerRef = ref(null)
const tooltipRef = ref(null)
const arrowRef = ref(null)

const tooltipStyles = ref({})
const arrowStyles = ref({})

const maxWidth = computed(() => props.maxWidth ? `${props.maxWidth}px` : '200px')
let cleanup = null

const shouldShow = computed(() => props.displayCondition && (props.externalTrigger ? props.isTriggered : isOpen.value))

const tooltipStylesWithZIndex = computed(() => ({
  ...tooltipStyles.value,
  zIndex: props.zIndex,
}))

const colorVariations = computed(() => ({
  primary: {
    solid: 'bg-primary text-white',
    outline: 'bg-white text-primary border border-primary',
  },
  secondary: {
    solid: 'bg-secondary text-white',
    outline: 'bg-white text-secondary border border-secondary',
  },
  accent: {
    solid: 'bg-accent text-white',
    outline: 'bg-white text-accent border border-accent',
  },
  neutral: {
    solid: 'bg-neutral text-gray-800',
    outline: 'bg-white text-gray-800 border border-neutral',
  },
  indigo: {
    solid: 'bg-indigo text-white',
    outline: 'bg-white text-indigo border border-indigo',
  },
  default: {
    solid: 'bg-gray-800 text-white',
    outline: 'bg-white text-gray-800 border border-gray-300',
  },
}))

const arrowColorClass = computed(() => {
  if (props.variation === 'outline') {
    return 'bg-white'
  }
  return colorVariations.value[props.color]?.solid.split(' ')[0] || 'bg-gray-800'
})

const updatePosition = () => {
  if (!triggerRef.value || !tooltipRef.value) return

  computePosition(triggerRef.value, tooltipRef.value, {
    placement: 'top',
    middleware: [
      offset(8),
      flip(),
      shift({ padding: 5 }),
      arrow({ element: arrowRef.value }),
    ],
  }).then(({ x, y, placement, middlewareData }) => {
    Object.assign(tooltipStyles.value, {
      left: `${x}px`,
      top: `${y}px`,
    })

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]]

    if (middlewareData.arrow) {
      const { x: arrowX, y: arrowY } = middlewareData.arrow

      Object.assign(arrowStyles.value, {
        left: arrowX != null ? `${arrowX}px` : '',
        top: arrowY != null ? `${arrowY}px` : '',
        right: '',
        bottom: '',
        [staticSide]: '-4px',
      })
    }
  })
}

const showTooltip = () => {
  if (!props.externalTrigger && props.displayCondition) {
    isOpen.value = true
    nextTick(() => {
      updatePosition()
      setupAutoUpdate()
    })
  }
}

const hideTooltip = () => {
  if (!props.externalTrigger) {
    isOpen.value = false
    if (cleanup) {
      cleanup()
      cleanup = null
    }
  }
}

const setupAutoUpdate = () => {
  if (triggerRef.value && tooltipRef.value) {
    cleanup = autoUpdate(triggerRef.value, tooltipRef.value, updatePosition)
  }
}

watch(() => props.isTriggered, (newValue) => {
  if (props.externalTrigger) {
    if (newValue) {
      nextTick(() => {
        updatePosition()
        setupAutoUpdate()
      })
    } else if (cleanup) {
      cleanup()
      cleanup = null
    }
  }
})

onMounted(() => {
  if (!triggerRef.value) return

  if (!props.externalTrigger) {
    triggerRef.value.addEventListener('mouseenter', showTooltip)
    triggerRef.value.addEventListener('mouseleave', hideTooltip)
  }
})

onBeforeUnmount(() => {
  if (triggerRef.value && !props.externalTrigger) {
    triggerRef.value.removeEventListener('mouseenter', showTooltip)
    triggerRef.value.removeEventListener('mouseleave', hideTooltip)
  }
  if (cleanup) {
    cleanup()
  }
})

watch(() => props.displayCondition, (newValue) => {
  if (!newValue) {
    hideTooltip()
  }
})
</script>