<template>
  <VueFinalModal :click-to-close="true" :esc-to-close="true" @closed="handleClose" class="flex justify-center items-center" content-class="flex flex-col bg-white w-full max-w-2xl shadow-lg rounded-md">
    <div class="flex flex-col w-full h-full">
      <!-- Header -->
      <div class="sticky top-0 z-10 w-full flex items-center justify-between bg-primary/95 px-6 py-1 text-white shrink-0 rounded-t-md">
        <h2 class="text-base font-medium flex items-center gap-2">
          <span class="material-symbols-outlined !text-[20px]">movie</span> <span>{{ t('entity.components.videoCreation.modal.title') }}</span>
        </h2>
        <button @click="$emit('update:modelValue', false)" class="p-1.5 rounded-md hover:bg-white/10 active:bg-white/20 transition-colors flex items-center">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>

      <!-- Content -->
      <div class="flex-1 overflow-y-auto p-6">
        <form @submit.prevent="handleSubmit" class="space-y-6">
          <!-- Description Input -->
          <CustomInput v-model="formData.description" type="textarea" :label="t('entity.components.videoCreation.modal.descriptionLabel')" :description="t('entity.components.videoCreation.modal.descriptionHelper')" :error="validationErrors.description" containerClass="w-full" additionalInputClass="resize-none min-h-[120px]" :show-error="true" required/>

          <!-- Image Upload Section -->
          <div class="space-y-2">
            <label class="text-sm font-medium text-gray-700 flex items-center justify-between"> <span>{{ t('common.imageUpload') }} *</span>
              <span class="text-xs text-gray-500">{{ formData.images.length }}/1 {{ t('common.images') }}</span> </label>

            <!-- Upload Area -->
            <div v-if="formData.images.length === 0" class="border-2 border-dashed border-gray-200 rounded-lg p-4 hover:border-primary/40 transition-colors cursor-pointer bg-gray-50" :class="{ 'border-red-300': validationErrors.images }" @click="triggerImageUpload" @dragover.prevent @drop.prevent="handleImageDrop">
              <div class="flex flex-col items-center justify-center space-y-2">
                <span class="material-symbols-outlined text-gray-400 text-2xl">upload_file</span>
                <p class="text-sm text-gray-500">{{ t('common.dragAndDrop') }}</p>
                <p class="text-xs text-gray-400">{{ t('entity.components.videoCreation.modal.imageFormatInfo') }}</p>
              </div>
              <input type="file" ref="fileInput" class="hidden" accept="image/jpeg,image/png" @change="handleImageSelect">
            </div>

            <!-- Image Preview -->
            <div v-if="formData.images.length > 0" class="flex items-center space-x-3">
              <div class="relative w-24 h-24 rounded-lg overflow-hidden flex-shrink-0 border border-gray-200">
                <img :src="formData.images[0].preview" class="w-full h-full object-cover" alt="Preview"/>
                <button type="button" @click="removeImage(0)" class="absolute top-1 right-1 p-1 bg-white/90 rounded-full shadow-sm hover:bg-white flex items-center">
                  <span class="material-symbols-outlined !text-[14px] text-gray-600">close</span>
                </button>
              </div>
              <div class="text-sm text-gray-500">
                {{ formData.images[0].file.name }}
              </div>
            </div>
            <div v-if="validationErrors.images" class="text-sm text-red-500 mt-1">
              {{ validationErrors.images }}
            </div>
          </div>
        </form>
      </div>

      <!-- Footer -->
      <div class="sticky bottom-0 z-10 px-6 py-4 bg-gray-50 border-t border-gray-100 shrink-0 rounded-b-md">
        <CustomButton @click="handleSubmit" :button-text="t('entity.components.videoCreation.modal.submitButton')" icon="movie" :show-icon="true" size="sm" :full-width="true" :is-disabled="!isFormValid"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'
import CustomInput from '@/components/base/inputs/VInput.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { formatFileSize } from '@/utils/common'

const { t } = useI18n()
const emit = defineEmits(['update:modelValue', 'formSubmitted'])
const fileInput = ref(null)

const formData = reactive({
  description: '',
  images: [],
})

const validationErrors = reactive({})

const isFormValid = computed(() => {
  return formData.description.trim() !== ''
})

const handleSubmit = async () => {
  try {
    Object.keys(validationErrors).forEach(key => delete validationErrors[key])

    if (!formData.description.trim()) {
      validationErrors.description = t('entity.components.videoCreation.validation.descriptionRequired')
      responseHandlerService.handleError(t('entity.components.videoCreation.validation.descriptionRequired'))
      return
    }

    // if (formData.images.length === 0) {
    //   validationErrors.images = t('entity.components.videoCreation.validation.imageRequired')
    //   responseHandlerService.handleError(t('entity.components.videoCreation.validation.imageRequired'))
    //   return
    // }

    const formDataObj = new FormData()
    formDataObj.append('request', formData.description.trim())
    if (formData.images.length > 0) formDataObj.append('image1', formData.images[0].file)

    emit('formSubmitted', formDataObj)
  } catch (error) {
    responseHandlerService.handleError(error)
  }
}

const triggerImageUpload = () => {
  fileInput.value.click()
}

const handleImageSelect = (event) => {
  const file = event.target.files[0]
  if (file) handleImageFile(file)
}

const handleImageDrop = (event) => {
  const file = event.dataTransfer.files[0]
  if (file && file.type.startsWith('image/')) handleImageFile(file)
}

const handleImageFile = (file) => {
  const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20MB

  if (file.size > MAX_FILE_SIZE) {
    const { size, unit } = formatFileSize(MAX_FILE_SIZE)
    responseHandlerService.handleWarning(t('common.fileSizeError', { size, unit }))
    return
  }

  const img = new Image()
  const objectUrl = URL.createObjectURL(file)

  img.onload = () => {
    URL.revokeObjectURL(objectUrl) // Bellek sızıntısını önlemek için URL'i serbest bırakıyoruz

    if (img.width < 300 || img.height < 300) {
      responseHandlerService.handleWarning(t('entity.components.videoCreation.validation.imageDimensionError'))
      validationErrors.images = t('entity.components.videoCreation.validation.imageDimensionError')
      return
    }

    const reader = new FileReader()
    reader.onload = (e) => {
      formData.images = [{
        file: file,
        preview: e.target.result,
        width: img.width,
        height: img.height,
      }]
    }
    reader.readAsDataURL(file)
  }

  img.onerror = () => {
    URL.revokeObjectURL(objectUrl)
    responseHandlerService.handleWarning(t('common.imageLoadError'))
  }

  img.src = objectUrl
}

const removeImage = (index) => {
  formData.images = []
}

const handleClose = () => {
  formData.description = ''
  formData.images = []
  Object.keys(validationErrors).forEach(key => delete validationErrors[key])
  emit('update:modelValue', false)
}
</script>