<template>
  <div class="bg-white rounded-xl border">
    <!-- Package Quick Info - New Compact Design -->
    <div class="p-4 bg-gray-50 rounded-t-xl border-b">
      <div class="flex items-center justify-between">
        <div class="flex-1">
          <div class="flex items-center gap-3">
            <h3 class="text-lg font-semibold text-gray-900">
              {{ packageInfo.name }} </h3>
            <span v-if="packageInfo.isPopular" class="bg-primary/10 text-primary px-2 py-0.5 text-xs rounded-full">
              Popular
            </span>
          </div>
          <div class="flex items-center gap-2 mt-1">
            <span class="text-sm text-gray-600">{{ planText }}</span> <span class="text-gray-400">•</span>
            <div class="flex items-center gap-1">
              <img v-if="paymentMethod === 'iyzico'" src="../../../../../../../../../assets/logo/iyzico-logo.png" alt="iyzico" class="h-3 w-auto"/>
              <span class="text-sm text-gray-600">{{ paymentMethodText }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Features Section -->
    <div class="p-6">
      <span class="block text-sm font-medium text-gray-700 mb-4">
        Package Features
      </span>
      <ul class="space-y-3">
        <li v-for="feature in packageInfo.features" :key="feature" class="flex items-start gap-2">
          <span class="material-symbols-outlined text-primary text-base shrink-0">
            check_circle
          </span> <span class="text-sm text-gray-600">{{ feature }}</span>
        </li>
      </ul>
    </div>

    <!-- Price Summary - Simplified -->
    <div class="p-4 bg-gray-50 rounded-b-xl border-t">
      <div class="flex justify-between items-center">
        <span class="font-medium text-gray-900">Total Amount</span>
        <div class="text-right">
          <span class="text-xl font-bold text-primary">
            ₺{{ packageInfo.price }}
          </span> <span class="text-sm text-gray-500 ml-1">
            {{ billingPeriod }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  package: {
    type: Object,
    required: true,
  },
  paymentMethod: {
    type: String,
    default: null,
  },
  formData: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()

const packageInfo = computed(() => props.package || {})
const isValid = computed(() => false)

const planText = computed(() => {
  const plan = packageInfo.value?.plan
  if (!plan) return 'One-time'

  return {
    monthly: 'Monthly Plan',
    annual: 'Annual Plan',
    trial: 'Trial Plan',
    onetime: 'One-time Purchase',
  }[plan]
})

const billingPeriod = computed(() => {
  const plan = packageInfo.value?.plan
  if (!plan || plan === 'onetime') return ''

  return {
    monthly: '/ month',
    annual: '/ year',
    trial: '/ month after trial',
  }[plan]
})

const paymentMethodText = computed(() => {
  if (!props.paymentMethod) return ''
  return t(`assets.components.create.components.payment.methods.${props.paymentMethod}.title`)
})

const validate = async () => {
  return {
    isValid: false,
    errors: {},
  }
}

defineExpose({
  validate,
  isValid,
})
</script>