<template>
  <div class="component-layout bg-gray-50">
    <!-- Welcome Section -->
    <VideoWelcomePage v-if="!showSuccess && !videoLoading" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :variants="{ custom: { transition: { type: 'spring', stiffness: 100, damping: 15 } } }" @start-creating="startCreating"/>

    <!-- Loading Section -->
    <VideoLoadingSection v-else-if="videoLoading" v-motion :initial="{ opacity: 0, scale: 0.9 }" :enter="{ opacity: 1, scale: 1 }" :variants="{ custom: { transition: { type: 'spring', stiffness: 200, damping: 20 } } }" @cancel="handleCancel"/>

    <!-- Success Message Section -->
    <div v-else v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }" :variants="{ custom: { transition: { type: 'spring', stiffness: 150, damping: 15 } } }" class="mt-4">
      <!--      <VideoPreview :video-url="videoUrl" :video-loading="isGenerating" @reset="handleReset"/>-->
      <VideoSuccessMessage @reset="handleReset"/>
    </div>
  </div>

  <!-- Video Creation Modal -->
  <VideoCreationModal v-model="showVideoModal" @formSubmitted="handleFormSubmitted"/>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useAssetStore } from '@/stores/asset'

import VideoWelcomePage from './components/VideoWelcomePage.vue'
import VideoCreationModal from './components/VideoCreationModal.vue'
import VideoLoadingSection from './components/VideoLoadingSection.vue'
import VideoSuccessMessage from './components/VideoSuccessMessage.vue'
import VideoPreview from '@/views/contents/components/newVideoContent/components/VideoPreview.vue'

const { t } = useI18n()
const assetStore = useAssetStore()

const showVideoModal = ref(false)
const showSuccess = ref(false)
const videoLoading = ref(false)
const cancelTokenSource = ref(null)

const startCreating = () => {
  showVideoModal.value = true
}

const handleFormSubmitted = async (formData) => {
  showVideoModal.value = false
  showSuccess.value = false
  videoLoading.value = true

  cancelTokenSource.value = axios.CancelToken.source()

  try {
    formData.append('assetId', assetStore.assetId)

    await axios.post(entity.createVideo, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      cancelToken: cancelTokenSource.value.token,
    })

    showSuccess.value = true
    responseHandlerService.handleSuccess(t('entity.components.videoCreation.messages.success'))
  } catch (error) {
    if (axios.isCancel(error)) responseHandlerService.handleWarning(t('entity.components.videoCreation.messages.cancelled'))
    else responseHandlerService.handleError(error)
  } finally {
    videoLoading.value = false
    cancelTokenSource.value = null
  }
}

const handleCancel = () => {
  if (cancelTokenSource.value) {
    cancelTokenSource.value.cancel()
    cancelTokenSource.value = null
  }
  videoLoading.value = false
  showSuccess.value = false
}

const handleReset = () => {
  showSuccess.value = false
  showVideoModal.value = false
  videoLoading.value = false
}
</script>