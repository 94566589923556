<template>
  <div class="component-layout bg-gray-50">
    <WelcomeSection v-if="!showContentForm && !contentLoading" v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0 }" :variants="{ custom: { transition: { type: 'spring', stiffness: 100, damping: 15 } } }" @start-creating="startCreating"/>

    <LoadingSection v-else-if="contentLoading" v-motion :initial="{ opacity: 0, scale: 0.9 }" :enter="{ opacity: 1, scale: 1 }" :variants="{ custom: { transition: { type: 'spring', stiffness: 200, damping: 20 } } }" @cancel="handleCancel"/>

    <div v-else v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }" :variants="{ custom: { transition: { type: 'spring', stiffness: 150, damping: 15 } } }" class="mt-4">
      <ContentForm :content-detail="generatedContent" :content-loading="contentLoading" @update:contentDetail="updateGeneratedContent" @content-saved="handleContentSaved" @response="handleContentResponse" @requestAiSuggestion="handleAiSuggestion" @reset="handleReset"/>
    </div>
  </div>

  <NewContentModal v-model="showNewContentModal" :is-trend-content="isTrendContent" :trend-keyword="trendKeyword" :trend-url="trendUrl" @formSubmitted="handleFormSubmitted"/>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import NewContentModal from './components/newContentModal.vue'
import ContentForm from './components/ContentForm.vue'
import LoadingSection from './components/LoadingSection.vue'
import WelcomeSection from './components/WelcomeSection.vue'
import { useFeatureStore } from '@/stores/featureStore'

const router = useRouter()
const { t } = useI18n()
const featureStore = useFeatureStore()

const showNewContentModal = ref(false)
const showContentForm = ref(false)
const generatedContent = ref(null)
const contentLoading = ref(false)
const isTrendContent = ref(false)
const trendKeyword = ref('')
const trendUrl = ref('')
const cancelTokenSource = ref(null)
const previousContent = ref(null)

watch(showNewContentModal, (newValue) => {
  if (!newValue && !contentLoading.value && previousContent.value) {
    generatedContent.value = previousContent.value
    showContentForm.value = true
    previousContent.value = null
  }
})

const startCreating = () => {
  showNewContentModal.value = true
}

const updateGeneratedContent = (newContent) => {
  generatedContent.value = newContent
}

const handleAiSuggestion = (field) => {
  previousContent.value = { ...generatedContent.value }
  showNewContentModal.value = true
  isTrendContent.value = false
}

const handleReset = () => {
  previousContent.value = null
  showContentForm.value = false
  generatedContent.value = null
  resetForm()
}

const handleFormSubmitted = async (formData) => {
  showNewContentModal.value = false
  contentLoading.value = true
  previousContent.value = null

  cancelTokenSource.value = axios.CancelToken.source()

  try {
    const endpoint = isTrendContent.value ? entity.createTrendContent : entity.createContent

    const response = await axios.post(endpoint, formData, {
      cancelToken: cancelTokenSource.value.token,
      timeout: 420000,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    handleContentCreated(response.data.data)
    responseHandlerService.handleSuccess(t(`entity.components.newContent.components.newContentModal.responseHandlers.${isTrendContent.value ? 'successCreateTrend' : 'successCreate'}`))
  } catch (err) {
    if (axios.isCancel(err)) responseHandlerService.handleWarning(t('common.requestCancelled'))
    else responseHandlerService.handleError(err || t('common.error'))
  } finally {
    contentLoading.value = false
    cancelTokenSource.value = null
    await featureStore.refreshFeatures()
  }
}


const handleCancel = () => {
  if (cancelTokenSource.value) {
    cancelTokenSource.value.cancel('Operation canceled by the user.')
    cancelTokenSource.value = null
    contentLoading.value = false

    if (previousContent.value) {
      generatedContent.value = previousContent.value
      showContentForm.value = true
      previousContent.value = null
    }
  }
}

const handleContentCreated = (content) => {
  if (content?.contentDetail) {
    generatedContent.value = content.contentDetail
    if (content.entityId) generatedContent.value.entityId = content.entityId
    showContentForm.value = true
  }
}

const handleContentResponse = (response) => {
  if (response?.contentDetail) {
    generatedContent.value = {
      ...generatedContent.value,
      ...response.contentDetail,
    }

    if (response.entityId || response.optimizeId) generatedContent.value.entityId = response.entityId || response.optimizeId

    if (response.data) {
      const { field, data } = response
      if (field === 'image') {
        generatedContent.value.pictureUrls = data.imageUrls || generatedContent.value.pictureUrls
        generatedContent.value.content = data.content || generatedContent.value.content
        generatedContent.value.seoKeywords = data.keywords || generatedContent.value.seoKeywords
      } else if (field && data[field]) {
        generatedContent.value[field] = data[field]
      }
    }
  }
}

const handleContentSaved = () => {
  previousContent.value = null
  showContentForm.value = false
  generatedContent.value = null
  resetForm()
  router.push({ query: { tab: 'contents' } })
}

const resetForm = () => {
  generatedContent.value = null
  showContentForm.value = false
  isTrendContent.value = false
  trendKeyword.value = ''
  trendUrl.value = ''

  router.push({
    name: 'contentModerator',
    query: { tab: 'new-content' },
  })
}

onMounted(() => {
  const { keyword, isTrend, tab } = router.currentRoute.value.query

  if (keyword && isTrend === 'true' && tab === 'new-content') {
    trendKeyword.value = decodeURIComponent(keyword)
    isTrendContent.value = true
    showNewContentModal.value = true
  }
})
</script>