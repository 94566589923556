<template>
  <div class="component-layout">
    <div class="bg-white p-4 rounded-md shadow-md">
      <CustomTable :data="trends" :columns="columns" :isLoading="isLoading" :showFilter="true" :filterOptions="[{
          id: 'source',
          icon: 'filter_alt',
          iconClass: 'material-symbols-outlined text-gray-600 !text-[22px]',
          showText: false,
          items: filterItems
        }]" @filter="handleFilter" min-height="300px" max-height="300px" min-width="900px">
        <!-- Table Header -->
        <template v-slot:table-actions>
          <h1 class="text-base font-semibold mb-2">
            {{ t('dashboard.components.trendKeywords.title') }} </h1>
        </template>

        <!-- Sıra numarası -->
        <template v-slot:number="{ item }">
          <span class="text-gray-600">{{ trends.indexOf(item) + 1 }}</span>
        </template>

        <!-- Anahtar Kelime -->
        <template v-slot:name="{ item }">
          <div class="flex items-center space-x-4 w-full text-sm">
            <p>{{ item.name }}</p>
            <Tooltip :title="getKeywordInfo(item)" :displayCondition="true">
              <template v-slot:content>
                <div class="w-full h-full flex items-center mb-1 cursor-pointer">
                  <span class="material-symbols-outlined !text-[14px] !font-bold !text-primary">?</span>
                </div>
              </template>
            </Tooltip>
          </div>
        </template>

        <!-- Arama Hacmi -->
        <template v-slot:volume="{ item }">
          <div class="flex flex-col items-center w-full">
            <span class="font-medium">{{ formatVolumeDisplay(item.volume) }}</span> <span class="text-xs text-green-600">+{{ item.increasePercentage }}%</span>
          </div>
        </template>

        <!-- Başlangıç -->
        <template v-slot:begining="{ item }">
          <div class="flex flex-col items-center">
            <Tooltip :title="t('dashboard.components.trendKeywords.trend.hint')" :displayCondition="true">
              <template #content>
                <div class="flex flex-col items-center cursor-pointer" @click="toggleTimeDisplay(item)">
                  <div class="flex items-center gap-1">
                    <span class="material-symbols-outlined !text-[14px] text-gray-400">schedule</span> <span class="text-sm text-gray-600">
                      {{ showElapsed[item.name] ? formatTimeDisplay(item.startTimestamp) : formatActualTime(item.startTimestamp) }}
                    </span>
                  </div>
                  <div class="flex items-center gap-1 mt-1">
                    <span v-if="!item.endTimestamp" class="flex items-center">
                      <span class="material-symbols-outlined !text-[14px] text-green-600">trending_up</span>
                      <span class="text-xs text-green-600">{{ t('dashboard.components.trendKeywords.trend.active') }}</span>
                    </span> <span v-else class="flex items-center">
                      <span class="material-symbols-outlined !text-[14px] text-gray-400">timelapse</span>
                      <span class="text-xs text-gray-500">
                        {{ calculateDuration(item.startTimestamp, item.endTimestamp) }}
                        {{ t('dashboard.components.trendKeywords.trend.lasted') }}
                      </span>
                    </span>
                  </div>
                </div>
              </template>
            </Tooltip>
          </div>
        </template>

        <!-- İçerik Butonu -->
        <template v-slot:content="{ item }">
          <div class="flex items-center justify-center">
            <CustomButton :button-text="t('dashboard.components.trendKeywords.createContent')" @click="handleNavigation(item)" size="sm"/>
          </div>
        </template>
      </CustomTable>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import CustomTable from '@/components/table/VTable.vue'
import Tooltip from '@/components/common/VTooltip.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { useRouter } from 'vue-router'
import { useAssetStore } from '@/stores/asset'
import { dateHelper } from '@/utils/dateHelper'

const { t } = useI18n()
const router = useRouter()
const assetStore = useAssetStore()

const trends = ref([])
const isLoading = ref(true)
const showElapsed = ref({})

// Trend keyword types
const TREND_KEYWORD_TYPES = {
  COMPANY_RELATED: 1,
  UNRELATED: 2,
}

// Filter items
const filterItems = computed(() => [
  {
    id: '',
    name: t('dashboard.components.trendKeywords.filters.general'),
    default: true,
  },
  {
    id: TREND_KEYWORD_TYPES.COMPANY_RELATED.toString(),
    name: t('dashboard.components.trendKeywords.filters.assetRelated'),
  },
])

// Table columns
const columns = computed(() => [
  {
    key: 'number',
    label: t('dashboard.components.trendKeywords.columns.number'),
    width: 'w-1/12',
  },
  {
    key: 'name',
    label: t('dashboard.components.trendKeywords.columns.keywords'),
    width: 'w-3/12',
  },
  {
    key: 'volume',
    label: t('dashboard.components.trendKeywords.columns.volume'),
    width: 'w-2/12',
    align: 'center',
  },
  {
    key: 'begining',
    label: t('dashboard.components.trendKeywords.columns.begining'),
    width: 'w-2/12',
    align: 'center',
  },
  {
    key: 'content',
    label: t('dashboard.components.trendKeywords.columns.content'),
    width: 'w-2/12',
    align: 'center',
  },
])

// Methods
const handleFilter = (selectedFilter) => {
  let type = TREND_KEYWORD_TYPES.UNRELATED

  if (selectedFilter?.source) {
    const typeId = parseInt(selectedFilter.source)
    if (!isNaN(typeId)) type = typeId
  }

  fetchTrends(type)
}

const formatVolumeDisplay = (volume) => {
  if (volume >= 1000000) return `${(volume / 1000000).toFixed(1)}M+`
  if (volume >= 1000) return `${(volume / 1000).toFixed(1)}K+`
  if (volume >= 500) return `500B+`
  return `${volume}+`
}

const formatTimeDisplay = (timestamp) => {
  if (!timestamp) return '-'

  const start = dateHelper.parse(timestamp * 1000)
  const now = dateHelper.now()
  const diffInHours = dateHelper.diff(now, start, 'hour')

  if (diffInHours < 1) {
    const diffInMinutes = dateHelper.diff(now, start, 'minute')
    return t('dashboard.components.trendKeywords.minutesAgo', { minutes: diffInMinutes })
  }
  if (diffInHours < 24) {
    return t('dashboard.components.trendKeywords.hoursAgo', { hours: diffInHours })
  }

  const diffInDays = Math.floor(diffInHours / 24)
  return t('dashboard.components.trendKeywords.daysAgo', { days: diffInDays })
}

const getKeywordInfo = (item) => {
  return `${t('dashboard.components.trendKeywords.volume')}: ${formatVolumeDisplay(item.volume)}
${t('dashboard.components.trendKeywords.increase')}: +${item.increasePercentage}%`
}

const fetchTrends = async (trendKeywordType = TREND_KEYWORD_TYPES.UNRELATED) => {
  isLoading.value = true
  try {
    const response = await axios.get(dashboard.getTrendKeywords, {
      params: {
        assetId: assetStore.assetId,
        trendKeywordType,
      },
    })
    trends.value = response.data.data
  } catch (error) {
    console.error('Error fetching keyword trends:', error)
  } finally {
    isLoading.value = false
  }
}

const toggleTimeDisplay = (item) => {
  showElapsed.value[item.name] = !showElapsed.value[item.name]
}

const formatActualTime = (timestamp) => {
  if (!timestamp) return '-'
  return dateHelper.formatLocalized(timestamp * 1000, 'long', { type: 'datetime' })
}

const calculateDuration = (start, end) => {
  if (!start || !end) return ''
  const duration = dateHelper.diff(end * 1000, start * 1000, 'minute')
  if (duration < 60) return `${Math.floor(duration)} dk.`
  return `${Math.floor(duration / 60)} sa.`
}

const handleNavigation = (item) => {
  router.push({
    name: 'contentModerator',
    query: {
      tab: 'new-content',
      keyword: item.name,
      isTrend: true,
    },
  })
}

onMounted(() => {
  fetchTrends(TREND_KEYWORD_TYPES.UNRELATED)
})
</script>