<template>
  <component :is="currentStepComponent" v-bind="stepProps"/>
</template>

<script setup>
import { computed, markRaw, defineAsyncComponent } from 'vue'
import { setupSteps } from './config'

const props = defineProps({
  step: {
    type: String,
    required: true,
    validator: (value) => !!setupSteps[value],
  },
})

const currentStepComponent = computed(() => {
  const component = setupSteps[props.step].component
  return component ? markRaw(defineAsyncComponent(component)) : null
})

const stepProps = computed(() => ({
  stepSlug: props.step,
  stepOrder: setupSteps[props.step].order,
}))
</script>