<template>
  <div class="bg-slate-50 rounded-lg p-4">
    <h4 class="font-medium text-sm mb-2 capitalize">
      {{ t(`entity.components.contentReview.${item.key === 'title' ? 'titleScore' : item.key}`) }} </h4>
    <div class="flex items-center gap-2 mb-2">
      <div class="h-2 w-20 bg-gray-200 rounded-full overflow-hidden">
        <div class="h-full bg-primary rounded-full" :style="{ width: `${calculatePercentage}%` }"/>
      </div>
      <span class="text-sm text-slate-600">{{ item.data.score }}/{{ maxScore }}</span>
    </div>
    <p class="text-sm text-slate-600">{{ item.data.message }}</p>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  item: Object,
})

const maxScore = computed(() => {
  const maxScores = {
    title: 10,
    metaDescription: 5,
    metaKeywords: 5,
    headings: 10,
    contentStructure: 10,
    schema: 10,
    faq: 10,
    eat: 10,
    imageOptimization: 15,
    internalLinks: 10,
    externalLinks: 5,
  }

  return maxScores[props.item.key] || 10
})

const calculatePercentage = computed(() => {
  return (props.item.data.score / maxScore.value) * 100
})
</script>