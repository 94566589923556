<template>
  <div class="layout-spacing">
    <!-- Header -->
    <div class="mb-8 bg-white p-6 rounded-md shadow-md">
      <h1 class="text-2xl font-semibold text-gray-900">{{ currentStepTitle }}</h1>
      <p class="mt-2 text-sm text-gray-600">{{ currentStepDescription }}</p>
    </div>

    <!-- Step content -->

    <router-view ref="currentStepRef"></router-view>

    <!-- Navigation buttons -->
    <div class="mt-8" :class="{'flex justify-end': !previousStep, 'flex justify-between': previousStep}">
      <VButton v-if="previousStep" variation="outline" color="neutral" :button-text="$t('common.previous')" @click="goToPreviousStep"/>

      <VButton v-if="nextStep" color="primary" :button-text="$t('common.next')" :loading-text="$t('general.loading')" :is-loading="loading" @click="goToNextStep"/>

      <VButton v-else color="accent" :button-text="$t('common.complete')" :loading-text="$t('common.processing')" :is-loading="loading" :is-disabled="loading" @click="completeSetup"/>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAssetStore } from '@/stores/asset'
import { setupSteps, getNextStep, getPreviousStep } from './config'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import VButton from '@/components/base/buttons/VButton.vue'

const router = useRouter()
const route = useRoute()
const assetStore = useAssetStore()

const currentStepRef = ref(null)
const loading = ref(false)

const currentStep = computed(() => route.params.step)
const nextStep = computed(() => getNextStep(currentStep.value))
const previousStep = computed(() => getPreviousStep(currentStep.value))

const currentStepTitle = computed(() => setupSteps[currentStep.value].title)
const currentStepDescription = computed(() => setupSteps[currentStep.value].description)

const goToNextStep = async () => {
  // Önce current step'in save metodunu çalıştır
  if (currentStepRef.value?.save) {
    const success = await currentStepRef.value.save()
    if (!success) return // save başarısız olduysa sonraki adıma geçme
  }

  // Save başarılıysa sonraki adıma geç
  if (nextStep.value) {
    await router.push({
      name: 'setupStep',
      params: { step: nextStep.value },
    })
  }
}

const goToPreviousStep = () => {
  if (previousStep.value) {
    router.push({
      name: 'setupStep',
      params: { step: previousStep.value },
    })
  }
}

const completeSetup = async () => {
  // Önce son adımın save'ini çalıştır
  if (currentStepRef.value?.save) {
    const success = await currentStepRef.value.save()
    if (!success) return
  }

  loading.value = true
  try {
    // Setup'ı tamamla
    await axios.post(assets.changeAssetSetup, {
      assetId: assetStore.assetId,
    })

    // Başarılıysa CheckPhase'i kapat ve assets'e yönlendir
    await assetStore.exitCheckPhase()
    responseHandlerService.handleSuccess('Setup başarıyla tamamlandı')
    await router.push({ name: 'assets' })

  } catch (error) {
    responseHandlerService.handleError(error)
  } finally {
    loading.value = false
  }
}
</script>