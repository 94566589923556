<template>
  <div class="relative" data-menu="socialShare" v-click-outside="handleClickOutside">
    <!-- Trigger Button -->
    <CustomButton @click="toggleMenu('socialShare')" icon="share" :button-text="buttonText || t('entity.components.contentReview.shareButton')" :color="buttonColor || 'secondary'" variation="outline" size="sm" :show-icon="true" :is-loading="loading"/>

    <!-- Share Menu Dropdown -->
    <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
      <div v-if="menuStore.isOpen('socialShare')" class="absolute right-0 mt-2 w-72 bg-white rounded-lg shadow-xl z-50 border border-slate-200">
        <!-- Loading State -->
        <div v-if="loading" class="p-4">
          <div class="flex flex-col items-center justify-center space-y-2">
            <div class="material-symbols-outlined animate-spin text-primary">progress_activity</div>
            <p class="text-sm text-gray-600">{{ t('entity.components.shareMenu.preparingContent') }}</p>
          </div>
        </div>

        <!-- Content State -->
        <template v-else>
          <!-- Share Menu Content -->
          <div class="p-4 border-b border-slate-100">
            <h3 class="font-medium text-slate-900 text-sm">{{ t('entity.components.shareMenu.title') }}</h3>
            <div class="mt-2.5">
              <button @click="openPreviewModal" class="w-full rounded-lg border border-gray-100 p-3 hover:bg-gray-50 transition-colors">
                <div class="flex items-center gap-2 mb-1.5">
                  <span class="material-symbols-outlined !text-[18px] text-primary">visibility</span> <span class="text-sm font-medium text-gray-900">{{
                    t('entity.components.shareMenu.customShare')
                  }}</span>
                </div>
                <span class="text-xs text-gray-500 text-left font-light leading-relaxed">
                  {{ t('entity.components.shareMenu.customShareDescription') }}
                </span>
              </button>
            </div>
          </div>

          <div v-if="platforms.length > 0" class="py-1.5">
            <button v-for="platform in platforms" :key="platform.name" @click="shareToSocial(platform)" class="w-full px-4 py-2.5 text-left hover:bg-slate-50 flex items-center gap-2 transition-colors">
              <span class="material-symbols-outlined !text-[18px]">{{ platform.icon }}</span> <span class="text-sm font-light text-gray-700">{{ platform.label }}</span>
            </button>
          </div>
        </template>
      </div>
    </transition>

    <!-- Preview Modal -->
    <PreviewContentModal v-if="showPreviewModal" v-model="showPreviewModal" :title="title" :content="content" :image="image" :url="url" :hashtags="hashtags"/>
  </div>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMenuStore } from '@/stores/menuStore'
import CustomButton from '@/components/base/buttons/VButton.vue'
import PreviewContentModal from '../shareOnSocialMedia/components/previewModal.vue'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { safeParse } from '@/utils/common'

const props = defineProps({
  title: String,
  content: String,
  hashtags: {
    type: Array,
    default: () => [],
  },
  url: String,
  image: String,
  buttonText: String,
  buttonColor: {
    type: String,
    default: 'secondary',
  },
  entityId: {
    type: Number,
    default: 0,
  },
  assetId: {
    type: Number,
    default: 0,
  },
  isSocialMediaContentReady: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close', 'update:socialMediaContent'])
const { t } = useI18n()
const menuStore = useMenuStore()
const showPreviewModal = ref(false)
const loading = ref(false)

// Click outside handler
const handleClickOutside = (event) => {
  const isClickInsideAnyMenu = event.target.closest('[data-menu]')
  if (!isClickInsideAnyMenu) {
    menuStore.closeAll()
  }
}

// Methods
const toggleMenu = async (menuId) => {
  menuStore.toggleMenu(menuId)
  if (!props.isSocialMediaContentReady) {
    loading.value = true
    try {
      const res = await axios.post(entity.createSocialmediaContent, {
        entityId: props.entityId,
        assetId: props.assetId,
      })
      const socialMedia = res.data.data

      emit('update:socialMediaContent', {
        socialMediaContent: socialMedia.socialMediaContent,
        socialMediaHashtags: safeParse(socialMedia.socialMediaHashtags, null, 'array') || [],
      })

      await new Promise(resolve => setTimeout(resolve, 500))
    } catch (error) {
      responseHandlerService.handleError(error, t('entity.components.shareMenu.errorCreating'))
      menuStore.closeMenu(menuId)
    } finally {
      loading.value = false
    }
  }
}


const closeMenu = () => {
  menuStore.closeMenu('socialShare')
  emit('close')
}

const openPreviewModal = () => {
  closeMenu()
  nextTick(() => {
    showPreviewModal.value = true
  })
}

const shareToSocial = (platform) => {
  const shareUrl = platform.shareUrl()
  const width = 600
  const height = 400
  const left = window.innerWidth / 2 - width / 2
  const top = window.innerHeight / 2 - height / 2

  window.open(
      shareUrl,
      'share-dialog',
      `width=${width},height=${height},left=${left},top=${top}`,
  )
  closeMenu()
}

const platforms = computed(() => [
  // {
  //   name: 'twitter',
  //   label: t('entity.components.shareMenu.twitter'),
  //   icon: 'send',
  //   shareUrl: () => {
  //     const text = props.title
  //     const hashtagStr = props.hashtags.map(h => h.replace(/\s+/g, '')).join(',')
  //     return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}${props.url ? `&url=${encodeURIComponent(props.url)}` : ''}&hashtags=${encodeURIComponent(hashtagStr)}`
  //   },
  // },
])
</script>