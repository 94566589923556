<template>
  <div class="flex flex-col">
    <!-- Label and Description -->
    <div v-if="label || description" class="mb-2">
      <div class="flex items-center space-x-2 mb-1">
        <div :class="['flex items-center', labelIconPosition === 'left' ? 'flex-row' : 'flex-row-reverse']">
          <icon-component v-if="labelIconPosition === 'left' && labelIcon" :icon="labelIcon" :class="[labelIconClass, 'mr-1', iconClickableClass]" @click="emitIconClick"/>
          <label v-if="label" :for="id" :class="[labelClass, additionalLabelClass]" class="text-sm"> {{ label }}<span v-if="required" class="text-primary">*</span> </label>
          <icon-component v-if="labelIconPosition === 'right' && labelIcon" :icon="labelIcon" :class="[labelIconClass, 'ml-1', iconClickableClass]" @click="emitIconClick"/>
        </div>
        <img v-if="showAiIcon" :src="aiStarIcon" class="cursor-pointer w-4 hover:opacity-70 transition-opacity" @click="emitIconClick" alt="star icon"/>
      </div>
      <p v-if="description" :class="[descriptionClass, additionalDescriptionClass]" class="text-xs mb-1">{{ description }}</p>
    </div>

    <!-- Dropdown Component -->
    <div class="relative w-full custom-dropdown" v-click-outside="onClickOutside" @keydown.esc="closeDropdown">
      <div class="flex w-full">
        <div :style="dynamicStyle" class="relative flex-grow">
          <div :id="id" @click="handleClick" ref="triggerRef" tabindex="0" :class="[
                 'w-full bg-white text-left border rounded-md shadow-sm focus:outline-none focus:ring-1 flex items-center justify-between cursor-pointer',
                 sizeClasses,
                 {
                   'rounded-r-none': showButton,
                   'cursor-not-allowed opacity-60 bg-gray-100': disabled,
                   'border-red-500 focus:border-red-500 focus:ring-red-500': error,
                   'border-gray-300 focus:ring-primary focus:border-primary': !error
                 }
               ]">
            <div class="flex-grow overflow-hidden">
              <span v-if="loading" class="text-gray-400">{{ t('globalComponents.customDropdown.loading') }}</span> <span v-else class="block truncate">{{ selectedOptionLabel }}</span>
            </div>
            <span v-if="loading" class="material-symbols-outlined text-gray-400 animate-spin ml-2">progress_activity</span> <span v-else class="material-symbols-outlined text-gray-400 ml-2">
              {{ isOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}
            </span>
          </div>
        </div>

        <button v-if="showButton" @click.stop="onButtonClick" :disabled="disabled" :class="[
                  'bg-primary text-white px-3 rounded-r-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary flex items-center',
                  sizeClasses,
                  {
                    'hover:bg-primary-dark': !disabled,
                    'opacity-60 cursor-not-allowed': disabled
                  }
                ]">
          <span class="material-symbols-outlined">{{ buttonIcon }}</span>
        </button>
      </div>

      <div v-show="isOpen && !loading && !disabled" ref="floatingRef" :style="floatingStyles" class="floating-dropdown absolute z-[9999] bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" @click.stop>
        <div v-if="showSearch" class="px-2 py-1">
          <CustomInput v-model="searchQuery" type="text" :placeholder="t('globalComponents.customDropdown.search')" size="sm" :containerClass="'mb-0'" :additionalInputClass="'!py-1.5'" icon="search" :show-icon="true" :show-error="false"/>
        </div>
        <ul class="overflow-auto thin-scrollbar" :style="dropdownListStyles">
          <li v-for="option in filteredOptions" :key="option.value" @click.stop="selectOption(option.value)" class="cursor-pointer select-none relative py-2 pl-3 pr-9 group" :class="{
                'bg-primary-light': isSelected(option.value),
                'hover:bg-gray-100': !isSelected(option.value)
              }">
            <div class="flex items-center">
              <input v-if="multiSelect" type="checkbox" :checked="isSelected(option.value)" class="mr-2" @click.stop/> <span class="block truncate" :class="{
                'font-semibold text-primary': isSelected(option.value),
                'font-normal group-hover:text-gray-900': !isSelected(option.value)
              }">
                {{ option.label }}
              </span>
            </div>
            <span v-if="isSelected(option.value)" class="absolute inset-y-0 right-0 flex items-center pr-4 text-primary">
              <span class="material-symbols-outlined text-primary">check</span>
            </span>
          </li>
        </ul>
      </div>

      <!-- Error Message -->
      <div v-if="showError" class="h-3">
        <div v-if="error" class="flex items-start gap-1">
          <span class="material-symbols-outlined text-red-500 !text-[14px] mt-0.5">error</span>
          <p class="text-xs text-red-500">{{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import { computePosition, flip, shift, offset, size } from '@floating-ui/dom'
import IconComponent from '@/components/common/VIcon.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'

const props = defineProps({
  // Label and description props
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  required: { type: Boolean, default: false },
  error: { type: String, default: '' },
  showError: { type: Boolean, default: true },
  labelClass: { type: String, default: 'font-semibold text-secondary' },
  descriptionClass: { type: String, default: 'text-gray-600' },
  additionalDescriptionClass: { type: String, default: '' },
  additionalLabelClass: { type: String, default: '' },
  showAiIcon: { type: Boolean, default: false },
  labelIcon: { type: String, default: '' },
  labelIconClass: { type: String, default: 'text-primary !text-[20px]' },
  labelIconPosition: {
    type: String,
    default: 'left',
    validator: (value) => ['left', 'right'].includes(value),
  },
  iconClickable: { type: Boolean, default: false },

  // Dropdown specific props
  options: {
    type: Array,
    default: () => [],
  },
  modelValue: [String, Array, Number],
  showButton: {
    type: Boolean,
    default: false,
  },
  buttonIcon: {
    type: String,
    default: 'add',
  },
  showSearch: {
    type: Boolean,
    default: false,
  },
  multiSelect: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
  minWidth: {
    type: String,
    default: '',
  },
  maxWidth: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    default: '',
  },
})

const { t } = useI18n()
const emit = defineEmits(['update:modelValue', 'buttonClick', 'search', 'iconClicked'])

// Refs
const isOpen = ref(false)
const searchQuery = ref('')
const id = `dropdown-${Math.random().toString(36).substr(2, 9)}`
const triggerRef = ref(null)
const floatingRef = ref(null)
const aiStarIcon = require('@/assets/icons/aiStar.png')
const placement = ref('bottom-start')
const availableSpace = ref({ above: 0, below: 0 })

const floatingStyles = ref({
  position: 'absolute',
  top: '0',
  left: '0',
  width: 'auto',
  visibility: 'hidden',
})

const dropdownListStyles = computed(() => {
  const maxHeight = Math.min(300, Math.max(availableSpace.value.above, availableSpace.value.below) - 20)
  return {
    maxHeight: `${maxHeight}px`,
  }
})

// Computed Properties
const selectedOptionLabel = computed(() => {
  if (!props.options?.length) return t('globalComponents.customDropdown.selectAnOption')

  if (props.multiSelect) {
    const selectedOptions = props.options.filter(option => props.modelValue?.includes(option.value))
    return selectedOptions.length > 0
        ? selectedOptions.map(o => o.label).join(', ')
        : t('dropdown.selectOptions')
  } else {
    const selectedOption = props.options.find(option => option.value === props.modelValue)
    return selectedOption ? selectedOption.label : t('globalComponents.customDropdown.selectAnOption')
  }
})

const dynamicStyle = computed(() => ({
  minWidth: props.minWidth || '',
  maxWidth: props.maxWidth || '',
  width: props.width || '',
}))

const filteredOptions = computed(() => {
  if (!props.options?.length) return []
  if (!searchQuery.value) return props.options

  return props.options.filter(option =>
      option.label?.toString().toLowerCase().includes((searchQuery.value || '').toLowerCase()),
  )
})

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'py-1 px-2 text-sm h-8'
    case 'lg':
      return 'py-3 px-4 text-lg h-12'
    default:
      return 'py-2 px-3 text-base h-10'
  }
})

const iconClickableClass = computed(() => {
  return props.iconClickable ? 'cursor-pointer hover:text-primary transition-colors' : ''
})

// Methods
const calculateAvailableSpace = () => {
  if (!triggerRef.value) return

  const triggerRect = triggerRef.value.getBoundingClientRect()
  const modalContent = document.querySelector('.vfm__content')
  const viewportHeight = modalContent ? modalContent.clientHeight : window.innerHeight

  if (modalContent) {
    const modalRect = modalContent.getBoundingClientRect()
    availableSpace.value = {
      above: triggerRect.top - modalRect.top,
      below: modalRect.bottom - triggerRect.bottom,
    }
  } else {
    availableSpace.value = {
      above: triggerRect.top,
      below: viewportHeight - triggerRect.bottom,
    }
  }
}

const updatePosition = async () => {
  if (!triggerRef.value || !floatingRef.value) return

  calculateAvailableSpace()

  try {
    const middleware = [
      offset(4),
      flip({
        fallbackPlacements: ['top-start', 'bottom-start'],
        padding: 8,
      }),
      shift({ padding: 8 }),
      size({
        apply({ availableHeight, elements }) {
          // Get the actual content height
          const contentHeight = elements.floating.scrollHeight
          // Calculate the maximum height based on available space and content
          const maxHeight = Math.min(
              contentHeight + 8, // Add padding
              Math.max(availableSpace.value.above, availableSpace.value.below) - 20,
          )

          Object.assign(elements.floating.style, {
            maxHeight: `${maxHeight}px`,
          })
        },
        padding: 8,
      }),
    ]

    const { x, y, placement: newPlacement, strategy } = await computePosition(
        triggerRef.value,
        floatingRef.value,
        {
          placement: placement.value,
          middleware,
        },
    )

    placement.value = newPlacement

    const triggerRect = triggerRef.value.getBoundingClientRect()

    floatingStyles.value = {
      position: strategy,
      top: `${y}px`,
      left: `${x}px`,
      width: `${triggerRect.width}px`,
      visibility: 'visible',
    }
  } catch (error) {
    console.error('Error updating position:', error)
  }
}

const handleClick = async (event) => {
  if (!props.disabled && !props.loading) {
    isOpen.value = !isOpen.value
    if (isOpen.value) {
      await nextTick()
      await updatePosition()
      window.addEventListener('scroll', updatePosition, true)
      window.addEventListener('resize', updatePosition)
    } else {
      window.removeEventListener('scroll', updatePosition, true)
      window.removeEventListener('resize', updatePosition)
    }
  }
}

const selectOption = (value) => {
  if (props.multiSelect) {
    const newValue = props.modelValue?.includes(value)
        ? props.modelValue.filter(v => v !== value)
        : [...(props.modelValue || []), value]
    emit('update:modelValue', newValue)
  } else {
    emit('update:modelValue', value)
    isOpen.value = false
  }
}

const isSelected = (value) => {
  return props.multiSelect
      ? props.modelValue?.includes(value)
      : props.modelValue === value
}

const onClickOutside = () => {
  if (isOpen.value) {
    isOpen.value = false
    window.removeEventListener('scroll', updatePosition, true)
    window.removeEventListener('resize', updatePosition)
  }
}

const closeDropdown = () => {
  if (isOpen.value) {
    isOpen.value = false
    window.removeEventListener('scroll', updatePosition, true)
    window.removeEventListener('resize', updatePosition)
  }
}

const onButtonClick = () => {
  if (!props.disabled) emit('buttonClick')
}

const onSearchInput = (value) => {
  searchQuery.value = typeof value === 'string' ? value : ''
  emit('search', searchQuery.value)
}

const emitIconClick = () => {
  emit('iconClicked')
}

// Watchers
watch(() => props.modelValue, (newValue) => {
  if (!newValue && props.multiSelect) emit('update:modelValue', [])
})

// Watch for changes in filtered options
watch(filteredOptions, async () => {
  if (isOpen.value) {
    await nextTick()
    await updatePosition()
  }
})

// Watch for changes in search query
watch(searchQuery, async () => {
  if (isOpen.value) {
    await nextTick()
    await updatePosition()
  }
})

onMounted(() => {
  window.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') closeDropdown()
  })
})

// Lifecycle Hooks
onBeforeUnmount(() => {
  window.removeEventListener('scroll', updatePosition, true)
  window.removeEventListener('resize', updatePosition)
  window.removeEventListener('keydown', (e) => {
    if (e.key === 'Escape') closeDropdown()
  })
})
</script>

<style scoped>
.custom-dropdown {
  position: relative;
}

</style>