import { errorMessages } from './errorMessages'

class ResponseHandlerService {
  constructor() {
    this.errorHandler = null
    this.successHandler = null
    this.warningHandler = null
    this.unauthorizedSnackbarDisplayed = false
    this.connectionErrorDisplayed = false
  }

  setErrorHandler(handler) {
    this.errorHandler = handler
  }

  setSuccessHandler(handler) {
    this.successHandler = handler
  }

  setWarningHandler(handler) {
    this.warningHandler = handler
  }

  _isConnectionError(error) {
    return (
        error?.isConnectionError ||
        !error?.response ||
        error?.code === 'ECONNABORTED' ||
        error?.message?.includes('Network Error')
    )
  }

  _extractMessage(data, fallbackMessage = null) {
    if (!data) return fallbackMessage

    if (data?.response?.data?.message) {
      return data.response.data.message
    }

    if (typeof data === 'string') return data

    if (data?.message) return data.message

    return fallbackMessage
  }

  _isUnauthorizedError(error) {
    return error?.response?.status === 401
  }

  resetUnauthorizedFlag() {
    this.unauthorizedSnackbarDisplayed = false
  }

  resetConnectionErrorFlag() {
    if (this.connectionErrorDisplayed) {
      this.connectionErrorDisplayed = false
    }
  }

  handleError(error, fallbackMessage = 'Bir hata oluştu.') {
    if (!this.errorHandler) {
      console.error('No error handler provided!')
      return
    }

    // Check for connection error first
    if (this._isConnectionError(error)) {
      if (!this.connectionErrorDisplayed) {
        this.connectionErrorDisplayed = true
        const message = error?.message || errorMessages.get('connectionError')
        this.errorHandler(message)
      }
      return
    }

    // If connection error is displayed, don't show other errors
    if (this.connectionErrorDisplayed) {
      return
    }

    // Handle unauthorized errors
    if (this._isUnauthorizedError(error)) {
      if (!this.unauthorizedSnackbarDisplayed) {
        this.unauthorizedSnackbarDisplayed = true
        const message = this._extractMessage(error, errorMessages.get('sessionExpired'))
        this.errorHandler(message)
      }
      return
    }

    const message = this._extractMessage(error, fallbackMessage)
    this.errorHandler(message)
  }

  handleSuccess(message, fallbackMessage = 'İşlem başarıyla tamamlandı.') {
    if (!this.successHandler || this.connectionErrorDisplayed) {
      return
    }

    const extractedMessage = this._extractMessage(message, fallbackMessage)
    this.successHandler(extractedMessage)
  }

  handleWarning(message, fallbackMessage = 'Uyarı mesajı.') {
    if (!this.warningHandler || this.connectionErrorDisplayed) {
      return
    }

    const extractedMessage = this._extractMessage(message, fallbackMessage)
    this.warningHandler(extractedMessage)
  }
}

export const responseHandlerService = new ResponseHandlerService()