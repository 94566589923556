<template>
  <div class="flex flex-col space-y-6 w-full">
    <div class="bg-white p-6 relative min-h-[500px]">
      <h2 class="text-xl font-bold text-secondary mb-6">
        {{ t('assets.components.create.components.packageSelection.title') }} </h2>

      <!-- Main Loading State -->
      <template v-if="loading">
        <div class="absolute inset-0 bg-white flex items-center justify-center">
          <customLoading :loading="true" :showLoadingText="true" :loadingText="t('assets.components.create.components.packageSelection.loading')"/>
        </div>
      </template>

      <!-- Main Content -->
      <template v-else>
        <!-- Error Messages -->
        <div v-if="Object.keys(errors).length > 0" class="mb-6">
          <div v-for="(error, key) in errors" :key="key" class="text-red-500 text-sm mb-2">
            {{ error }}
          </div>
        </div>

        <div v-if="displayPackages.subscription.length || displayPackages.onetime.length">
          <!-- Trial Banner -->
          <div v-if="isTrialAvailable" class="mb-8 bg-gradient-to-r from-primary/10 to-secondary/10 rounded-xl p-6 border-2" :class="{'border-primary': selectedPackage?.id === 1}">
            <div class="flex items-start space-x-4">
              <span class="material-symbols-outlined text-primary text-3xl mt-1">star</span>
              <div>
                <h3 class="text-lg font-bold text-gray-900 mb-2">
                  {{ t('assets.components.create.components.packageSelection.trial.title') }} </h3>
                <p class="text-sm text-gray-600">
                  {{ t('assets.components.create.components.packageSelection.trial.subtitle') }} </p>
                <customButton class="mt-4" :button-text="selectedPackage?.id !== 1 ? t('assets.components.create.components.packageSelection.trial.button') : t('assets.components.create.components.packageSelection.trial.activeButton')" color="primary" size="sm" :is-disabled="selectedPackage?.id === 1" :additional-button-class="selectedPackage?.id === 1 ? 'ring-2 ring-primary/20' : ''" @click="handlePackageSelect('1')"/>
              </div>
            </div>
          </div>

          <!-- Package Grid -->
          <div v-if="activeTab === 'subscription'" class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div v-for="pkg in displayPackages.subscription" :key="pkg.id" class="relative border-2 rounded-xl transition-all duration-300 hover:shadow-lg" :class="getPackageClasses(pkg.id)">
              <!-- Package Content -->
              <div class="p-6 cursor-pointer" @click="handlePackageSelect(pkg)">
                <div class="flex justify-between items-start mb-4">
                  <div>
                    <h3 class="text-lg font-bold text-gray-900">{{ pkg.name }}</h3>
                    <p class="text-sm text-gray-500 mt-1">{{ pkg.description }}</p>
                  </div>
                  <div v-if="pkg.isPopular" class="bg-primary text-white px-3 py-1 rounded-full text-sm font-medium">
                    {{ t('assets.components.create.components.packageSelection.popular') }}
                  </div>
                </div>

                <!-- Pricing Plans -->
                <div class="space-y-4">
                  <!-- Monthly Plan -->
                  <div class="border rounded-lg p-4 cursor-pointer transition-colors" :class="getPlanClasses('monthly', pkg.id)" @click.stop="handlePlanSelect('monthly', pkg)">
                    <div class="flex justify-between items-center mb-2">
                      <span class="font-medium text-sm">
                        {{ t('assets.components.create.components.packageSelection.pricing.monthly') }}
                      </span>
                      <div class="text-right">
                        <span class="text-xl font-bold">{{ formatPrice(pkg.monthlyPrice) }}</span>
                        <span class="text-sm text-gray-500">/{{ t('assets.components.create.components.packageSelection.pricing.month') }}</span>
                      </div>
                    </div>
                    <p class="text-sm text-gray-500">{{ pkg.monthlyFeatures }}</p>
                  </div>

                  <!-- Annual Plan -->
                  <div class="border rounded-lg p-4 cursor-pointer transition-colors" :class="getPlanClasses('annual', pkg.id)" @click.stop="handlePlanSelect('annual', pkg)">
                    <div class="flex justify-between items-center mb-2">
                      <div>
                        <span class="font-medium text-sm">
                          {{ t('assets.components.create.components.packageSelection.pricing.annual') }}
                        </span> <span class="ml-2 bg-green-100 text-green-800 px-2 py-0.5 rounded text-xs">
                          {{ t('assets.components.create.components.packageSelection.pricing.save') }}
                        </span>
                      </div>
                      <div class="text-right">
                        <span class="text-xl font-bold">{{ formatPrice(pkg.annualPrice) }}</span>
                        <span class="text-sm text-gray-500">/{{ t('assets.components.create.components.packageSelection.pricing.year') }}</span>
                      </div>
                    </div>
                    <p class="text-sm text-gray-500">{{ pkg.annualFeatures }}</p>
                  </div>
                </div>

                <!-- Features -->
                <div class="mt-6 space-y-3">
                  <div v-for="(feature, index) in pkg.features" :key="index" class="flex items-center">
                    <span class="material-symbols-outlined text-primary mr-2">check_circle</span> <span class="text-sm text-gray-600">{{ feature }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center text-gray-500 py-8">
          {{ t('assets.components.create.components.packageSelection.noPackages') }}
        </div>
      </template>

      <!-- Validation Loading Overlay -->
      <div v-if="validationLoading" class="absolute inset-0 bg-white/50 backdrop-blur-sm flex items-center justify-center z-50">
        <div class="flex flex-col items-center space-y-4">
          <customLoading :loading="true" :showLoadingText="true" :loadingText="t('assets.components.create.components.packageSelection.packageSelecting')"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import { assets, packages as packageUrls } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import customButton from '@/components/base/buttons/VButton.vue'
import customLoading from '@/components/loading/VLoading.vue'

const emit = defineEmits(['stepValidation'])
const assetStore = useAssetStore()
const { t } = useI18n()

// State Management
const loading = ref(true)
const validationLoading = ref(false)
const errors = ref({})
const activeTab = ref('subscription')
const isTrialAvailable = ref(true)

// Price formatting function
const formatPrice = (price) => {
  if (!price) return '0 ₺'

  const [integerPart, decimalPart] = price.toString().split('.')

  const formattedInteger = new Intl.NumberFormat('tr-TR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(integerPart))

  return decimalPart ? `${formattedInteger},${decimalPart} ₺` : `${formattedInteger} ₺`
}

// Default Data
const defaultPackages = {
  subscription: [
    {
      id: 2,
      name: 'Professional 101',
      description: 'Designed for businesses that require consistent content creation support to fuel growth with 2 websites for crawling at maximum',
      isPopular: false,
      monthlyPrice: 900,
      annualPrice: 7200,
      monthlyFeatures: '20 Lobs per month + 5% discount on additional Lobs',
      annualFeatures: '20 Lobs per month + 20% discount on additional Lobs',
      features: [
        '2 websites for crawling',
        'Basic calendar service',
        'SEO optimization tools',
        '24/7 support access',
      ],
      planId: {
        monthly: 7,
        annual: 8,
      },
    },
    {
      id: 3,
      name: 'Professional 102',
      description: 'An ideal choice for businesses with high content demands and a need for scalable solutions',
      isPopular: true,
      monthlyPrice: 1800,
      annualPrice: 14400,
      monthlyFeatures: '50 Lobs per month + 5% discount on additional Lobs',
      annualFeatures: '50 Lobs per month + 20% discount on additional Lobs',
      features: [
        '4 websites for crawling',
        'Advanced calendar service',
        'Premium SEO tools',
        'Priority support',
      ],
      planId: {
        monthly: 9,
        annual: 10,
      },
    },
  ],
  onetime: [],
}

const apiPackages = ref({
  subscription: [],
  onetime: [],
})

// Computed Properties
const selectedPackage = computed(() => assetStore.assetData.selectedPackage)

const displayPackages = computed(() => ({
  subscription: apiPackages.value.subscription.length ? apiPackages.value.subscription : defaultPackages.subscription,
  onetime: apiPackages.value.onetime.length ? apiPackages.value.onetime : defaultPackages.onetime,
}))

// API Functions
const fetchPackagesAndTrialStatus = async () => {
  loading.value = true
  try {
    const response = await axios.get(packageUrls.get)
    const { packages, canUseTrial } = response.data.data

    const trialPkg = packages.find((p) => p.packageId === 1)
    if (trialPkg) {
      defaultPackages.subscription[0].description = trialPkg.description
    }

    if (packages.length > 0) {
      const mappedPackages = packages.map(mapApiPackage).filter(Boolean)
      apiPackages.value = {
        subscription: mappedPackages,
        onetime: defaultPackages.onetime,
      }
    }

    isTrialAvailable.value = canUseTrial
  } catch (error) {
    console.error('Error fetching packages:', error)
    apiPackages.value = defaultPackages
  } finally {
    loading.value = false
  }
}

const mapApiPackage = (apiPkg) => {
  if (apiPkg.packageId === 1 || apiPkg.plans.some(p => p.type === 'ONE_TIME')) return null

  const monthlyPlan = apiPkg.plans.find(p => p.type === 'MONTHLY')
  const annualPlan = apiPkg.plans.find(p => p.type === 'ANNUAL')
  const defaultPkg = defaultPackages.subscription.find(dp => dp.id === apiPkg.packageId)

  const features = apiPkg.features
      .filter(feature => feature.isAvailable)
      .map(feature => feature.monthlyLobsLimit > 0 ?
          `${feature.name} (${feature.monthlyLobsLimit} adet/ay)` :
          feature.name,
      )

  return {
    id: apiPkg.packageId,
    name: apiPkg.packageName,
    description: apiPkg.description,
    isPopular: defaultPkg?.isPopular || false,
    monthlyPrice: monthlyPlan?.price || defaultPkg?.monthlyPrice,
    annualPrice: annualPlan?.price || defaultPkg?.annualPrice,
    monthlyFeatures: defaultPkg?.monthlyFeatures,
    annualFeatures: defaultPkg?.annualFeatures,
    features,
    planId: {
      monthly: monthlyPlan?.planId,
      annual: annualPlan?.planId,
    },
    hasTrial: apiPkg.hasTrial,
    trialDays: apiPkg.trialDays,
  }
}

// Validation Functions
const validateStep = () => {
  const validation = {
    isValid: false,
    errors: {},
  }

  if (!selectedPackage.value?.id) {
    validation.errors.package = t('assets.components.create.components.packageSelection.errors.selectPackage')
    return validation
  }

  if (activeTab.value === 'subscription' && !selectedPackage.value.plan) {
    validation.errors.plan = t('assets.components.create.components.packageSelection.errors.selectPlan')
    return validation
  }

  validation.isValid = true
  return validation
}

const validate = async () => {
  const validation = validateStep()

  if (!validation.isValid || assetStore.assetServiceType !== 2 || assetStore.assetData.assetId) {
    return validation
  }

  try {
    validationLoading.value = true

    const websiteUrl = new URL(assetStore.assetData.introductionList.website)
    const result = await axios.post(assets.addCrawler, {
      name: assetStore.assetData.introductionList.name,
      website: websiteUrl.href,
      languageId: assetStore.assetData.introductionList.languageId,
      searchEngineLocations: assetStore.assetData.introductionList.searchEngineLocations,
      countryId: assetStore.assetData.introductionList.countryId,
      timezoneId: assetStore.assetData.introductionList.timezoneId,
    })

    await assetStore.setAssetId(result.data.data.assetId)
    validation.isValid = true
    return validation
  } catch (error) {
    validation.isValid = false
    validation.errors.request = t('assets.create.source.websiteConfiguration.errors.generalError')
    responseHandlerService.handleError(error)
    return validation
  } finally {
    validationLoading.value = false
  }
}

// Event Handlers
const handleTabChange = (tab) => {
  activeTab.value = tab
  errors.value = {}
  validateAndEmit()
}

const handlePackageSelect = async (pkg) => {
  const packageData = pkg === '1' || pkg === 1
      ? {
        id: 1,
        name: t('assets.components.create.components.packageSelection.trial.title'),
        price: 0,
        plan: 'trial',
        planId: pkg.plans?.[0]?.planId || 12,
        features: pkg.features?.filter(f => f.isAvailable).map(f => f.name) || [],
        maxWebsites: 2,
        maxLobs: pkg.features?.find(f => f.code === 'SEO_ANALYSIS')?.monthlyLobsLimit || 10,
        discountRate: 0,
        isTrialEligible: true,
        trialDurationDays: pkg.trialDays || 30,
      }
      : {
        id: typeof pkg.id === 'string' ? parseInt(pkg.id) : pkg.id,
        name: pkg.name,
        price: pkg.price || pkg.monthlyPrice,
        plan: activeTab.value === 'subscription' ? 'monthly' : 'onetime',
        planId: pkg.planId?.monthly,
        features: pkg.features,
        maxWebsites: pkg.id === 2 ? 2 : 4,
        maxLobs: pkg.id === 2 ? 20 : 50,
        discountRate: 5,
        isTrialEligible: pkg.hasTrial,
        trialDurationDays: pkg.trialDays,
      }

  await assetStore.setSelectedPackage(packageData)
  await validateAndEmit()
}

const handlePlanSelect = async (plan, pkg) => {
  const pkgId = typeof pkg.id === 'string' ? parseInt(pkg.id) : pkg.id
  await assetStore.setSelectedPackage({
    id: pkgId,
    name: pkg.name,
    price: plan === 'monthly' ? pkg.monthlyPrice : pkg.annualPrice,
    plan: plan,
    planId: pkg.planId?.[plan],
    features: pkg.features,
    maxWebsites: pkgId === 2 ? 2 : 4,
    maxLobs: pkgId === 2 ? 20 : 50,
    discountRate: plan === 'monthly' ? 5 : 20,
    isTrialEligible: false,
  })
  await validateAndEmit()
}

const validateAndEmit = async () => {
  const validation = validateStep()
  errors.value = validation.errors
  if (validation.isValid) emit('stepValidation', validation)
}

// UI Helper Functions
const getPackageClasses = (packageId) => ({
  'border-primary bg-primary/5 shadow-lg': selectedPackage.value?.id === packageId,
  'border-gray-200 hover:border-gray-300': selectedPackage.value?.id !== packageId,
})

const getPlanClasses = (planType, packageId) => ({
  'border-primary bg-primary/5': selectedPackage.value?.id === packageId && selectedPackage.value?.plan === planType,
  'border-gray-200': selectedPackage.value?.id !== packageId || selectedPackage.value?.plan !== planType,
})

// Lifecycle Hooks
onMounted(async () => {
  loading.value = true
  try {
    await fetchPackagesAndTrialStatus()
    await assetStore.setAssetLoading(false)
    await validateAndEmit()
  } catch (error) {
    console.error('Error in component initialization:', error)
  } finally {
    loading.value = false
  }
})

// Watchers
watch(() => selectedPackage.value, validateAndEmit, { deep: true })

// Expose Component Methods
defineExpose({
  validate,
  getPackageClasses,
  getPlanClasses,
  handleTabChange,
  handlePackageSelect,
  handlePlanSelect,
})
</script>

<style scoped>
.validation-loading-overlay {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  z-index: 50;
}

.package-card {
  transition: all 0.3s ease;
}

.package-card:hover {
  transform: translateY(-2px);
}
</style>