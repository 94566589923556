import * as yup from 'yup'
import { useUIStore } from '@/stores/ui'

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5MB in bytes

// Fallback messages for both languages
const fallbackMessages = {
  tr: {
    required: 'Bu alan zorunludur',
    minLength: (length) => `En az ${length} karakter olmalıdır`,
    maxLength: (length) => `En fazla ${length} karakter olmalıdır`,
    invalidName: 'Geçerli bir isim giriniz (özel karakterler hariç)',
    invalidEmail: 'Geçerli bir e-posta adresi giriniz',
    fileSizeError: (size) => `Dosya boyutu çok büyük (Maksimum: ${size})`,
    fileTypeError: 'Geçersiz dosya türü.',
  },
  en: {
    required: 'This field is required',
    minLength: (length) => `Must be minimum ${length} characters`,
    maxLength: (length) => `Must be maximum ${length} characters`,
    invalidName: 'Please enter a valid name (no special characters)',
    invalidEmail: 'Please enter a valid email address',
    fileSizeError: (size) => `File is too large (Maximum: ${size})`,
    fileTypeError: 'Invalid file type',
  },
}

export const createFeedbackBaseSchema = (t) => {
  const getMessage = (key, params = {}) => {
    try {
      const uiStore = useUIStore()
      const currentLang = uiStore.getCurrentLanguage
      const fullKey = `form.errors.${key}`
      const translation = t(fullKey, params)

      if (translation === fullKey) {
        console.warn(`Missing translation for key: ${fullKey}`)
        const fallback = fallbackMessages[currentLang] || fallbackMessages.en
        return typeof fallback[key] === 'function'
            ? fallback[key](params.length || params.size || '')
            : fallback[key] || fallbackMessages.en[key]
      }

      return translation
    } catch (error) {
      console.warn(`Translation error for key: form.errors.${key}`, error)
      return fallbackMessages.en[key] || key
    }
  }

  return {
    name: yup
        .string()
        .required(() => getMessage('required'))
        .min(2, () => getMessage('minLength', { length: 2 }))
        .matches(
            /^[A-Za-zÀ-ÖØ-öø-ÿğĞıİöÖüÜşŞçÇ\s'-]+$/,
            () => getMessage('invalidName'),
        ),

    email: yup
        .string()
        .required(() => getMessage('required'))
        .email(() => getMessage('invalidEmail')),

    message: yup
        .string()
        .required(() => getMessage('required'))
        .min(10, () => getMessage('minLength', { length: 10 }))
        .max(1000, () => getMessage('maxLength', { length: 1000 })),

    attachment: yup
        .mixed()
        .nullable()
        .test('fileSize', () => getMessage('fileSizeError', { size: '5MB' }), (value) => {
          if (!value) return true
          return value.size <= MAX_FILE_SIZE
        })
        .test('fileType', () => getMessage('fileTypeError'), (value) => {
          if (!value) return true
          const supportedTypes = [
            'image/jpeg',
            'image/png',
            'image/gif',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'text/plain',
          ]
          return supportedTypes.includes(value.type)
        }),
  }
}

export const createFeedbackSchema = (t) => {
  try {
    const schema = createFeedbackBaseSchema(t)
    return yup.object().shape(schema)
  } catch (error) {
    console.error('Error creating feedback schema:', error)
    const uiStore = useUIStore()
    const currentLang = uiStore.getCurrentLanguage
    const fallback = fallbackMessages[currentLang] || fallbackMessages.en

    return yup.object().shape({
      name: yup.string().required(fallback.required),
      email: yup.string().required(fallback.required).email(fallback.invalidEmail),
      message: yup.string().required(fallback.required),
      attachment: yup.mixed().nullable(),
    })
  }
}

export const refreshSchema = (t) => createFeedbackSchema(t)