<template>
  <div class="mx-auto px-4 pt-8 pb-6 h-[70vh] bg-white rounded-lg shadow-md border border-gray-100">
    <div class="flex flex-col items-center justify-center h-full">
      <div class="text-center max-w-md">
        <div class="inline-flex p-4 rounded-full mb-4">
          <img src="@/assets/images/content.gif" alt="content creation" class="h-full">
        </div>
        <h2 class="text-xl font-semibold text-gray-900 mb-2">
          {{ t('entity.components.videoCreation.loading.title') }} </h2>
        <p class="text-gray-500 mb-8">
          {{ t('entity.components.videoCreation.loading.description') }} </p>
        <CustomButton @click="$emit('cancel')" color="primary" icon="close" size="sm" :show-icon="true" :button-text="t('common.cancel')"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import CustomButton from '@/components/base/buttons/VButton.vue'

const { t } = useI18n()

defineEmits(['cancel'])
</script>