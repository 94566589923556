<template>
  <div class="flex flex-col space-y-4 w-full">
    <div class="bg-white p-8 rounded-lg shadow-sm w-full flex items-center justify-center min-h-[400px]">
      <div v-if="loading" class="flex flex-col items-center justify-center space-y-6">
        <div class="relative">
          <div class="w-16 h-16 border-4 border-primary border-t-transparent rounded-full animate-spin"/>
          <span class="material-symbols-outlined text-primary absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            settings
          </span>
        </div>
        <div class="text-center space-y-2">
          <p class="font-semibold text-xl text-gray-800">
            {{ t('assets.components.create.components.analyse.messages.analyzing') }} </p>
          <p class="text-sm text-gray-500">
            {{ t('assets.components.create.components.analyse.messages.pleaseWait') }} </p>
        </div>
      </div>

      <div v-else-if="error" class="flex flex-col items-center justify-center space-y-6">
        <div class="w-16 h-16 rounded-full bg-red-50 flex items-center justify-center">
          <span class="material-symbols-outlined text-3xl text-red-500">error</span>
        </div>
        <div class="text-center space-y-2">
          <p class="font-semibold text-xl text-gray-800">
            {{ t('assets.components.create.components.analyse.messages.error') }} </p>
          <p v-if="errorMessage" class="text-sm text-red-500">{{ errorMessage }}</p>
          <button class="mt-4 px-4 py-2 bg-primary text-white text-sm rounded-lg hover:bg-primary-dark transition-colors" @click="handleRetry">
            {{ t('assets.components.create.components.analyse.actions.retry') }}
          </button>
        </div>
      </div>

      <div v-else-if="completed" class="flex flex-col items-center justify-center space-y-6">
        <div class="w-16 h-16 rounded-full bg-green-50 flex items-center justify-center">
          <span class="material-symbols-outlined text-3xl text-green-500">check_circle</span>
        </div>
        <div class="text-center space-y-2">
          <p class="font-semibold text-xl text-gray-800">
            {{ t('assets.components.create.components.analyse.messages.completed') }} </p>
          <p class="text-sm text-gray-500">
            {{ t('assets.components.create.components.analyse.messages.redirecting') }} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const props = defineProps({
  step: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['stepValidation', 'successResponse'])
const assetStore = useAssetStore()
const router = useRouter()
const { t } = useI18n()

const loading = ref(false)
const error = ref(false)
const completed = ref(false)
const errorMessage = ref('')

const serviceType = computed(() => assetStore.assetData.serviceType)
const assetId = computed(() => assetStore.assetData.assetId)
const customerLanguageId = computed(() => assetStore.assetData.customerLanguageId)
const introductionList = computed(() => assetStore.assetData.introductionList)
const informationList = computed(() => assetStore.assetData.informationList)
const authList = computed(() => assetStore.assetData.authList)

const getRequestConfig = () => {
  const isEdit = router.currentRoute.value.path.includes('/edit/')
  const id = router.currentRoute.value.params.id

  const crawlerData = {
    name: introductionList.value.name,
    website: introductionList.value.website,
    languageId: introductionList.value.languageId,
    searchEngineLocations: introductionList.value.searchEngineLocations,
    assetId: assetId.value,
  }

  const normalData = {
    assetId: assetId.value,
    customerLanguageId: customerLanguageId.value,
    aboutUs: informationList.value.aboutUs,
    facebook: informationList.value.facebookUrl,
    instagram: informationList.value.instagramUrl,
    twitter: informationList.value.twitterUrl,
    linkedin: informationList.value.linkedinUrl,
    phone: informationList.value.phone,
    wphone: informationList.value.wphone,
    keywords: informationList.value.keywords,
    languageId: introductionList.value.languageId,
    username: authList.value.username,
    password: authList.value.password,
    authUrl: authList.value.authUrl,
  }

  if (isEdit && id) {
    const editData = serviceType.value === 2 ? crawlerData : normalData
    return {
      method: 'put',
      url: assets.edit,
      data: {
        ...editData,
        assetId: id,
        communicationId: introductionList.value.communicationId,
      },
    }
  }

  return {
    method: 'post',
    url: serviceType.value === 2 ? assets.startCrawler : assets.add,
    data: serviceType.value === 2 ? crawlerData : normalData,
  }
}

const handleRetry = async () => {
  errorMessage.value = ''
  await sendRequest()
}

const sendRequest = async () => {
  loading.value = true
  error.value = false
  errorMessage.value = ''

  try {
    const requestConfig = getRequestConfig()
    await axios(requestConfig)
    emit('successResponse', true)
    completed.value = true
    error.value = false
    emit('stepValidation', { isValid: true })

    setTimeout(() => {
      router.push({
        name: 'assets',
        query: { tab: 'entities' },
      })
    }, 2000)
  } catch (err) {
    error.value = true
    completed.value = false
    errorMessage.value = err.response?.data?.message ||
        t('assets.components.create.components.analyse.messages.generalError')
    emit('stepValidation', {
      isValid: false,
      errors: { general: errorMessage.value },
    })
    responseHandlerService.handleError(err)
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  emit('stepValidation', { isValid: false })
  await sendRequest()
})

defineExpose({
  validate: () => !error.value && completed.value,
})
</script>