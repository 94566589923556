<template>
  <div class="flex flex-col space-y-6 component-layout relative min-h-[75vh]">
    <VLoading v-if="loading" class="absolute inset-0 bg-white/80 z-50" :loading-text="t('general.loading')" :show-loading-text="true"/>

    <template v-else>
      <!-- Top Section: Table and Cards -->
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-6">
        <!-- Left side: KeywordTable -->
        <div class="flex flex-col h-full">
          <KeywordTable :keywords="keywords" :is-loading="loading" :max-keywords="MAX_KEYWORDS" @update:keywords="updateKeywords"/>
        </div>

        <!-- Right side: Cards -->
        <div class="flex flex-col space-y-4">
          <!-- Document Upload Section -->
          <InformationCard :title="t('settings.components.configuration.documentUploadLabel')" :description="t('settings.components.configuration.documentUploadDescription')" @view="showDocumentModal = true"/>

          <!-- Memory History Section -->
          <InformationCard :title="t('settings.components.configuration.memoryHistoryLabel')" :description="t('settings.components.configuration.memoryHistoryDescription')" @view="openMemoryHistoryModal"/>

          <!-- Backlinks Section -->
          <InformationCard :title="t('settings.components.configuration.backlinksLabel')" :description="t('settings.components.configuration.backlinksDescription')" @view="showBacklinkModal = true"/>

          <!-- Engine Location Selector Section -->
          <InformationCard :title="t('settings.components.configuration.engineLocationLabel')" :description="t('settings.components.configuration.engineLocationDescription')" @view="showEngineLocationModal = true"/>

          <!-- Auto Optimize Card -->
          <InformationCard :title="t('settings.components.configuration.autoOptimizeTitle')" :description="t('settings.components.configuration.autoOptimizeDescription')" :button-text="t('settings.components.configuration.optimizeButton')" :is-loading="isOptimizing || loading" :loading-text="loading ? t('general.loading') : t('settings.components.configuration.optimizing')" :is-disabled="isAutoOptimized || loading" :disable-gray="true" :show-tooltip="isAutoOptimized" :tooltip-text="isAutoOptimized ? t('settings.components.configuration.autoOptimizedTooltip') : ''" tooltip-color="secondary" @view="showConfirmDialog = true"/>
        </div>
      </div>

      <!-- Bottom Section: Location Settings -->
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-6">
        <!-- Location Settings Section -->
        <div class="xl:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4">
          <!-- Country Selector -->
          <div class="bg-white rounded-md p-4">
            <VCountrySelector v-model="selectedCountry" :label="t('settings.components.configuration.countryLabel')" :description="t('settings.components.configuration.countryDescription')" @countrySelected="handleCountryChange"/>
          </div>

          <!-- Timezone Selector -->
          <div class="bg-white rounded-md p-4">
            <VTimezoneSelector v-model="selectedTimezone" :countryId="selectedCountry" :label="t('settings.components.configuration.timezoneLabel')" :description="t('settings.components.configuration.timezoneDescription')" @timezoneSelected="handleTimezoneChange"/>
          </div>
        </div>
      </div>
    </template>

    <!-- Modals -->
    <documentModal v-model="showDocumentModal" @update:show="showDocumentModal = $event"/>
    <memoryHistory ref="memoryHistoryRef"/>
    <engineLocationModal v-model="showEngineLocationModal" v-model:locations="searchEngineLocations" @update:locations="handleLocationUpdate"/>
    <backlinksModal v-model="showBacklinkModal" @update:show="showBacklinkModal = $event"/>
    <customDialog v-model="showConfirmDialog" :title="t('settings.components.configuration.confirmDialogTitle')" :message="t('settings.components.configuration.confirmDialogMessage')" :showCancelButton="true" :cancel-button-text="t('settings.components.configuration.confirmDialogCancel')" :confirm-button-text="t('settings.components.configuration.confirmDialogConfirm')" @confirm="confirmAutoOptimizeProcess"/>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import axios from '@/plugins/axiosInstance'

// Components
import KeywordTable from './components/keywordTable/index.vue'
import VLoading from '@/components/loading/VLoading.vue'
import memoryHistory from '@/views/settings/components/configuration/components/memoryHistory/index.vue'
import documentModal from './components/assetsInformation/documentModal.vue'
import customDialog from '@/components/feedback/VDialog.vue'
import engineLocationModal from '@/components/features/engine-location/modal/index.vue'
import InformationCard from './components/informationCard/index.vue'
import BacklinksModal from '@/views/settings/components/configuration/components/backlinks/BacklinksModal.vue'

// Services & Stores
import { settings } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useAssetStore } from '@/stores/asset'

// Schema
import { createConfigurationSchema, hasChanges, validateConfigurationData, MAX_KEYWORDS } from '@/validations/schemas/settings.configuration.schema'
import { deepClone } from '@/utils/objectUtils'
import VCountrySelector from '@/components/base/inputs/VCountrySelector.vue'
import VTimezoneSelector from '@/components/base/inputs/VTimezoneSelector.vue'

const { t } = useI18n()
const assetStore = useAssetStore()
const { assetId } = storeToRefs(assetStore)

// Props & Emits
defineProps(['keywordList'])
const emit = defineEmits(['keywords', 'saving'])

// Refs
const memoryHistoryRef = ref(null)
const searchEngineLocations = ref({ google: [], bing: [], yandex: [] })
const keywords = ref([])
const showDocumentModal = ref(false)
const showEngineLocationModal = ref(false)
const showBacklinkModal = ref(false)
const loading = ref(false)
const showConfirmDialog = ref(false)
const isOptimizing = ref(false)
const isAutoOptimized = ref(false)
const selectedCountry = ref(null)
const selectedTimezone = ref(null)

// Schema and Original Data
const configSchema = computed(() => createConfigurationSchema(t))
const originalData = ref(null)


// Methods
const getEngineName = (engineNumber) => {
  const engineMap = {
    1: 'yandex',
    2: 'google',
    3: 'bing',
  }
  return engineMap[engineNumber] || `Engine ${engineNumber}`
}

const openMemoryHistoryModal = () => {
  memoryHistoryRef.value?.toggleSidebar?.()
}

const handleLocationUpdate = (newLocations) => {
  searchEngineLocations.value = newLocations
}

const updateKeywords = (newKeywords) => {
  keywords.value = newKeywords
}

const handleCountryChange = (countryId) => {
  selectedCountry.value = countryId
}

const handleTimezoneChange = (timezoneId) => {
  selectedTimezone.value = timezoneId
}

const fetch = async () => {
  if (!assetId.value) return

  loading.value = true
  try {
    const { data: { data } } = await axios.get(settings.assetConfiguration, {
      params: { assetId: assetId.value },
    })

    // Update keywords
    keywords.value = data.keywords
    isAutoOptimized.value = data.isAutoOptimized
    selectedTimezone.value = data.timezoneId
    selectedCountry.value = data.countryId

    // Update locations
    if (data.locations?.length) {
      searchEngineLocations.value = data.locations.reduce((acc, location) => {
        const engineName = getEngineName(location.engineId)
        acc[engineName].push({
          id: location.id,
          locationId: location.locationId,
          name: location.locationName,
          engineId: location.engineId,
          canonicalName: location.canonicalName,
          state: location.state,
        })
        return acc
      }, { google: [], bing: [], yandex: [] })
    }

    // Save original data
    originalData.value = {
      keywords: deepClone(data.keywords),
      locations: deepClone(searchEngineLocations.value),
      countryId: data.countryId,
      timezoneId: data.timezoneId,
    }
  } catch (error) {
    console.error('Fetch error:', error)
    responseHandlerService.errorHandler(t('settings.components.configuration.fetchError'))
  } finally {
    setTimeout(() => loading.value = false, 500)
  }
}

const confirmAutoOptimizeProcess = async () => {
  if (isOptimizing.value) return

  isOptimizing.value = true
  try {
    await axios.post(settings.autoOptimize, { assetId: assetId.value })
    responseHandlerService.successHandler(t('settings.components.configuration.optimizeSuccess'))
    await fetch()
  } catch (error) {
    responseHandlerService.errorHandler(t('settings.components.configuration.optimizeError'))
  } finally {
    isOptimizing.value = false
    showConfirmDialog.value = false
  }
}

const save = async () => {
  const currentData = {
    keywords: keywords.value,
    locations: searchEngineLocations.value,
    countryId: selectedCountry.value,
    timezoneId: selectedTimezone.value,
  }

  if (!hasChanges(originalData.value, currentData)) {
    responseHandlerService.warningHandler(t('settings.components.configuration.noChanges'))
    return
  }

  // Validate only keywords
  const { isValid, errors } = await validateConfigurationData({ keywords: keywords.value }, configSchema.value)
  if (!isValid) {
    console.log('Validation errors:', errors)

    // Show error messages
    if (typeof errors === 'object') {
      Object.values(errors).forEach(message => {
        responseHandlerService.errorHandler(message)
      })
    }
    return
  }

  emit('saving', true)
  try {
    await axios.put(settings.assetConfiguration, {
      ...currentData,
      assetId: assetId.value,
    })

    responseHandlerService.successHandler(t('settings.components.configuration.updateSuccess'))
    await fetch()
  } catch (error) {
    if (error.response?.data?.message) {
      responseHandlerService.errorHandler(error.response.data.message)
    } else {
      responseHandlerService.errorHandler(t('settings.components.configuration.updateError'))
    }
  } finally {
    emit('saving', false)
  }
}

// Watch for assetId changes
watch(() => assetId.value, (newId, oldId) => {
  if (newId !== oldId) fetch()
})

// Lifecycle
onMounted(() => {
  fetch()
})

// Expose methods
defineExpose({ save })
</script>
