import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import axios from '@/plugins/axiosInstance'
import { packages } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'
import { useI18n } from 'vue-i18n'
import { encryptedStorage } from '@/utils/encryption'

export const useFeatureStore = defineStore('features', () => {
  const assetStore = useAssetStore()
  const { t } = useI18n()

  // Feature Metadata Configuration
  const featureMetadata = {
    SOCIAL_MEDIA_INTEGRATION: {
      translationKey: 'features.socialMediaIntegration',
      icon: 'share',
      class: 'text-blue-500',
    },
    SEO_ANALYSIS: {
      translationKey: 'features.seoAnalysis',
      icon: 'language',
      class: 'text-green-500',
    },
    TREND_ANALYSIS: {
      translationKey: 'features.trendAnalysis',
      icon: 'trending_up',
      class: 'text-purple-500',
    },
    COMPETITOR_ANALYSIS: {
      translationKey: 'features.competitorAnalysis',
      icon: 'search',
      class: 'text-orange-500',
    },
    WEBSITE_CRAWL: {
      translationKey: 'features.siteCrawl',
      icon: 'travel_explore',
      class: 'text-teal-500',
    },
    KEYWORD_MANAGEMENT: {
      translationKey: 'features.keywordManagement',
      icon: 'key',
      class: 'text-cyan-500',
    },
    PHOTO_POST: {
      translationKey: 'features.aiBlogVisual',
      icon: 'image',
      class: 'text-pink-500',
    },
    VIDEO_POST: {
      translationKey: 'features.aiBlogVideo',
      icon: 'movie',
      class: 'text-red-500',
    },
    VISITOR_STATS: {
      translationKey: 'features.analytics',
      icon: 'analytics',
      class: 'text-indigo-500',
    },
    CALENDAR_ACCESS: {
      translationKey: 'features.calendarAccess',
      icon: 'calendar_month',
      class: 'text-amber-500',
    },
    MANUAL_POST: {
      translationKey: 'features.manualPost',
      icon: 'edit_document',
      class: 'text-lime-500',
    },
    DOCUMENT_UPLOAD: {
      translationKey: 'features.documentUpload',
      icon: 'upload_file',
      class: 'text-emerald-500',
    },
    TICKET_SYSTEM: {
      translationKey: 'features.ticketSystem',
      icon: 'confirmation_number',
      class: 'text-rose-500',
    },
    AUTO_OPTIMIZATION: {
      translationKey: 'features.autoOptimization',
      icon: 'auto_awesome',
      class: 'text-violet-500',
    },
    AI_CUSTOMER_SERVICE: {
      translationKey: 'features.aiCustomerService',
      icon: 'support_agent',
      class: 'text-sky-500',
    },
    SOCIAL_MEDIA_CONTENT: {
      translationKey: 'features.socialMediaContent',
      icon: 'forum',
      class: 'text-fuchsia-500',
    },
    REVISE_CONTENT: {
      translationKey: 'features.reviseContent',
      icon: 'edit',
      class: 'text-yellow-500',
    },
  }

  // State
  const loading = ref(false)
  const features = ref([])
  const featuresByAsset = ref({})
  const packageName = ref('')
  const currentPackage = ref({
    id: null,
    name: '',
    planType: null,
    status: 'ACTIVE',
    renewalDate: null,
    price: 0,
  })

  // Getters
  const activeFeatureCount = computed(() => features.value.length)

  const featuresByUsage = computed(() => {
    return features.value.sort((a, b) => {
      const aUsage = calculatePercentage(a.monthlyLobsLimit - a.remainingLobs, a.monthlyLobsLimit)
      const bUsage = calculatePercentage(b.monthlyLobsLimit - b.remainingLobs, b.monthlyLobsLimit)
      return bUsage - aUsage
    })
  })

  const hasActiveFeatures = computed(() => features.value.length > 0)

  const usageData = computed(() => {
    return features.value
        .filter(feature => feature.lobsPerUse > 0)
        .map(feature => ({
          id: feature.featureId,
          code: feature.code,
          name: t(`${getFeatureTranslationKey({ code: feature.code })}.name`),
          usedAmount: Math.max(0, Number(feature.monthlyLobsLimit) - Number(feature.remainingLobs)),
          totalAmount: Number(feature.monthlyLobsLimit) || 0,
        }))
  })

  // Package Information Helper
  const getPackageInfo = (pkg) => {
    // Free package special display
    if (pkg?.id === 1) {
      return {
        planType: null,
        renewalDate: null,
        price: 0,
        showPlanType: false,
        showRenewalDate: false,
      }
    }

    return {
      planType: pkg?.planType || null,
      renewalDate: pkg?.renewalDate || null,
      price: pkg?.price || 0,
      showPlanType: true,
      showRenewalDate: true,
    }
  }

  // Feature Metadata Helper Methods
  const getFeatureIcon = (feature) => {
    return featureMetadata[feature.code]?.icon || 'extension'
  }

  const getFeatureIconClass = (feature) => {
    return featureMetadata[feature.code]?.class || 'text-gray-400'
  }

  const getFeatureTranslationKey = (feature) => {
    return featureMetadata[feature.code]?.translationKey || 'features.unknown'
  }

  // Actions
  async function fetchFeatures(silent = false) {
    const currentAssetId = assetStore.assetId

    if (!silent) {
      loading.value = true
    }

    try {
      const result = await axios.get(packages.futureDetail, {
        params: {
          assetId: currentAssetId,
        },
      })

      const data = result.data.data

      // Set package info using the helper function
      const packageInfo = getPackageInfo({
        id: data.packageId,
        planType: data.packageId === 1 ? null : (data.planType || 'MONTHLY'),
        renewalDate: data.packageId === 1 ? null : data.endDate,
        price: data.packageId === 1 ? 0 : (Number(data.price) || 0),
      })

      currentPackage.value = {
        id: data.packageId,
        name: data.packageName,
        planType: packageInfo.planType,
        status: data.status || 'ACTIVE',
        renewalDate: packageInfo.renewalDate,
        price: packageInfo.price,
      }

      // Filter features and add translations
      const activeFeatures = data.features
          .filter(feature =>
              feature.status &&
              feature.monthlyLobsLimit > 0 &&
              feature.lobsPerUse > 0,
          )
          .map(feature => ({
            ...feature,
            name: t(`${getFeatureTranslationKey(feature)}.name`),
            description: t(`${getFeatureTranslationKey(feature)}.description`),
          }))

      features.value = activeFeatures
      packageName.value = data.packageName || t('navigation.layout.components.featureMenu.no_package')

      featuresByAsset.value[currentAssetId] = {
        features: activeFeatures,
        packageName: packageName.value,
        packageDetails: currentPackage.value,
      }

      return result
    } catch (error) {
      console.error('Error fetching features:', error)
      const defaultPackageInfo = getPackageInfo({ id: null })

      features.value = []
      packageName.value = t('navigation.layout.components.featureMenu.no_package')
      currentPackage.value = {
        id: null,
        name: '',
        planType: defaultPackageInfo.planType,
        status: 'ACTIVE',
        renewalDate: defaultPackageInfo.renewalDate,
        price: defaultPackageInfo.price,
      }

      featuresByAsset.value[currentAssetId] = {
        features: [],
        packageName: packageName.value,
        packageDetails: currentPackage.value,
      }
      throw error
    } finally {
      if (!silent) {
        loading.value = false
      }
    }
  }

  async function refreshFeatures() {
    try {
      return await fetchFeatures(true)
    } catch (error) {
      console.error('Error refreshing features:', error)
      throw error
    }
  }

  function resetFeaturesByAsset(assetId = null) {
    if (assetId) {
      delete featuresByAsset.value[assetId]
    } else {
      const currentAssetId = assetStore.assetId
      delete featuresByAsset.value[currentAssetId]
    }

    const defaultPackageInfo = getPackageInfo({ id: null })

    features.value = []
    packageName.value = t('navigation.layout.components.featureMenu.no_package')
    currentPackage.value = {
      id: null,
      name: '',
      planType: defaultPackageInfo.planType,
      status: 'ACTIVE',
      renewalDate: defaultPackageInfo.renewalDate,
      price: defaultPackageInfo.price,
    }
  }

  function calculatePercentage(used, total) {
    if (!used || !total) return 0
    return Math.min(100, Math.round((used / total) * 100))
  }

  function getUsageStatus(feature) {
    const percentage = calculatePercentage(
        feature.monthlyLobsLimit - feature.remainingLobs,
        feature.monthlyLobsLimit,
    )

    if (percentage >= 90) return 'critical'
    if (percentage >= 70) return 'warning'
    return 'normal'
  }

  function getColorsByStatus(status, type = 'bg') {
    const colors = {
      critical: {
        bg: 'bg-red-100',
        progress: 'bg-red-500',
        text: 'text-red-600',
      },
      warning: {
        bg: 'bg-yellow-100',
        progress: 'bg-yellow-500',
        text: 'text-yellow-600',
      },
      normal: {
        bg: 'bg-blue-100',
        progress: 'bg-blue-500',
        text: 'text-blue-600',
      },
    }

    return colors[status][type]
  }

  function getFeatureById(featureId) {
    return features.value.find(f => f.featureId === featureId)
  }

  function hasAvailableLobs(featureId) {
    const feature = getFeatureById(featureId)
    return feature ? feature.remainingLobs >= feature.lobsPerUse : false
  }

  function getRemainingUses(featureId) {
    const feature = getFeatureById(featureId)
    return feature ? Math.floor(feature.remainingLobs / feature.lobsPerUse) : 0
  }

  return {
    // State
    loading,
    features,
    packageName,
    currentPackage,
    featureMetadata,

    // Getters
    activeFeatureCount,
    featuresByUsage,
    hasActiveFeatures,
    usageData,

    // Feature Metadata Helpers
    getFeatureIcon,
    getFeatureIconClass,
    getFeatureTranslationKey,
    getPackageInfo,

    // Actions
    fetchFeatures,
    refreshFeatures,
    resetFeaturesByAsset,
    calculatePercentage,
    getUsageStatus,
    getColorsByStatus,
    getFeatureById,
    hasAvailableLobs,
    getRemainingUses,
  }
}, {
  persist: {
    key: 'feature-store',
    storage: encryptedStorage,
    paths: ['featuresByAsset'],
  },
})