import * as yup from 'yup'

// Base schema creator function
export const createIntroductionBaseSchema = (t) => ({
  name: yup
      .string()
      .required(t('form.errors.required'))
      .min(2, t('form.errors.minLength', { length: 2 }))
      .matches(/^[a-zA-ZğĞıİşŞöÖüÜçÇ0-9\s\-_]+$/, t('assets.components.create.components.introduction.information.errors.invalidCharacters'))
      .test('uniqueName',
          t('assets.components.create.components.introduction.information.errors.duplicateAssetName'),
          function (value) {
            return true
          }),

  website: yup
      .string()
      .required(t('form.errors.required'))
      .url(t('assets.components.create.components.introduction.information.errors.invalidWebsite'))
      .test('httpsOnly',
          t('assets.components.create.components.introduction.information.errors.invalidWebsiteFormat'),
          (value) => {
            if (!value) return true
            return value.startsWith('https://')
          }),

  languageId: yup
      .number()
      .required(t('form.errors.required')),

  countryId: yup
      .number()
      .required(t('form.errors.required'))
      .test('required', t('form.errors.required'), (value) => {
        return value !== null && value !== undefined
      }),

  timezoneId: yup
      .number()
      .required(t('form.errors.required'))
      .test('required', t('form.errors.required'), (value) => {
        return value !== null && value !== undefined
      }),
})

export const createIntroductionSchema = (t, assets = [], editMode = false, currentId = null) => {
  const schema = { ...createIntroductionBaseSchema(t) }

  // Name unique kontrolü
  schema.name = schema.name.test('uniqueName',
      t('assets.components.create.components.introduction.information.errors.duplicateAssetName'),
      (value) => {
        if (editMode) {
          return !assets.some(asset =>
              asset.id !== currentId &&
              asset.name.trim().toLowerCase() === value?.trim().toLowerCase(),
          )
        }
        return !assets.some(asset =>
            asset.name.trim().toLowerCase() === value?.trim().toLowerCase(),
        )
      },
  )

  return yup.object().shape(schema)
}

export const refreshSchema = (t, assets, editMode, currentId) =>
    createIntroductionSchema(t, assets, editMode, currentId)