<template>
  <!-- Template remains mostly unchanged except for store references -->
  <div class="flex flex-col space-y-6 w-full min-h-[400px]">
    <PaymentHeader :current-nested-step="currentNestedStep"/>
    <LoadingState v-if="isLoading" :text="loadingText"/>

    <template v-else>
      <PaymentStatus v-if="showPaymentStatus" v-bind="paymentStatusProps" @try-again="handleTryAgain" @status-change="handlePaymentStatusChange" @download-receipt="handleDownloadReceipt"/>

      <template v-if="showPaymentSteps">
        <div class="transition-all duration-300">
          <TransitionGroup name="fade" appear>
            <div v-if="currentNestedStep === 0" key="billing" class="relative">
              <BillingForm ref="billingFormRef" :initial-data="assetStore.assetData.paymentDetails.formContent" @update:form="handleFormUpdate" @stepValidation="handleNestedStepValidation"/>
            </div>

            <div v-if="currentNestedStep === 1" key="methods">
              <PaymentMethods ref="paymentMethodsRef" :selected-method="assetStore.assetData.paymentDetails.method" :disabled="processingPayment" @select="handleMethodSelect" @stepValidation="handleNestedStepValidation"/>
            </div>

            <div v-if="currentNestedStep === 2" key="summary" class="space-y-6">
              <PackageSummary ref="summaryFormRef" :package="assetStore.assetData.selectedPackage" :payment-method="assetStore.assetData.paymentDetails.method" :form-data="assetStore.assetData.paymentDetails.formContent"/>

              <div class="flex flex-col items-center">
                <CustomButton :button-text="t('assets.components.create.components.payment.proceedToPayment')" color="primary" size="lg" :is-loading="processingPayment" :is-disabled="!canProceedToPayment" additional-class="w-full max-w-md" @click="handlePayment"/>

                <div class="mt-4 text-sm text-gray-500 text-center">
                  {{ t('assets.components.create.components.payment.status.info.securePayment') }}
                </div>
              </div>
            </div>
          </TransitionGroup>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useStepsStore } from '@/stores/steps'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import axios from '@/plugins/axiosInstance'
import { payment as paymentUrls } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'

import PaymentHeader from './components/PaymentHeader.vue'
import LoadingState from './components/LoadingState.vue'
import PaymentStatus from './components/PaymentStatus.vue'
import BillingForm from './components/BillingForm.vue'
import PaymentMethods from './components/PaymentMethods.vue'
import PackageSummary from './components/PackageSummary.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'

const props = defineProps({
  step: { type: Object, required: true },
  currentNestedStep: { type: Number, required: true },
})

const emit = defineEmits(['stepValidation', 'stepData'])

const assetStore = useAssetStore()
const stepsStore = useStepsStore()
const route = useRoute()
const { t } = useI18n()

const loading = ref(false)
const initialLoading = ref(true)
const processingPayment = ref(false)
const paymentError = ref('')
const currentStepError = ref('')
const stepValidationState = ref({})

const billingFormRef = ref(null)
const paymentMethodsRef = ref(null)
const summaryFormRef = ref(null)

const isLoading = computed(() => loading.value || initialLoading.value)
const showPaymentStatus = computed(() =>
    route.query.success && assetStore.assetData.paymentDetails.status,
)

const showPaymentSteps = computed(() =>
    !assetStore.assetData.paymentDetails.status ||
    assetStore.assetData.paymentDetails.status === 'failed' ||
    !route.query.success,
)

const loadingText = computed(() => {
  if (initialLoading.value) {
    return t('assets.components.create.components.payment.status.info.initializing')
  }
  return processingPayment.value
      ? t('assets.components.create.components.payment.status.info.processingMessage')
      : t('assets.components.create.components.payment.status.info.loading')
})

const paymentStatusProps = computed(() => ({
  status: assetStore.assetData.paymentDetails.status,
  amount: assetStore.assetData.selectedPackage?.price,
  transactionInfo: {
    method: assetStore.assetData.paymentDetails?.method,
    id: assetStore.assetData.paymentDetails?.transactionId,
    date: assetStore.assetData.paymentDetails?.completedAt,
  },
  errorMessage: assetStore.assetData.paymentDetails.errorMessage,
}))

const canProceedToPayment = computed(() => {
  const hasRequiredData = assetStore.assetData.selectedPackage?.id &&
      assetStore.assetData.billingInformation?.paymentInformationId && // formContent yerine paymentInformationId kontrol ediyoruz
      assetStore.assetData.paymentDetails?.method

  return hasRequiredData && !processingPayment.value
})

const navigationState = computed(() => {
  const status = assetStore.assetData.paymentDetails?.status
  const isFinalStep = props.currentNestedStep === 2

  if (isFinalStep && status !== 'success') {
    return { prevDisabled: false, nextDisabled: true }
  }

  if (status === 'success' && props.currentNestedStep === 2) {
    return { prevDisabled: true, nextDisabled: false }
  }

  return { prevDisabled: false, nextDisabled: false }
})

const getCurrentStepRef = () => {
  const refs = {
    0: billingFormRef.value,
    1: paymentMethodsRef.value,
    2: summaryFormRef.value,
  }
  return refs[props.currentNestedStep] || null
}

const validateCurrentStep = async () => {
  const currentRef = getCurrentStepRef()
  if (!currentRef?.validate) return true

  try {
    const result = await currentRef.validate()

    if (typeof result === 'object') {
      const { isValid, errors = {} } = result
      await handleNestedStepValidation({ isValid, errors })
      return isValid
    }

    await handleNestedStepValidation({ isValid: result, errors: {} })
    return result
  } catch (error) {
    await handleNestedStepValidation({
      isValid: false,
      errors: { general: error.message },
    })
    return false
  }
}

const handleNestedStepValidation = async ({ isValid, errors = {} }) => {
  stepValidationState.value[props.currentNestedStep] = { isValid, errors }
  currentStepError.value = Object.values(errors)[0] || ''

  emit('stepValidation', {
    isValid: isValid && (props.currentNestedStep !== 2 || assetStore.assetData.paymentDetails?.status === 'success'),
    errors,
  })
}

const handleFormUpdate = async (formData) => {
  await assetStore.setPaymentDetails({ formContent: formData })
}

const handleMethodSelect = async (method) => {
  if (processingPayment.value) return
  await assetStore.setPaymentDetails({ method })
  await handleNestedStepValidation({ isValid: !!method })
}

const handlePayment = async () => {
  if (!canProceedToPayment.value) return

  processingPayment.value = true
  emit('stepValidation', { isValid: false, errors: {} })
  await stepsStore.updateNavigation({ prevDisabled: true, nextDisabled: true })

  try {
    await assetStore.setPaymentDetails({ status: 'pending' })
    const paymentData = {
      paymentInformationId: assetStore.assetData.billingInformation.paymentInformationId,
      assetId: assetStore.assetData.assetId,
      packageId: assetStore.assetData.selectedPackage.id,
      planId: assetStore.assetData.selectedPackage.planId,
      method: assetStore.assetData.paymentDetails.method,
    }

    const response = await axios.post(paymentUrls.start, paymentData)
    if (response.data.data.paymentPageUrl) {
      window.location.href = response.data.data.paymentPageUrl
    }
  } catch (error) {
    paymentError.value = t('assets.components.create.components.payment.status.error.processingError')
    await assetStore.setPaymentDetails({
      status: 'failed',
      errorMessage: error.message,
    })
    emit('stepValidation', { isValid: false, errors: {} })
  } finally {
    processingPayment.value = false
  }
}

const handlePaymentStatusChange = async (status) => {
  const navigationUpdate = status === 'success'
      ? { prevDisabled: true, nextDisabled: false }
      : { prevDisabled: false, nextDisabled: true }

  await stepsStore.updateNavigation(navigationUpdate)

  if (status === 'failed') {
    await stepsStore.setCurrentStep({
      stepIndex: props.step.index,
      nestedStepIndex: 2,
    })
  }
}

const handleTryAgain = async () => {
  await assetStore.setPaymentDetails({
    status: null,
    errorMessage: null,
  })

  await stepsStore.setCurrentStep({
    stepIndex: props.step.index,
    nestedStepIndex: 2,
  })

  await stepsStore.updateNavigation({ prevDisabled: false, nextDisabled: true })
}

const handleDownloadReceipt = async () => {
  try {
    const response = await axios.get(paymentUrls.receipt, {
      params: {
        assetId: assetStore.assetData.assetId,
        transactionId: assetStore.assetData.paymentDetails.transactionId,
      },
      responseType: 'blob',
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'receipt.pdf')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    responseHandlerService.handleError(error)
  }
}

const checkPaymentStatus = async () => {
  loading.value = true
  try {
    const response = await axios.get(paymentUrls.status, {
      params: { assetId: assetStore.assetData.assetId },
    })

    await assetStore.setPaymentDetails({
      status: 'success',
      completedAt: new Date().toISOString(),
      transactionId: response.data.data.transactionId,
    })

    emit('stepValidation', { isValid: true, errors: {} })
    await stepsStore.updateNavigation({ prevDisabled: true, nextDisabled: false })
  } catch (error) {
    await assetStore.setPaymentDetails({
      status: 'failed',
      errorMessage: error.message,
    })
    emit('stepValidation', { isValid: false, errors: {} })
    await stepsStore.updateNavigation({ prevDisabled: false, nextDisabled: true })
  } finally {
    loading.value = false
  }
}

watch(
    () => props.currentNestedStep,
    () => {
      currentStepError.value = ''
      paymentError.value = ''
    },
)

watch(
    () => assetStore.assetData.paymentDetails?.status,
    (newStatus) => {
      if (newStatus) {
        emit('stepValidation', {
          isValid: newStatus === 'success',
          errors: {},
        })
      }
    },
)

watch(
    navigationState,
    async (newState) => {
      await stepsStore.updateNavigation(newState)
    },
    { immediate: true },
)

onMounted(async () => {
  try {
    if (route.query.success) {
      await checkPaymentStatus()
    } else {
      await stepsStore.updateNavigation({
        prevDisabled: false,
        nextDisabled: false,
      })
      emit('stepValidation', { isValid: false, errors: {} })
    }
  } catch (error) {
    responseHandlerService.handleError(error)
  } finally {
    initialLoading.value = false
  }
})

defineExpose({
  validate: validateCurrentStep,
})
</script>
