<template>
  <div class="feedback-wrapper">
    <button @click="handleButtonClick" :class="[
        'feedback-trigger',
        { 'bg-green-500': isSuccess, 'bg-primary': !isSuccess }
      ]" :disabled="isSuccess">
      <span class="material-symbols-outlined" :class="{ 'scale-enter-active': isSuccess }">
        {{ isSuccess ? 'check_circle' : 'rate_review' }}
      </span>
    </button>

    <div v-if="isFormVisible" class="fixed inset-0 bg-secondary/50 flex items-center justify-center backdrop-blur-sm z-[999999999999]" @click.self="hideFeedback">
      <div class="bg-white rounded-xl shadow-xl w-full max-w-md mx-4 transform transition-all max-h-[90vh] flex flex-col">
        <div class="p-6 border-b border-gray-200 flex justify-between items-center shrink-0">
          <h3 class="text-xl font-semibold text-secondary flex items-center gap-2">
            <span class="material-symbols-outlined text-primary">forum</span> {{ $t('feedback.title') }} </h3>
          <custom-button icon="close" :show-icon="true" variation="outline" color="neutral" size="sm" :is-text-showable="false" @click="hideFeedback"/>
        </div>

        <form @submit.prevent="handleSubmit" class="p-6 overflow-y-auto">
          <div class="space-y-5">
            <div v-if="screenshot" class="border rounded-lg p-2">
              <div class="flex items-center justify-between mb-2">
                <span class="text-sm font-medium text-secondary">{{ $t('feedback.screenshot') }}</span>
                <button type="button" @click="removeScreenshot" class="text-primary hover:text-primary/80">
                  <span class="material-symbols-outlined text-[18px]">delete</span>
                </button>
              </div>
              <img :src="screenshot" alt="Screenshot" class="w-full h-auto rounded border"/>
            </div>

            <custom-input v-model="formData.name" :label="$t('feedback.nameLabel')" :placeholder="$t('feedback.namePlaceholder')" type="text" required show-icon icon="person" :error="errors.name"/>

            <custom-input v-model="formData.email" :label="$t('feedback.emailLabel')" :placeholder="$t('feedback.emailPlaceholder')" type="text" required show-icon icon="mail" :error="errors.email"/>

            <custom-input v-model="formData.message" :label="$t('feedback.messageLabel')" :placeholder="$t('feedback.messagePlaceholder')" type="textarea" required text-area-height="h-32" :error="errors.message"/>

            <div class="flex gap-4">
              <button type="button" @click="takeScreenshot" class="flex-1 border border-gray-200 py-2.5 px-4 rounded-lg hover:bg-gray-50 transition-colors duration-200 flex items-center justify-center gap-2">
                <span class="material-symbols-outlined">screenshot</span> {{ $t('feedback.takeScreenshot') }}
              </button>

              <div class="flex-1 relative">
                <button type="button" @click="triggerFileUpload" class="w-full border border-gray-200 py-2.5 px-4 rounded-lg hover:bg-gray-50 transition-colors duration-200 flex items-center justify-center gap-2">
                  <span class="material-symbols-outlined">attach_file</span> {{ $t('feedback.attachFile') }}
                </button>
                <input ref="fileInput" type="file" class="absolute inset-0 opacity-0 cursor-pointer" @change="handleFileChange" accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.txt"/>
              </div>
            </div>

            <div v-if="formData.attachment" class="text-sm text-gray-500 flex items-center gap-2">
              <span class="material-symbols-outlined text-[18px]">description</span> {{ formData.attachment.name }}
              <button type="button" @click="removeAttachment" class="text-primary hover:text-primary/80">
                <span class="material-symbols-outlined text-[18px]">delete</span>
              </button>
            </div>

            <div v-if="errors.attachment" class="text-sm text-red-500">
              {{ errors.attachment }}
            </div>
          </div>

          <div class="mt-6">
            <custom-button type="submit" :button-text="isSubmitting ? $t('feedback.submitting') : $t('feedback.submit')" :is-loading="isSubmitting" color="primary" :full-width="true" :is-disabled="isSubmitting"/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeUnmount } from 'vue'
import * as Sentry from '@sentry/vue'
import { useAuthStore } from '@/stores/auth'
import { useI18n } from 'vue-i18n'
import html2canvas from 'html2canvas'
import { createFeedbackSchema } from '@/validations/schemas/feedback.schema'
import CustomButton from '@/components/base/buttons/VButton.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'

const authStore = useAuthStore()
const { t } = useI18n()
const fileInput = ref(null)

const isFormVisible = ref(false)
const isSubmitting = ref(false)
const screenshot = ref(null)
const isSuccess = ref(false)
let resetTimer = null

const formData = reactive({
  name: '',
  email: '',
  message: '',
  attachment: null,
})

const errors = reactive({
  name: '',
  email: '',
  message: '',
  attachment: '',
})

if (authStore.userData?.user) {
  formData.name = authStore.userData.user.name || ''
  formData.email = authStore.userData.user.email || ''
}

const clearErrors = () => {
  Object.keys(errors).forEach(key => {
    errors[key] = ''
  })
}

const handleSubmit = async () => {
  clearErrors()
  try {
    const schema = createFeedbackSchema(t)
    await schema.validate(formData, { abortEarly: false })
    await submitFeedback()
  } catch (error) {
    if (error.inner) {
      error.inner.forEach((err) => {
        errors[err.path] = err.message
      })
    }
  }
}

const handleButtonClick = () => {
  if (!isSuccess.value) {
    showFeedback()
  }
}

const showFeedback = () => {
  isFormVisible.value = true
}

const hideFeedback = () => {
  isFormVisible.value = false
  resetForm()
  clearErrors()
}

const resetForm = () => {
  formData.name = authStore.userData?.user?.name || ''
  formData.email = authStore.userData?.user?.email || ''
  formData.message = ''
  formData.attachment = null
  screenshot.value = null
}

const takeScreenshot = async () => {
  isFormVisible.value = false
  await new Promise(resolve => setTimeout(resolve, 100))

  try {
    const canvas = await html2canvas(document.body)
    screenshot.value = canvas.toDataURL()
    isFormVisible.value = true

    const blobBin = atob(screenshot.value.split(',')[1])
    const array = []
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i))
    }
    const file = new Blob([new Uint8Array(array)], { type: 'image/png' })
    file.name = 'screenshot.png'
    formData.attachment = file
  } catch (error) {
    isFormVisible.value = true
    errors.attachment = t('feedback.screenshotError')
  }
}

const removeScreenshot = () => {
  screenshot.value = null
  if (formData.attachment?.name === 'screenshot.png') {
    formData.attachment = null
  }
}

const triggerFileUpload = () => {
  fileInput.value.click()
}

const removeAttachment = () => {
  formData.attachment = null
  if (screenshot.value) {
    screenshot.value = null
  }
}

const handleFileChange = (event) => {
  const file = event.target.files[0]
  if (file) {
    formData.attachment = file
    screenshot.value = null
  }
}

const submitFeedback = async () => {
  isSubmitting.value = true

  try {
    await Sentry.captureFeedback({
      name: formData.name,
      email: formData.email,
      message: formData.message,
    }, {
      includeReplay: true,
      attachments: formData.attachment ? [formData.attachment] : [],
    })

    isFormVisible.value = false
    isSuccess.value = true

    if (resetTimer) clearTimeout(resetTimer)
    resetTimer = setTimeout(() => {
      isSuccess.value = false
      isSubmitting.value = false
    }, 4000)

    resetForm()
  } catch (error) {
    isSubmitting.value = false
    errors.message = t('feedback.errorMessage')
  }
}

onBeforeUnmount(() => {
  if (resetTimer) {
    clearTimeout(resetTimer)
  }
  if (isFormVisible.value) {
    resetForm()
    clearErrors()
  }
})
</script>

<style scoped>
.feedback-trigger {
  @apply fixed bottom-6 right-5 flex items-center justify-center text-white w-12 h-12 rounded-full shadow-lg transition-all duration-300 z-50 cursor-pointer;
}

.feedback-trigger:not(:disabled):hover {
  @apply transform scale-110;
}

.feedback-trigger:disabled {
  @apply cursor-default;
}

.scale-enter-active {
  animation: scale 0.3s ease;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>