<template>
  <div class="flex items-center gap-4">
    <VButton v-if="isRevisedEntityReceived" :disabled="isAnalyzing" @click="handleSaveEditedContent" icon="save" icon-position="left" show-icon size="sm" button-color="secondary" :button-text="t('entity.components.contentReview.updateContent')" :is-loading="saveEditedContentLoading"/>

    <ShareMenu v-bind="socialMediaData" button-color="secondary" icon="share" :button-text="t('entity.components.newContent.components.contentForm.buttons.shareOnSocial')" @update:socialMediaContent="handleSocialMediaUpdate"/>

    <!-- Dropdown Menü -->
    <VMenu v-model="menuOpen" :triggerClass="'hover:bg-gray-200 border border-secondary px-2 py-0.5 rounded-md flex items-center gap-2 text-sm'">
      <template #default>
        <span class="material-symbols-outlined !text-[20px]">more_vert</span> <span>{{ t('entity.components.contentReview.actions') }}</span>
      </template>

      <template #content>
        <button v-if="canOptimize" :disabled="isAnalyzing" @click="handleAnalyze" class="w-full text-left flex items-center gap-2 px-4 py-2 text-sm hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
          <span class="material-symbols-outlined text-[18px]">auto_fix_high</span> <span>
            {{
            isRevisedEntityReceived
                ? t('entity.components.contentReview.reOptimizeButton')
                : t('entity.components.contentReview.optimizeButton')
          }}
          </span>
        </button>

        <!-- Copy Content Butonu -->
        <button @click="handleCopyContent" class="w-full text-left flex items-center gap-2 px-4 py-2 text-sm hover:bg-gray-50">
          <span class="material-symbols-outlined text-[18px]">content_copy</span> <span>{{ t('entity.components.copyModal.title') }}</span>
        </button>

        <button @click="handleToggleHistory" class="w-full text-left flex items-center gap-2 px-4 py-2 text-sm hover:bg-gray-50">
          <span class="material-symbols-outlined text-[18px]">history</span> <span>{{ t('entity.components.contentReview.historyButton') }}</span>
        </button>

        <button v-if="entityUrl && !isHistoryContent" @click="handleNavigate(entityUrl)" class="w-full text-left flex items-center gap-2 px-4 py-2 text-sm hover:bg-gray-50">
          <span class="material-symbols-outlined text-[18px]">open_in_new</span> <span>{{ t('entity.components.contentReview.navigateToContentButton') }}</span>
        </button>

        <button v-if="isHistoryContent" @click="handleSaveContent" class="w-full text-left flex items-center gap-2 px-4 py-2 text-sm hover:bg-gray-50">
          <span class="material-symbols-outlined text-[18px]">save</span> <span>{{ t('entity.components.contentReview.saveContent') }}</span>
        </button>
      </template>
    </VMenu>

    <!-- Copy Content Modal -->
    <CopyContentModal v-model="showCopyModal" :content="contentForCopy"/>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import ShareMenu from '../../../shareOnSocialMedia/index.vue'
import VMenu from '@/components/common/VMenu.vue'
import VButton from '@/components/base/buttons/VButton.vue'
import CopyContentModal from '../../../copyContentModal/index.vue'

const { t } = useI18n()
const menuOpen = ref(false)
const showCopyModal = ref(false)

const props = defineProps({
  socialMediaData: {
    type: Object,
    required: true,
  },
  canOptimize: {
    type: Boolean,
    default: false,
  },
  entityUrl: {
    type: String,
    default: '',
  },
  isAnalyzing: {
    type: Boolean,
    default: false,
  },
  isRevisedEntityReceived: {
    type: Boolean,
    default: false,
  },
  isHistoryContent: {
    type: Boolean,
    default: false,
  },
  saveEditedContentLoading: {
    type: Boolean,
    default: false,
  },
  content: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits([
  'toggleHistory',
  'analyze',
  'navigate',
  'saveContent',
  'update:socialMediaData',
  'saveEditedContent',
])

// Copy modal için content data hazırlama
const contentForCopy = computed(() => {
  const imageUrls = props.content?.imageUrls?.filter(img => img?.url || img?.image) || []

  return {
    title: props.content?.title || '',
    body: props.content?.content || '',
    category: props.content?.category || '',
    keywords: props.content?.keywords.map(k => k.name).filter(Boolean) || [],
    metaDescription: props.content?.metaDescription || '',
    images: imageUrls,
    socialMediaContent: props.content?.socialMediaContent || '',
    socialMediaHashtags: Array.isArray(props.content?.socialMediaHashtags)
        ? props.content.socialMediaHashtags
        : [],
  }
})

const handleSocialMediaUpdate = (newContent) => {
  emit('update:socialMediaData', {
    ...props.socialMediaData,
    content: newContent.socialMediaContent,
    hashtags: newContent.socialMediaHashtags,
    isSocialMediaContentReady: true,
  })
}

const handleAnalyze = () => {
  emit('analyze')
  menuOpen.value = false
}

const handleToggleHistory = () => {
  emit('toggleHistory')
  menuOpen.value = false
}

const handleNavigate = (url) => {
  emit('navigate', url)
  menuOpen.value = false
}

const handleSaveContent = () => {
  emit('saveContent')
  menuOpen.value = false
}

const handleSaveEditedContent = () => {
  emit('saveEditedContent')
  menuOpen.value = false
}

const handleCopyContent = () => {
  showCopyModal.value = true
  menuOpen.value = false
}
</script>