<template>
  <div class="relative w-full" ref="componentRef">
    <label v-if="label" :class="['block font-semibold text-sm mb-1', labelClass]"> {{ label }} <span v-if="required" class="text-red-500">*</span> </label>
    <p v-if="description" :class="['mb-2 text-xs text-gray-500', descriptionClass]">
      {{ description }} </p>

    <CustomDropdown :options="countryOptions" v-model="selectedCountryId" :label="placeholder" :show-search="true" :loading="loading" :disabled="disabled" :error="error" size="sm" @update:modelValue="handleCountryChange" @search="handleSearch"/>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import CustomDropdown from '@/components/base/inputs/VDropdown.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const props = defineProps({
  modelValue: [String, Number],
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  required: { type: Boolean, default: false },
  labelClass: { type: String, default: 'text-secondary' },
  descriptionClass: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  showSearch: { type: Boolean, default: true },
  error: { type: String, default: '' },
})

const emit = defineEmits(['update:modelValue', 'countrySelected', 'timezoneSelected'])

const componentRef = ref(null)
const countries = ref([])
const loading = ref(true)
const selectedCountryId = ref(props.modelValue)
const searchQuery = ref('')

const countryOptions = computed(() => {
  if (!countries.value?.length) return []

  return countries.value
      .filter(country => {
        if (!searchQuery.value) return true
        return country.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            country.code.toLowerCase().includes(searchQuery.value.toLowerCase())
      })
      .map(country => ({
        value: country.id,
        label: country.name,
        code: country.code,
      }))
})

const handleCountryChange = async (countryId) => {
  selectedCountryId.value = countryId
  emit('countrySelected', countryId)
  emit('update:modelValue', countryId)

  // Seçilen ülkenin timezone'ını otomatik al
  try {
    const response = await axios.get(assets.timezones, {
      params: { countryId },
    })
    const timeZones = response.data.data
    if (timeZones && timeZones.length > 0) {
      // İlk timezone'ı seç (API'den ülkenin timezone'ı ilk sırada geliyor)
      emit('timezoneSelected', timeZones[0].id)
    }
  } catch (error) {
    console.error('Error fetching timezone:', error)
    responseHandlerService.handleError('Timezone bilgisi alınamadı')
  }
}

const handleSearch = (query) => {
  searchQuery.value = query || ''
}

const fetchCountries = async () => {
  try {
    loading.value = true
    const response = await axios.get(assets.countries)
    countries.value = response.data.data || []

    if (props.modelValue) {
      selectedCountryId.value = props.modelValue
      // Eğer başlangıçta bir ülke seçili ise, onun timezone'ını da al
      await handleCountryChange(props.modelValue)
    }
  } catch (error) {
    console.error('Error fetching countries:', error)
    countries.value = []
  } finally {
    loading.value = false
  }
}

// Props değişimini izle
watch(() => props.modelValue, (newValue) => {
  if (newValue !== selectedCountryId.value) {
    selectedCountryId.value = newValue
    if (newValue) {
      handleCountryChange(newValue)
    }
  }
}, { immediate: true })

onMounted(() => {
  fetchCountries()
})
</script>