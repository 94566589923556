<template>
  <PaymentInformation v-model="selectedPaymentInformationId" @stepValidation="handleStepValidation" ref="paymentInfoRef" :show-header="false"/>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useAssetStore } from '@/stores/asset'
import PaymentInformation from '@/views/shared/PaymentInformationManagment/index.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  currentStep: {
    type: Number,
    default: 0,
  },
})

const { t } = useI18n()
const emit = defineEmits(['stepValidation'])
const assetStore = useAssetStore()
const { getBillingInformation } = storeToRefs(assetStore)

const paymentInfoRef = ref(null)
const selectedPaymentInformationId = ref(getBillingInformation.value?.paymentInformationId || null)

const handleStepValidation = (validationData) => {
  emit('stepValidation', validationData)
}

const validate = async (silent = false) => {
  if (!selectedPaymentInformationId.value) {
    if (!silent) responseHandlerService.handleError(t('assets.components.create.components.payment.errors.selectBilling'))
    emit('stepValidation', {
      isValid: false,
      errors: { payment: t('assets.components.create.components.payment.errors.billingNotSelected') },
    })
    return false
  }

  emit('stepValidation', { isValid: true, errors: {} })
  return true
}

watch(selectedPaymentInformationId, (newValue) => {
  assetStore.setPaymentInformationId(newValue)

  if (!newValue) {
    emit('stepValidation', {
      isValid: false,
      errors: { payment: t('assets.components.create.components.payment.errors.billingNotSelected') },
    })
  } else {
    emit('stepValidation', { isValid: true, errors: {} })
  }
})

const reset = () => {
  selectedPaymentInformationId.value = null
  assetStore.clearBillingInformation()
  if (paymentInfoRef.value?.reset) {
    paymentInfoRef.value.reset()
  }
}

onMounted(() => {
  validate(true)
})

defineExpose({
  validate,
  reset,
})
</script>