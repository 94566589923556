<template>
  <div class="w-full max-h-screen min-h-screen overflow-y-auto">
    <navigationLayout>
      <router-view v-slot="{ Component }">
        <component :is="Component"/>
      </router-view>
    </navigationLayout>

    <ModalsContainer/>
    <feedback/>
    <ToastNotification ref="toastNotification" :max-toasts="2" :newest-on-top="true"/>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import navigationLayout from '@/components/navigation/layout/index.vue'
import feedback from '@/components/feedback/SentryFeedback.vue'
import { ModalsContainer } from 'vue-final-modal'
import ToastNotification from '@/components/feedback/VToast.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useUIStore } from '@/stores/ui'

const toastNotification = ref(null)
const uiStore = useUIStore()

onMounted(() => {
  uiStore.setSnackbarPosition({ bottom: true, right: true })

  const addToast = (type, payload) => {
    if (!toastNotification.value) return

    const message = typeof payload === 'object' ? payload.message : payload

    if (payload?.isConnectionError) {
      // Array'i modifiye etmek yerine elemanları tek tek sil
      const length = toastNotification.value.toasts.length
      for (let i = length - 1; i >= 0; i--) {
        toastNotification.value.toasts.splice(i, 1)
      }
    }

    toastNotification.value.addToast(type, message)
  }

  const errorHandler = (error) => {
    if (error.status === 401 && responseHandlerService.unauthorizedSnackbarDisplayed) return
    addToast('warning', error)
  }

  const successHandler = (message) => {
    addToast('success', message)
  }

  const warningHandler = (message) => {
    addToast('warning', message)
  }

  responseHandlerService.setErrorHandler(errorHandler)
  responseHandlerService.setSuccessHandler(successHandler)
  responseHandlerService.setWarningHandler(warningHandler)
})
</script>